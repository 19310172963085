/* eslint-disable react/jsx-pascal-case */
/* eslint-disable camelcase */
import { Button, Dialog, IconButton } from '@mui/material'
import { useState } from 'react'
import ViewColumnIcon from '@mui/icons-material/ViewColumn'
import useStyles from './styles'
import IOSSwitch from '../../../components/Form/IOSSwitch'

const ColumnVisibility = ({ columns = [], onChange }) => {
  const classes = useStyles()

  const [anchorEl, setAnchorEl] = useState(null)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const onChangeHandler = (i, value) => {
    onChange(
      columns.map((item, index) => {
        if (i === index) {
          return {
            ...item,
            value,
          }
        }
        return item
      })
    )
  }

  const bulkChange = (value) => {
    onChange(columns.map((item) => ({ ...item, value: item.disabled ? item.value : value })))
  }

  const open = Boolean(anchorEl)

  return (
    <div className={classes.hide_col_btn}>
      <IconButton aria-label="Show/Hide columns" onClick={handleClick}>
        <ViewColumnIcon />
      </IconButton>
      <Dialog onClose={handleClose} open={open}>
        <div className={classes.popover}>
          <div className={classes.popover_header}>
            <Button variant="text" onClick={() => bulkChange(false)}>
              Hide All
            </Button>
            <Button variant="text" onClick={() => bulkChange(true)}>
              Show All
            </Button>
          </div>
          <div className={classes.popover_list}>
            {columns.map((item, index) => (
              <div className={classes.popover_list_item}>
                <div>{item.label}</div>
                <div>
                  <IOSSwitch
                    checked={item.value}
                    disabled={item.disabled}
                    onChange={(e) => {
                      onChangeHandler(index, e.target.checked)
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Dialog>
    </div>
  )
}
export default ColumnVisibility

import Cookies from 'universal-cookie'

const cookies = new Cookies()

export function useAuth() {
  if (sessionStorage.getItem('AUTH_NSL') || cookies.get('AUTH_NSL')) {
    return true
  }
  return false
}

export function setAuth(prop, val, session = false) {
  if (session) {
    cookies.set(prop, val, { path: '/', maxAge: 7948800 })
  } else {
    sessionStorage.setItem(prop, val)
  }
}

export function getAccountID() {
  return sessionStorage.getItem('AUTH_NSL') || cookies.get('AUTH_NSL')
}
export function getUserId() {
  return sessionStorage.getItem('USER_ID') || cookies.get('USER_ID')
}

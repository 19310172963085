import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'

import { Link } from 'react-router-dom'
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg'
import { setPage } from '../../store/slice/page'

import PageWrapper from '../../containers/PageWrapper'
import AddPlatformForm from '../../containers/Form/AddPlatformForm'

import useStyles from './styles'

const AddPlatfrom = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPage('addPlatfrom'))
  })

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <Box className={classes.page_content}>
          <AddPlatformForm />
        </Box>
        <Link className={classes.closeBtn} to="/account/api-keys">
          <CloseIcon />
        </Link>
      </Box>
    </PageWrapper>
  )
}
export default AddPlatfrom

import useStyles from './styles'

const CheckboxRound = ({ id, onChange, children }) => {
  const classes = useStyles()

  return (
    <div className={classes.checkbox}>
      <input type="checkbox" id={id} onChange={(e) => onChange(e.target.checked)} />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={id}>{children}</label>
    </div>
  )
}

export default CheckboxRound

/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { getRulesAvailableReq, getRulesReq, updateRulesReq } from '../../api/rules'
import { errorParserRequest } from '../../utils/errorParser'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'

export const actionsVerbEnum = {
  stopAdvertising: 'stopAdvertising',
  decreaseBids: 'decreaseBids',
  multiplyBidsByFactorAndUploadNegativeToFullAutos: 'multiplyBidsByFactorAndUploadNegativeToFullAutos',
  multiplyBidsByPercentageAndUploadNegativeToFullAutos: 'multiplyBidsByPercentageAndUploadNegativeToFullAutos',
}

const initialState = {
  rules: [],
  rulesAvailable: [],
  loading: false,
}

const isUpdated = (data) => {
  const activeUpdated = data.active !== data.initActive
  const criteriaValueUpdated = data.scopeSelector.selectorCriterion.criterionValueOrFunction !== data.initCriteriaValue
  const criteriaFieldUpdated = data.scopeSelector.selectorCriterion.criterionFieldOrFunction !== data.initCriteriaField
  const actionParameterUpdated = data.actions?.[0]?.actionParameter !== data.initActionParameter

  return activeUpdated || criteriaValueUpdated || criteriaFieldUpdated || actionParameterUpdated
}

export const getRules = createAsyncThunk('rules/getRules', async (data, { rejectWithValue }) => {
  try {
    const response = await getRulesReq(data)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    const err = errorParserRequest(error)
    toast.error(err, TOAST_DEFAULT_CONFIG)
    return rejectWithValue()
  }
})

export const getRulesAvailable = createAsyncThunk('rules/getRulesAvailable', async (data, { rejectWithValue }) => {
  try {
    const response = await getRulesAvailableReq(data)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    const err = errorParserRequest(error)
    toast.error(err, TOAST_DEFAULT_CONFIG)
    return rejectWithValue()
  }
})

export const updateRules = createAsyncThunk('rules/updateRules', async ({ id, guid, data }, { rejectWithValue }) => {
  try {
    const response = await updateRulesReq(id, guid, data)
    if (response.status === 200 || response.status === 201) {
      return {
        guid: data.guid,
      }
    }
  } catch (error) {
    const err = errorParserRequest(error)
    toast.error(err, TOAST_DEFAULT_CONFIG)
    return rejectWithValue()
  }
})

/* eslint-disable no-param-reassign */
export const rulesSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    updateValue: (state, action) => {
      state.rules = state.rules.map((item) => {
        if (item.GUID === action.payload.GUID) {
          return {
            ...action.payload,
            updated: isUpdated(action.payload),
          }
        }
        return item
      })
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRules.pending, (state) => {
        state.loading = true
      })
      .addCase(getRules.fulfilled, (state, action) => {
        state.loading = false
        state.rules = action.payload.map((item) => ({
          ...item,
          initActive: item.active,
          initCriteriaValue: item.scopeSelector.selectorCriterion.criterionValueOrFunction,
          initCriteriaField: item.scopeSelector.selectorCriterion.criterionFieldOrFunction,
          initActionParameter: item.actions?.[0]?.actionParameter,
          updated: false,
        }))
      })
      .addCase(getRules.rejected, (state) => {
        state.loading = false
      })
      // .addCase(getRulesAvailable.pending, (state) => {})
      .addCase(getRulesAvailable.fulfilled, (state, action) => {
        state.rulesAvailable = action.payload
      })
      // .addCase(getRulesAvailable.rejected, (state) => {})
      .addCase(updateRules.pending, () => {})
      .addCase(updateRules.fulfilled, (state) => {
        state.rules = state.rules.map((item) => ({
          ...item,
          initActive: item.active,
          initCriteriaValue: item.scopeSelector.selectorCriterion.criterionValueOrFunction,
          initCriteriaField: item.scopeSelector.selectorCriterion.criterionFieldOrFunction,
          initActionParameter: item.actions?.[0]?.actionParameter,
          updated: false,
        }))
      })
      .addCase(updateRules.rejected, () => {})
  },
})
/* eslint-enable no-param-reassign */

export const selectRulesAvailable = (state) => state.rules.rulesAvailable
export const selectRules = (state) => state.rules.rules
export const selectRulesLoading = (state) => state.rules.loading

export const { updateValue, setLoading } = rulesSlice.actions

export default rulesSlice.reducer

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    select: {
      maxWidth: 164,
      width: 164,
      '& .MuiSelect-select.MuiSelect-outlined': {
        background: '#F2F2F2',
        paddingLeft: '10px',
      },
      '& .MuiFormLabel-root': {
        top: '50%',
        transform: 'translateY(-50%)',
        paddingLeft: 10,
        fontWeight: 800,
        '&[data-shrink="true"]': {
          paddingLeft: 10,
          fontSize: '1rem',
        },
        '&.MuiFormLabel-filled': {
          top: 6,
          transform: 'translateY(0)',
          fontSize: 7,
          lineHeight: 1.5,
        },
      },
      '& .MuiSelect-icon': {
        top: 'calc(50% - 10px)',
      },
      '& fieldset': {
        top: 0,
        border: 0,
      },
      '& .MuiSelect-select[aria-expanded="true"]~fieldset.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
      '& legend': {
        display: 'none',
      },
      '& .MuiInputBase-adornedEnd': {
        paddingRight: 0,
        '& .MuiSelect-select': {
          paddingRight: '55px !important',
        },
      },
      '& .MuiInputAdornment-root': {
        position: 'absolute',
        right: 40,
        marginTop: 2,
      },
      // theme.spacing(5.625)
    },
    select_options: {
      maxWidth: 164,
    },
    '.MuiPaper-root': {
      background: '#f2f2f2',
    },
    without_label: {
      '& .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input': {
        paddingTop: '9px',
        fontSize: '15px',
        fontWeight: 700,
      },
    },
  })
)

export default useStyles

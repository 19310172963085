// OLD realization
export const TABLE_TYPE = {
  SIMPLE: 'SIMPLE',
  GROUP: 'GROUP',
  SEPARATE: 'SEPARATE',
}

// NEW realization
export const TABLE_VIEW_VARIANT = {
  SIMPLE: 0,
  ONE_NESTED: 1,
  DOUBLE_NESTED: 2,
}

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    keyword_btn: {
      minWidth: 19,
      minHeight: 19,
      width: 19,
      height: 19,
      background: 'none',
      border: 0,
      padding: 0,
      outline: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      cursor: 'pointer',
      '& svg': {
        width: '100%',
        height: '100%',
      },
      '& img': {
        position: 'absolute',
        left: 'calc(50% - 6.5px)',
        top: 'calc(50% - 6.5px)',
        width: 13,
        height: 13,
      },

      '&:hover': {
        opacity: 0.7,
      },
    },
  })
)
export default useStyles

/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import { Button, TextField } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import useStyles from './styles'

const SearchBar = ({
  onChange = () => {},
  onSubmit = () => {},
  onClear = () => {},
  isButtonDisabled,
  value,
  placeholder,
}) => {
  const [str, setStr] = useState(value)
  const [isClearShow, setIsClearShow] = useState()
  const classes = useStyles()

  const onChangeHandler = (e) => {
    setStr(e.target?.value)
    onChange(e.target?.value)
    setIsClearShow(false)
  }

  const onSubmitHandler = () => {
    onSubmit(str)
    setIsClearShow(true)
  }

  const onClearHandler = () => {
    setStr('')
    setIsClearShow(false)
    onClear('')
    onSubmit('')
  }

  const handleKeyPress = (e) => {
    if (e.code === 'Enter') {
      onSubmit(str)
      setIsClearShow(true)
    }
  }

  useEffect(() => {
    setIsClearShow(!!value)
  }, [])

  useEffect(() => {
    if (!value) {
      setStr('')
      setIsClearShow(false)
    } else {
      setStr(value)
      setIsClearShow(true)
    }
  }, [value])

  return (
    <Box className={classes.search_wrapper}>
      <Box className={classes.search_text}>
        <TextField
          fullWidth
          id="cockpit-search"
          type="text"
          placeholder={placeholder}
          value={str}
          onChange={onChangeHandler}
          onKeyPress={handleKeyPress}
        />
      </Box>
      <Box className={classes.search_button_wrapper}>
        {!isClearShow && (
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            className={classes.search_button}
            disabled={isButtonDisabled || !str}
            onClick={onSubmitHandler}
          >
            Go
          </Button>
        )}
        {isClearShow && (
          <Button
            variant="outlined"
            size="small"
            color="secondary"
            className={classes.search_button}
            disabled={isButtonDisabled}
            onClick={onClearHandler}
          >
            <CloseIcon color="secondary" width={15} height={15} />
          </Button>
        )}
      </Box>
    </Box>
  )
}

export default SearchBar

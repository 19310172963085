import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    page_wrapper: {},
    page_content: {
      paddingLeft: 125,
      paddingRight: 125,
      paddingBottom: 45,
      paddingTop: 45,
      width: '100%',
      [theme.breakpoints.down('lg1')]: {
        paddingLeft: 80,
        paddingRight: 80,
      },
    },
    rightBlock: {
      width: '100%',
    },
    wrapperTable: {},
    flexWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 43,
    },
    labelText: {
      fontSize: 14,
      color: 'black',
      lineHeight: 'normal',
      margin: 0,
    },
    wrapperFlex: {
      display: 'flex',
      gap: 77,
      marginTop: 45,
      [theme.breakpoints.down('lg1')]: {
        flexDirection: 'column-reverse',
      },
    },
    form_footer_box: {
      width: 125,
      textAlign: 'center',
      '&:not(:first-of-type)': {
        marginLeft: '0 !important',
      },
      '& > a': {
        display: 'flex',
        textDecoration: 'none',
      },
      '& .MuiButtonBase-root': {
        border: '1px solid #1A0063',
        color: '#1A0063',
        transition: '0.3s',
        '&.Mui-disabled': {
          borderColor: '#C4C4C4',
          color: '#C4C4C4',
        },
        '&:hover': {
          backgroundColor: '#1A0063',
          color: '#fff',
        },
      },
    },
    wrapperArea: {
      marginTop: 45,
    },
    wrapperSelectBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: 25,
    },
    addProducts: {
      width: 168,
      textAlign: 'center',
      '&:not(:first-of-type)': {
        marginLeft: '0 !important',
      },
      '& > a': {
        display: 'flex',
        textDecoration: 'none',
      },
      '& .MuiButtonBase-root': {
        height: 40,
        minHeight: 40,
        fontSize: 16,
        border: '1px solid #1A0063',
        color: '#1A0063',
        transition: '0.3s',
        '&.Mui-disabled': {
          borderColor: '#C4C4C4',
          color: '#C4C4C4',
        },
        '&:hover': {
          backgroundColor: '#1A0063',
          color: '#fff',
        },
      },
    },
    wrapperBottom: {
      borderTop: '1px solid #C4C4C4',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: 15,
      paddingTop: 25,
      paddingBottom: 15,
    },
    form_btn: {
      width: '100%',
      flexGrow: 1,
      fontWeight: 'bold',
    },
    header_title: {
      textAlign: 'left',
      fontWeight: 800,
      fontSize: '1.625rem',
      lineHeight: 1.26,
      color: '#000',
      width: '50%',
      maxWidth: 658,
      [theme.breakpoints.down('lg1')]: {
        width: '100%',
      },
    },
    descriptionText: {
      fontSize: 14,
    },
    description: {
      width: '50%',
      maxWidth: 658,
      marginTop: 10,
      [theme.breakpoints.down('lg1')]: {
        width: '100%',
      },
    },
    leftBlock: {
      maxWidth: '100%',
    },
    textArea: {
      maxWidth: '100%',
    },
    closeBtn: {
      width: 45,
      height: 45,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      position: 'absolute',
      top: 25,
      right: 25,
      border: '1px solid #1A0063',
      borderRadius: 100,
      transition: ' 0.3s',
      '&:hover': {
        backgroundColor: '#1A0063',
        '& svg, path': {
          fill: 'white',
        },
      },
      '& svg, path': {
        width: 20,
        height: 20,
        fill: '#1A0063',
      },
    },
  })
)
export default useStyles

import axios from 'axios'

export const getChannels = (accountId) => axios.get(`channels/${accountId}`)

export const channelAccountCountryReq = ({ accountID, platformID }) =>
  axios.get(`/channels/${accountID}/queryCreatable?platformID=${platformID}`)

export const createChannelReq = (accountID, data, platformID) =>
  axios.post(`/channels/${accountID}?platformID=${platformID}`, data)

export const changeChannels = (
  accountId,
  channelId,
  acos,
  commission,
  averageGrossMargin,
  averageShippingCosts,
  averageReturnCosts,
  averageReturnRate
) =>
  axios.patch(
    `channels/${accountId}?channelID=${channelId}&ACoSTarget=${acos}&commission=${commission}&averageGrossMargin=${averageGrossMargin}&averageShippingCosts=${averageShippingCosts}&averageReturnCosts=${averageReturnCosts}&averageReturnRate=${averageReturnRate}`
  )

export const changeChannelsLabel = (accountId, data) =>
  axios.patch(`channels/${accountId}?channelID=${data?.channelID}&channelName=${data.channelName}`)

export const receiveExternalResponse = (data) => axios.post(`receiveExternalResponse`, data)

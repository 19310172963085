import logo from '../../assets/logo/deepcliq-logo.svg'

import useStyles from './styles'

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <div className={classes.logo}>
        <img src={logo} alt="logo" />
      </div>
    </footer>
  )
}
export default Footer

const isValueExist = (v) => v !== null && v !== undefined

export const rowTotalGenetator = (data) => ({
  id: '',
  name: '',
  ean: '',
  products: new Intl.NumberFormat('en-EN').format(data?.nProducts || 0),
  active: '',
  profit: isValueExist(data?.advertisingNetProfitProportion?.value)
    ? Number(data.advertisingNetProfitProportion.value)
    : '-',
  adProfitAbsolute: isValueExist(data?.advertisingNetProfitAbsolute)
    ? new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(data.advertisingNetProfitAbsolute)
    : '-',
  acos: isValueExist(data?.ACoS?.value) ? Number(data.ACoS?.value) : '-',
  tacos: isValueExist(data.TACoS?.value) ? Number(data.TACoS?.value) : '-',
  sales: isValueExist(data?.sales)
    ? new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(data.sales)
    : '-',
  cost: isValueExist(data?.cost)
    ? new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(data.cost)
    : '-',
  conversions: isValueExist(data?.conversions) ? new Intl.NumberFormat('en-EN').format(data.conversions) : '-',
  clicks: isValueExist(data?.clicks) ? new Intl.NumberFormat('en-EN').format(data.clicks) : '-',
  impressions: isValueExist(data?.impressions) ? new Intl.NumberFormat('en-EN').format(data?.impressions) : '-',
  presentInChannels_count: isValueExist(data?.presentInChannels_count) ? data.presentInChannels_count : '-',
  returnRate: isValueExist(data?.returnRate?.value) ? data.returnRate.value : '-',
  profitAbsolute: isValueExist(data.totalNetProfitAbsolute)
    ? new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(data.totalNetProfitAbsolute)
    : '-',
  totalSales: isValueExist(data.totalSales)
    ? new Intl.NumberFormat('en-EN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(data.totalSales)
    : '-',
  asin: isValueExist(data?.ASINandSKU?.ASIN) ? data?.ASINandSKU?.ASIN : '',
  customerID: isValueExist(data?.customerID) ? data?.customerID : '',
  totalNetProportion: isValueExist(data?.totalNetProfitProportion?.value)
    ? Number(data?.totalNetProfitProportion?.value)
    : '-',
})

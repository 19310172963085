import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, CardActions, FormGroup, TextField, ThemeProvider } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
// import { styled } from '@mui/material/styles'
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip'
import * as Yup from 'yup'
import ThemeNSLLight from '../../../theme/theme-nsl-light'
import { errorParserSingle } from '../../../utils/errorParser'

import { createChannel, getQueryCreatableData } from '../../../store/slice/channel'
import { userData } from '../../../store/slice/user'

import useStyles from './styles'
import { TOAST_DEFAULT_CONFIG } from '../../../constants/toast'
import { getPlatformAccountsAction } from '../../../store/slice/platformAccounts'
import CustomSelect from '../../../components/Form/CustomSelect'

const PLATFORM_ACCOUNTS_OPTIONS = [
  {
    label: 'BOL',
    value: 3,
  },
  {
    label: 'Amazon',
    value: 2,
  },
]

const AddChannelFormSchema = () =>
  Yup.object().shape({
    // platform: Yup.string().required('Required'),
    // advertisingAccount: Yup.string().required('Required'),
    // retailerAccount: Yup.string().required('Required'),
    // country: Yup.string().required('Required'),
    // channelName: Yup.string().required('Required'),
  })

const initialValues = {
  platform: '',
  platformAccounts1: '',
  platformAccounts2: '',
  country: '',
  channelName: '',
}

// const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
//   ({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//       backgroundColor: '#f5f5f9',
//       color: 'rgba(0, 0, 0, 0.87)',
//       maxWidth: 220,
//       fontSize: theme.typography.pxToRem(12),
//       border: '1px solid #dadde9',
//     },
//   })
// )

const AddChannelForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const user = useSelector(userData)
  // const accountCountryData = useSelector(selectAccountCountryData)
  const { queryCreatable } = useSelector((state) => state.channel)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const countriesList = useMemo(() => {
    if (queryCreatable?.countries) {
      return queryCreatable.countries.map((item) => ({ label: item.countryCode, value: item.countryCode }))
    }
    return []
  }, [queryCreatable?.countries])
  const platformAccounts1List = useMemo(() => {
    if (queryCreatable?.platformAccounts1) {
      return queryCreatable.platformAccounts1.map((item) => ({ ...item, value: item.platformAccountID }))
    }
    return []
  }, [queryCreatable?.platformAccounts1])
  const platformAccounts2List = useMemo(() => {
    if (queryCreatable?.platformAccounts2) {
      return queryCreatable.platformAccounts2.map((item) => ({ ...item, value: item.platformAccountID }))
    }
    return []
  }, [queryCreatable?.platformAccounts2])
  useEffect(() => {
    if (user?.accountID) {
      dispatch(getPlatformAccountsAction(user?.accountID))
    }
  }, [user])
  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: AddChannelFormSchema(),
    onSubmit: async (data) => {
      const { platform, platformAccounts1, platformAccounts2, country } = data
      const pl1 = queryCreatable.platformAccounts1.find((el) => el.platformAccountID === platformAccounts1)
      const pl2 = queryCreatable.platformAccounts2.find((el) => el.platformAccountID === platformAccounts2)
      const countryData = { countryCode: country, countryName: country }
      dispatch(
        createChannel({
          id: user.accountID,
          data: {
            channelName: data?.channelName,
            platformAccount1: pl1,
            platformAccount2: pl2,
            country: countryData,
          },
          platformID: platform,
        })
      )
        .unwrap()
        .then(() => {
          toast.success(`Channel created`, TOAST_DEFAULT_CONFIG)
          formik.resetForm()
          navigate('/settings')
        })
        .catch((err) => {
          toast.error(err, TOAST_DEFAULT_CONFIG)
          errorParserSingle(err, formik)
        })
    },
  })

  useEffect(() => {
    const { platform, platformAccounts1 } = formik.values
    const errorLength = Object.keys(formik.errors).length
    if (platform && platformAccounts1 && errorLength <= 0) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }, [formik.values, formik.errors])

  const getPlatformID = (id) => {
    dispatch(getQueryCreatableData({ accountID: user.accountID, platformID: id }))
    formik.values.platformAccounts1 = ''
    formik.values.platformAccounts2 = ''
    formik.values.country = ''
  }

  const handleKeyPress = (event) => {
    const { which, keyCode } = event
    if (keyCode === 13 || which === 13) {
      if (!isButtonDisabled) {
        formik.handleSubmit()
      }
    }
  }

  const handleInputChange = (name, value) => {
    formik.setFieldValue(name, value)
  }

  // useEffect(() => {
  //   if (user.accountID) {
  //     dispatch(getQueryCreatableData(user.accountID))
  //   }
  // }, [user])

  return (
    <ThemeProvider theme={ThemeNSLLight}>
      <Box className={classes.card}>
        <Box className={classes.header}>
          <div className={classes.header_title}>Add channel</div>
          <Box className={classes.description}>
            <div className={classes.descriptionText}>
              {'   Here you can add channels. A "Channel" is a combination of platform and country. So Bol NL is a channel.\n' +
                '              Bol NL, Bol BE and Amazon DE are 3 separate channels. Before adding a channel you need to make sure you\n' +
                '              have added the appropriate API under "API keys".'}
            </div>
          </Box>
        </Box>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <FormGroup className={classes.form_group}>
            <Box className={classes.labelText}>Select platform:</Box>
            <CustomSelect
              label="Select"
              value={formik.values.platform}
              options={PLATFORM_ACCOUNTS_OPTIONS}
              onChange={(e) => {
                handleInputChange('platform', e)
                getPlatformID(e)
              }}
            />
          </FormGroup>
          <FormGroup className={classes.form_group}>
            <Box className={classes.labelText}> Advertising API account:</Box>
            <CustomSelect
              label="Select"
              value={formik.values.platformAccounts1}
              options={platformAccounts1List}
              onChange={(e) => handleInputChange('platformAccounts1', e)}
            />
            {/* <HtmlTooltip */}
            {/*  title={ */}
            {/*    <div color="inherit"> */}
            {/*      Here you can add your platform account ( */}
            {/*      <a href="http://Bol.com" target="_blank" rel="noreferrer"> */}
            {/*        http://Bol.com */}
            {/*      </a> */}
            {/*      , Amazon, Kaufland etc.) and you can specify the country you want to target (NL, BE, DE etc.). The */}
            {/*      combination of platform and country we call a “Channel” (e.g. “Amazon DE”). */}
            {/*    </div> */}
            {/*  } */}
            {/* > */}
            {/*  <div className={classes.info}> */}
            {/*    <span>i</span> */}
            {/*  </div> */}
            {/* </HtmlTooltip> */}
          </FormGroup>
          {formik.values.platform === 3 && (
            <FormGroup className={classes.form_group}>
              <Box className={classes.labelText}>Retailer API account:</Box>
              <CustomSelect
                label="Select"
                value={formik.values.platformAccounts2}
                options={platformAccounts2List}
                onChange={(e) => handleInputChange('platformAccounts2', e)}
              />
            </FormGroup>
          )}
          <FormGroup className={classes.form_group}>
            <Box className={classes.labelText}>Country:</Box>
            <CustomSelect
              label="Select"
              value={formik.values.country}
              options={countriesList}
              onChange={(e) => handleInputChange('country', e)}
            />
          </FormGroup>
          <FormGroup className={classes.form_group}>
            <Box className={classes.labelText}>Channel name:</Box>
            <TextField
              className={classes.form_input}
              error={!!formik.errors?.channelName && !!formik.touched?.channelName}
              fullWidth
              id="channelName"
              type="text"
              placeholder="Enter channel name"
              value={formik.values?.channelName}
              helperText={formik.touched?.channelName && formik.errors?.channelName ? formik.errors?.channelName : ''}
              onChange={(e) => handleInputChange('channelName', e.target.value)}
              onFocus={() => formik.setFieldTouched('channelName', false)}
              onBlur={() => formik.setFieldTouched('channelName', true)}
              onKeyPress={handleKeyPress}
            />
          </FormGroup>
          <CardActions className={classes.form_footer}>
            <Box className={classes.form_footer_box}>
              <Link to="/settings">
                <Button variant="outline" size="large" color="primary" type="reset" className={classes.form_btn}>
                  Cancel
                </Button>
              </Link>
            </Box>
            <Box className={classes.form_footer_box}>
              <Button
                variant="outline"
                size="large"
                color="primary"
                className={classes.form_btn}
                disabled={isButtonDisabled}
                type="submit"
              >
                Add
              </Button>
            </Box>
          </CardActions>
          {formik.status && (
            <Box className={classes.form_alert}>
              <strong>Error Message</strong> {formik.status?.message}
            </Box>
          )}
        </form>
      </Box>
    </ThemeProvider>
  )
}
export default AddChannelForm

import axios from 'axios'

// PRODUCT FILES
export const downloadActivationFileReq = (accountID) => axios.get(`/products/${accountID}/channelProductAssignment/CSV`)

export const uploadActivationFileReq = async (accountID, data, config) =>
  axios.post(`/products/${accountID}/channelProductAssignment/CSV`, data, {
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/csv',
    },
    ...config,
  })

export const downloadAttributesFileReq = (accountID) => axios.get(`/products/${accountID}/productAttributes/CSV`)

export const uploadAttributesFileReq = (accountID, data, config) =>
  axios.post(`/products/${accountID}/productAttributes/CSV`, data, {
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/csv',
    },
    ...config,
  })
// PRODUCT FILES END

// PRODUCT ANALYSIS END
export const productAnalysisGroupingsReq = (accountID) => axios.get(`/productAnalysis/${accountID}/groupings`)

export const productAnalysisReq = (accountID, params) =>
  axios.get(`/productAnalysis/${accountID}`, {
    params,
  })

export const productAnalysisCSVReq = (accountID, params) =>
  axios.get(`/productAnalysis/${accountID}?observable=1`, {
    params,
  })
// PRODUCT ANALYSIS END

export const productKeyword = (accountID, channelID, productID) =>
  axios.get(`/keywordControl/${accountID}/${channelID}/${productID}`)

export const changeProductKeyword = (accountID, channelID, productID, data) =>
  axios.patch(`/keywordControl/${accountID}/${channelID}/${productID}`, data)

export const productStatusUpdateReq = (accountID, data) => axios.patch(`/products/${accountID}`, data)

export const getProductsCanBeAdded = (accountID, channelID) =>
  axios.get(`/channels/${accountID}/${channelID}/addProducts2/queryAvailable`)
export const addProductsEan = (accountID, channelID, data) =>
  axios.post(`/channels/${accountID}/${channelID}/addProducts2/add`, data)

import { TextareaAutosize } from '@mui/material'
import useStyles from './styles'

const CustomTextarea = ({ value, onChange = () => {}, label, placeholder, customClass }) => {
  const classes = useStyles()

  return (
    <div className={classes.wrapperBlock}>
      {label && <p className={classes.label}>{label}</p>}
      <TextareaAutosize
        placeholder={placeholder}
        className={`${classes.textArea} ${customClass}`}
        value={value}
        onChange={(e) => {
          onChange(e.target.value)
        }}
      />
    </div>
  )
}

export default CustomTextarea

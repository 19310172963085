import axios from 'axios'

export const getPlatformAccounts = (accountId) => axios.get(`platformAccounts/${accountId}`)
export const updatePlatformAccounts = (accountId, data) =>
  axios.patch(`platformAccounts/${accountId}?platformAccountID=${data?.platformAccountID}&label=${data?.label}`)
export const createPlatformAccounts = (accountId, data) => axios.post(`/platformAccounts/${accountId}`, data)
export const uploadProductFile = async (accountID, channelID, data) =>
  axios.post(`/channels/${accountID}/${channelID}/addProducts`, data, {
    headers: {
      Accept: '*/*',
      'Content-Type': 'application/csv',
    },
  })
export const getPlatformList = (accountId) => axios.get(`platformAccounts/${accountId}/addAPI/queryConnectableAPIs`)
export const addPlatformAccountsInitiateConnection = (accountId, platformID, label) =>
  axios.post(`/platformAccounts/${accountId}/addAPI/initiateConnection?platformID=${platformID}&label=${label}`)

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    labelStyle: {
      fontWeight: 300,
      textTransform: 'capitalize!important',
      fontStyle: 'italic',
      color: '#000000',
      opacity: 1,
    },
    select: {
      width: '100%',
      '& .MuiFormLabel-root': {
        top: '50%',
        transform: 'translateY(-50%)',
        paddingLeft: 20,
        fontSize: 12,
        lineHeight: 1.5,
        fontWeight: 800,
        textTransform: 'uppercase',
        color: '#000',
        '&[data-shrink="true"]': {
          paddingLeft: 20,
        },
        '&.MuiFormLabel-filled': {
          opacity: 0,
          display: 'none',
        },
      },
      '& .MuiOutlinedInput-input': {
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 1.5,
        textTransform: 'uppercase',
        paddingTop: '5px !important',
        paddingBottom: '5px !important',
        display: 'flex',
        alignItems: 'center',
        '&::placeholder': {
          fontWeight: 300,
          textTransform: 'capitalize',
          fontStyle: 'italic',
          color: '#000000',
          opacity: 1,
        },
      },
      '& .MuiSvgIcon-root': {
        color: '#000',
      },
      '& fieldset': {
        top: 0,
        border: '1px solid rgba(26, 18, 63, 0.5)',
      },
      '& legend': {
        display: 'none',
      },
    },
  })
)

export default useStyles

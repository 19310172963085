import { Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

import useStyles from './styles'

const CustomButton = ({
  href,
  icon,
  isDownload,
  isLoading,
  onClick,
  loadingText,
  withProgress,
  progress,
  children,
}) => {
  const classes = useStyles()

  const renderIcon = () => {
    if (isLoading && !withProgress) {
      return (
        <span className={classes.button_icon}>
          <CircularProgress />
        </span>
      )
    }
    if (isLoading && withProgress) {
      return (
        <div className={classes.icon_progress}>
          <CircularProgress variant="determinate" value={progress} />
          <div>
            <div>{progress}%</div>
          </div>
        </div>
      )
    }
    return <span className={classes.button_icon}>{icon}</span>
  }

  // eslint-disable-next-line no-nested-ternary
  return href && isDownload ? (
    <a href={href} className={classes.button} download={isDownload}>
      {icon && <span className={classes.button_icon}>{isLoading ? <CircularProgress /> : icon}</span>}
      <span className={classes.button_text}>{children}</span>
    </a>
  ) : href ? (
    <Link to={href} className={classes.button}>
      {icon && <span className={classes.button_icon}>{isLoading ? <CircularProgress /> : icon}</span>}
      <span className={classes.button_text}>{children}</span>
    </Link>
  ) : (
    <button className={classes.button} onClick={() => !isLoading && onClick()} type="button">
      {icon && renderIcon()}
      {loadingText && isLoading ? (
        <span className={`${classes.button_text} ${classes.button_text_loading}`}>{loadingText}</span>
      ) : (
        <span className={classes.button_text}>{children}</span>
      )}
    </button>
  )
}

export default CustomButton

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    date: {
      display: 'flex',
      alignItems: 'center',
      // theme.spacing(5.625)
      '& .react-datepicker': {
        '&-wrapper': {
          flex: 1,
          '&:first-child': {
            marginRight: 16,
          },
        },
        '&__input-container': {
          '& input': {
            background: '#fff',
            border: '1px solid rgba(26, 18, 63, 0.5)',
            borderRadius: 5,
            height: 40,
            padding: '0 15px',
            fontWeight: 400,
            fontSize: 12,
            lineHeight: 1.5,
            width: '100%',
          },
        },
      },
      '& .react-datepicker__day--in-selecting-range': {
        backgroundColor: '#1A0063',
      },
    },
    date_range_wraper: {
      position: 'relative',
      height: 40,
      display: 'flex',
      alignItems: 'center',
      padding: '10px',
      borderRadius: 5,
      background: '#f2f2f2',
      cursor: 'pointer',
    },
    date_range: {
      display: 'flex',
      flexDirection: 'column',
      position: 'absolute',
      width: '700px',
      left: 0,
      top: 55,
      zIndex: 100,
      background: '#F2F2F2',
      borderRadius: '5px',
      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
      '& .rdrCalendarWrapper': {
        width: '100%',
      },
      '& .rdrStartEdge, & .rdrInRange, & .rdrEndEdge': {
        color: '#1A0063 !important',
      },
      '& .rdrDayToday .rdrDayNumber span:after': {
        background: '#1A0063 !important',
      },
      '& .rdrDayStartPreview,   & .rdrDayEndPreview, & .rdrDayInPreview': {
        color: '#1A0063 !important',
      },
      '& .rdrDateDisplayItemActive': {
        borderColor: '#1A0063 !important',
      },
      '& .rdrDateDisplayWrapper': {
        display: 'none',
      },
      '& .rdrMonthAndYearWrapper': {
        background: '#f2f2f2',
        paddingTop: 0,
      },
      '& .rdrNextPrevButton, & .rdrNextButton': {
        background: '#FFFFFF',
        boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '5px',
      },
      '& .rdrNextPrevButton i, & .rdrNextButton i': {
        margin: '0 0 0 10px',
        borderWidth: '0px 1px 1px 0px',
        borderColor: 'black',
        padding: '3px',
        transform: 'rotate(-225deg)',
      },
      '& .rdrNextButton i': {
        margin: '0 0 0 6px',
        transform: 'rotate(-45deg)',
      },
      '& .rdrMonth': {
        padding: '15px 6px',
        width: '100%',
      },
      '& .rdrWeekDays': {
        marginBottom: 15,
      },
      '& .rdrWeekDay': {
        fontWeight: 700,
        fontSize: 12,
        lineHeight: 1.5,
        color: '#000000',
      },
      '& .rdrDayNumber': {
        fontWeight: 500,
        fontSize: 12,
      },
      '& .rdrInRange': {
        background: 'rgba(26, 0, 99, 0.1)',
        color: '#000000 !important',
      },
      '& .rdrInRange + span *': {
        color: '#000000 !important',
      },
      '& .rdrInRange + span + span *': {
        color: '#000000 !important',
      },
      '& .rdrStaticRangeSelected': {
        color: '#1A0063 !important',
      },
    },
    apply_btn: {
      margin: '15px 15px 15px auto !important',
      fontSize: '12px !important',
      lineHeight: '17px !important',
      minHeight: '30px !important',
      fontWeight: 700,
      padding: '7px !important',
      border: '1px solid #1A0063 !important',
      color: '#1A0063 !important',
      transition: '0.3s',
      '&.Mui-disabled': {
        borderColor: '#C4C4C4',
        color: '#C4C4C4',
      },
      '&:hover': {
        backgroundColor: '#1A0063 !important',
        color: '#fff !important',
      },
    },
    form_input: {
      '& .MuiFormLabel-root': {
        fontWeight: 800,
        background: '#ffffff',
        fontSize: 12,
        lineHeight: 1.5,
        textTransform: 'uppercase',
        color: '#000',
        top: '50%',
        outline: '0',
        transform: 'translate(20px, -50%) scale(1)',
        padding: 0,
        '&.MuiFormLabel-filled, &.MuiInputLabel-shrink': {
          opacity: 0,
          pointerEvents: 'none',
        },
        '&.Mui-error': {
          color: '#000',
        },
      },
      '& .MuiInputBase-root': {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'transparent',
          },
        },
      },
      '& .MuiInputBase-input': {
        background: '#f2f2f2',
        fontWeight: 800,
        fontSize: 12,
        lineHeight: 1.5,
        textTransform: 'uppercase',
        color: '#000',
        padding: '0 1.25rem',
        height: 40,

        '&:focus': {
          borderColor: 'transparent',
          outline: 'none',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
        top: 0,
        '& legend': {
          opacity: 0,
          pointerEvents: 'none',
          background: 'transparent',
          display: 'none',
        },
      },
    },
    date_range_text: {
      width: '100%',
      fontWeight: 400,
      fontSize: 12,
      lineHeight: 1.5,
      left: 10,
      '& span': {
        fontWeight: 800,
        fontSize: 7,
      },
    },
  })
)

export default useStyles

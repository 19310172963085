/* eslint-disable no-unused-vars */
import { SliderThumb } from '@mui/material/Slider'
import useStyles from './styles'

const CustomThumbComponent = (props) => {
  const { children, hideLabel, ...other } = props
  const classes = useStyles()

  // console.log(other)
  return (
    <div className={classes.slider_thumb}>
      <SliderThumb {...other}>
        {children}
        {!hideLabel && (
          <>
            <span className="custom-bar" />
            <span className="custom-bar" />
            <span className="custom-bar" />
          </>
        )}
        {hideLabel && other.ownerState.value}
      </SliderThumb>
    </div>
  )
}

export default CustomThumbComponent

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@mui/material/Box'

import { Link } from 'react-router-dom'
import { setPage } from '../../store/slice/page'

import PageWrapper from '../../containers/PageWrapper'
import AddChannelForm from '../../containers/Form/AddChannelForm'

import useStyles from './styles'
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg'

const AddChannel = () => {
  const classes = useStyles()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setPage('addChannel'))
  })

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <Box className={classes.page_content}>
          <AddChannelForm />
        </Box>
        <Link className={classes.closeBtn} to="/settings">
          <CloseIcon />
        </Link>
      </Box>
    </PageWrapper>
  )
}
export default AddChannel

import { configureStore } from '@reduxjs/toolkit'

import user from './slice/user'
import product from './slice/product'
import channel from './slice/channel'
import page from './slice/page'
import platformAccounts from './slice/platformAccounts'
import cockpit from './slice/cockpit'
import rules from './slice/rules'

export const store = configureStore({
  reducer: {
    user,
    product,
    channel,
    page,
    platformAccounts,
    cockpit,
    rules,
  },
  devTools: process.env.NODE_ENV !== 'production',
})

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    copyright: {
      fontSize: 12,
      lineHeight: 1.5,
      textAlign: 'center',
      color: theme.palette.text.white,
      '& a': {
        color: 'inherit',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  })
)

export default useStyles

// import React, { useEffect, useState } from 'react'
import React from 'react'
import useStyles from './styles'

const PageHeadline = ({ children, isWhite }) => {
  const classes = useStyles()
  // const [isStick, setIsStick] = useState(false)

  // const handleScroll = () => {
  //   const pos = window.pageYOffset

  //   if (pos > 0) {
  //     setIsStick(true)
  //   } else {
  //     setIsStick(false)
  //   }
  // }

  // useEffect(() => {
  //   handleScroll()

  //   window.addEventListener('scroll', handleScroll)
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, [])
  // return <div className={`${classes.page_headline} ${isStick ? classes.sticky : ''}`}>{children}</div>
  return <div className={`${classes.page_headline} ${isWhite ? classes.page_headline_white : ''} `}>{children}</div>
}

export default React.memo(PageHeadline)

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    main_headline: {
      fontWeight: 'bold !important',
      lineHeight: '144px',
      textTransform: 'uppercase',
      color: theme.palette.text.white,
      [theme.breakpoints.up('xs')]: {
        fontSize: '10vw',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '7vw',
      },
      [theme.breakpoints.up('lg')]: {
        fontSize: 120,
      },
    },
  })
)

export default useStyles

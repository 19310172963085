import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import { setPage } from '../../store/slice/page'
import PageWrapper from '../../containers/PageWrapper'
import PageHeadline from '../../components/PageHeadline'
import {
  getRules,
  getRulesAvailable,
  selectRules,
  selectRulesAvailable,
  selectRulesLoading,
  setLoading,
  updateRules,
  updateValue,
} from '../../store/slice/rules'
import { getAccountID } from '../../utils/auth'
import RuleItem from './RuleItem'
import Loader from '../../components/Loader'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'

import useStyles from './styles'

const RulesPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const data = useSelector(selectRules)
  const rulesAvailable = useSelector(selectRulesAvailable)
  const loading = useSelector(selectRulesLoading)

  const updatedRulesExist = useMemo(() => data.some((item) => item.updated), [data])

  const saveChanges = async () => {
    dispatch(setLoading(true))
    const changed = data.filter((item) => item.updated)
    const queue = []

    changed.forEach((item) => {
      queue.push(
        dispatch(
          updateRules({
            id: getAccountID(),
            guid: item.GUID,
            data: {
              GUID: item.GUID,
              actions: item.actions,
              active: item.active,
              name: item.name,
              scopeSelector: item.scopeSelector,
            },
          })
        ).unwrap()
      )
    })

    Promise.all(queue).then(() => {
      toast.success('Rules updated successfully', TOAST_DEFAULT_CONFIG)
      dispatch(getRules(getAccountID()))
    })
  }

  useEffect(() => {
    dispatch(setPage('rules'))
    dispatch(getRules(getAccountID()))
  }, [])

  useEffect(() => {
    if (data?.length) {
      const filterderData = data.filter((d) => d.name === 'organicRankBasedBidding')
      if (filterderData.length) {
        dispatch(getRulesAvailable(getAccountID()))
      }
    }
  }, [data])

  const stockRules = useMemo(
    () =>
      data.filter(
        (item) => item.name === 'stockControlRule-decreaseBids' || item.name === 'stockControlRule-stopAdvertising'
      ),
    [data]
  )

  const organicRules = useMemo(() => data.filter((item) => item.name === 'organicRankBasedBidding'), [data])
  const isOrganicRulesAvailable = useMemo(() => !rulesAvailable.includes('organicRankBasedBidding'), [rulesAvailable])

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <PageHeadline>
          <Box className={classes.headline}>
            <Box className={classes.headline_btn}>
              <Button variant="outlined" className={classes.button} onClick={saveChanges} disabled={!updatedRulesExist}>
                <span>Save</span>
              </Button>
            </Box>
          </Box>
        </PageHeadline>
        <Box className={classes.page_content}>
          <Box className={classes.title}>Stock control (beta, Bol only)</Box>
          <Box className={classes.description}>
            Avoid advertising for products with low stock levels.
            <br />
            <br /> By choosing either “remaining days of stock” or “units in stock”, you can reduce advertising pressure
            or even stop advertising altogether at the limits you set. <br />
            <br />
            You can use these rules separately or in combination (recommended).
          </Box>
          {!loading && !!data?.length && (
            <Box className={classes.list}>
              {stockRules.map((item) => (
                <RuleItem
                  data={item}
                  key={item.guid}
                  onActiveChange={(e) => {
                    dispatch(
                      updateValue({
                        ...item,
                        active: e.target.checked ? 1 : 0,
                      })
                    )
                  }}
                  onCriteriaValueChange={(e) => {
                    dispatch(
                      updateValue({
                        ...item,
                        scopeSelector: {
                          ...item.scopeSelector,
                          selectorCriterion: {
                            ...item.scopeSelector.selectorCriterion,
                            criterionValueOrFunction: String(e),
                          },
                        },
                      })
                    )
                  }}
                  onCriteriaFieldChange={(e) => {
                    dispatch(
                      updateValue({
                        ...item,
                        scopeSelector: {
                          ...item.scopeSelector,
                          selectorCriterion: {
                            ...item.scopeSelector.selectorCriterion,
                            criterionFieldOrFunction: String(e),
                          },
                        },
                      })
                    )
                  }}
                  onActionParameterChange={(e) => {
                    dispatch(
                      updateValue({
                        ...item,
                        actions: [
                          {
                            ...item.actions[0],
                            actionParameter: String(e),
                          },
                        ],
                      })
                    )
                  }}
                />
              ))}
            </Box>
          )}
          {!loading && !!data?.length && (
            <>
              <br />
              <br />
              <br />
              <Box className={classes.title}>Organic rank-based bidding (beta, Bol only)</Box>
              <Box className={classes.description}>
                {isOrganicRulesAvailable &&
                  'This a premium feature that is not active in your account yet. Please, contact DeepCliq support should you be interested in a trial.'}
              </Box>
              <Box className={[classes.description, isOrganicRulesAvailable && classes.description_disabled]}>
                Avoid wasting budget on advertisements for search terms that already rank prominently on page one
                organically.
                <br />
                <br />
                With the “Organic rank threshold” you set the limit from which bids for advertisements are reduced. With
                “Bid reduction” you set the percentage with which the bid will be lowered. At 100% the bid is 0 and you
                stop advertising.
                <br />
                <br />
                <b>Example</b>
                <br />
                For your account you set the threshold at 7 and the bid reduction at 60% (we call that a “7/60
                configuration”).
                <br />
                <br />
                When bidding on keyword XYZ for product 123 DeepCliq will check the organic rank for keyword XYZ and
                product 123. If the organic rank is at place 4, which is better than the threshold, the bid is reduced
                by 60% and the advertisement will typically disappear from page one and might be displayed on pages two,
                three or lower. That way you avoid paying for advertisements for which your organic rank is already very
                good.
              </Box>
              <Box className={classes.list}>
                {organicRules.map((item) => (
                  <RuleItem
                    disabled={!rulesAvailable.includes(item.name)}
                    data={item}
                    key={item.guid}
                    onActiveChange={(e) => {
                      dispatch(
                        updateValue({
                          ...item,
                          active: e.target.checked ? 1 : 0,
                        })
                      )
                    }}
                    onCriteriaValueChange={(e) => {
                      dispatch(
                        updateValue({
                          ...item,
                          scopeSelector: {
                            ...item.scopeSelector,
                            selectorCriterion: {
                              ...item.scopeSelector.selectorCriterion,
                              criterionValueOrFunction: String(e),
                            },
                          },
                        })
                      )
                    }}
                    onCriteriaFieldChange={(e) => {
                      dispatch(
                        updateValue({
                          ...item,
                          scopeSelector: {
                            ...item.scopeSelector,
                            selectorCriterion: {
                              ...item.scopeSelector.selectorCriterion,
                              criterionFieldOrFunction: String(e),
                            },
                          },
                        })
                      )
                    }}
                    onActionParameterChange={(e) => {
                      dispatch(
                        updateValue({
                          ...item,
                          actions: [
                            {
                              ...item.actions[0],
                              actionParameter: String(e),
                            },
                          ],
                        })
                      )
                    }}
                  />
                ))}
              </Box>
            </>
          )}
          {loading && (
            <div className={classes.loaderWrapper}>
              <Loader className={classes.transparent} />
            </div>
          )}
        </Box>
      </Box>
    </PageWrapper>
  )
}
export default RulesPage

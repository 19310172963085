import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { Link, useParams } from 'react-router-dom'
import { Button } from '@mui/material'
import { toast } from 'react-toastify'
import { setPage } from '../../store/slice/page'

import PageWrapper from '../../containers/PageWrapper'

import useStyles from './styles'
import { ReactComponent as CloseIcon } from '../../assets/icons/cancel.svg'
import { channelsList, getChannelsAction } from '../../store/slice/channel'
import { userData } from '../../store/slice/user'
import ProductsTable from './Table'
import CustomCheckbox from '../../components/CustomCheckbox'
import CustomTextarea from '../../components/CustomTextarea'
import SearchBar from '../../components/Form/SearchBar'

import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'
import { addProductsEan, getProductsCanBeAdded } from '../../api/product'

const AddProduct = () => {
  const user = useSelector(userData)
  const classes = useStyles()
  const dispatch = useDispatch()
  const channelData = useParams()
  const channelsDataList = useSelector(channelsList)
  const [productsList, setProductList] = useState([])
  const [textData, setDataText] = useState('')
  const [search, setSearch] = useState('')

  const [tableList, setTableList] = useState([])
  const [isLoadingTable, setIsLoadingTable] = useState(false)
  const [isAddingProducts, setIsAddingProducts] = useState(false)

  const selectAll = useMemo(() => tableList.every((item) => item.selected), [tableList])
  const isSomeSelected = useMemo(() => tableList.some((item) => item.selected), [tableList])
  const currentChannelData = useMemo(
    () => channelsDataList.find((el) => el.id.toString() === channelData.id.toString()),
    [channelsDataList, channelData]
  )

  const onSearch = (str) => {
    setSearch(str)
    const arr = productsList?.filter(
      (item) =>
        item.productLabel.toLowerCase().includes(str.toLowerCase()) ||
        item.EAN.toLowerCase().includes(str.toLowerCase()) ||
        item.productBrand.toLowerCase().includes(str.toLowerCase())
    )
    setTableList(arr)
  }

  const toggleSelectAll = () => {
    const mappedList = tableList.map((item) => ({ ...item, selected: !selectAll }))

    setProductList(mappedList)
    setTableList(mappedList)
  }

  const onChangeSelect = (id, value) => {
    const mappedList = tableList.map((item) => {
      if (id === item.id) {
        return {
          ...item,
          selected: value,
        }
      }
      return item
    })
    setProductList(mappedList)
    setTableList(mappedList)
  }

  const onSelectItems = () => {
    const arr = textData.split('\n')
    const mappedList = tableList.map((item) => {
      const res = arr.find((el) => el === item.EAN)
      if (res) {
        return {
          ...item,
          selected: true,
        }
      }

      return item
    })

    setProductList(mappedList)
    setTableList(mappedList)
  }

  const fetchTableData = (accountID, channelID) => {
    setIsLoadingTable(true)
    getProductsCanBeAdded(accountID, channelID)
      .then((res) => {
        if (res.status === 200) {
          const data = res.data.map((item, index) => ({
            ...item,
            id: index,
            selected: false,
          }))
          setProductList(data)
          setTableList(data)
        }
      })
      .finally(() => {
        setIsLoadingTable(false)
      })
      .catch((err) => {
        toast.error(err.response.data?.Error, TOAST_DEFAULT_CONFIG)
      })
  }

  const onAddProduct = () => {
    setIsAddingProducts(true)
    const formDataList = tableList
      .filter((el) => el.selected)
      .map((el) => {
        if (el.ASINandSKU && (el.ASINandSKU.ASIN || el.ASINandSKU.SKU)) {
          return { ASINandSKU: el.ASINandSKU }
        }
        if (el.EAN) {
          return { EAN: el.EAN }
        }
        return { EAN: '' }
      })

    addProductsEan(user?.accountID, channelData.id, formDataList)
      .then((res) => {
        if (res.status === 200) {
          // const arrMess = res.data.informationMessages.map((item) => item.informationMessage)
          fetchTableData(user?.accountID, channelData.id)
          // toast.success(arrMess.join('\n'), TOAST_DEFAULT_CONFIG)
          toast.success(
            'Advertising campaigns for the selected product(s) are being generated. They will be visible in the Cockpit within 24 hrs.',
            TOAST_DEFAULT_CONFIG
          )
          setSearch('')
          setDataText('')
        }
      })
      .finally(() => {
        setIsAddingProducts(false)
      })
      .catch((err) => {
        let arrMess = []
        if (err.response.data.errors) {
          arrMess = err.response.data.errors.map((item) => item.message)
        } else if (err.response.data.errorMessages) {
          arrMess = err.response.data.errorMessages.map((item) => item.errorMessage)
        } else if (err.response.data.Error) {
          arrMess = [err.response.data.Error]
        }
        toast.error(arrMess.join(' '), TOAST_DEFAULT_CONFIG)
      })
  }

  useEffect(() => {
    dispatch(setPage('addChannel'))
  })

  useEffect(() => {
    if (user?.accountID && channelData.id) {
      fetchTableData(user?.accountID, channelData.id)
    }
  }, [user?.accountID, channelData.id])

  useEffect(() => {
    if (user?.accountID) {
      dispatch(getChannelsAction(user?.accountID))
    }
  }, [user])

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <Box className={classes.page_content}>
          <div className={classes.header_title}>Add new products to {currentChannelData?.channelName}</div>
          <Box className={classes.description}>
            <div className={classes.descriptionText}>
              Only products that are not advertised yet are shown below. To add new products, checkmark one or several
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              and click "Add products"
            </div>
            <Box className={classes.wrapperFlex}>
              <Box className={classes.rightBlock}>
                <Box className={classes.wrapperTable}>
                  <ProductsTable
                    search={search}
                    onChangeSelect={onChangeSelect}
                    list={tableList}
                    accountId={user?.accountID}
                    loading={isLoadingTable || isAddingProducts}
                    loadingText={isAddingProducts ? 'Please wait, adding product...' : 'Please wait, loading data...'}
                  />
                </Box>
                <Box className={classes.wrapperBottom}>
                  <Box className={classes.flexWrapper}>
                    <CustomCheckbox
                      disabled={isLoadingTable || isAddingProducts || !tableList.length}
                      value={selectAll}
                      onChange={() => toggleSelectAll()}
                    />
                    <p className={classes.labelText}>Select all</p>
                  </Box>
                  <Box className={classes.addProducts}>
                    <Button
                      disabled={!isSomeSelected || isAddingProducts}
                      onClick={onAddProduct}
                      variant="outline"
                      size="large"
                      color="primary"
                      className={classes.form_btn}
                      type="submit"
                    >
                      Add products
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Box className={classes.leftBlock}>
                <SearchBar
                  value={search}
                  onSubmit={onSearch}
                  onChange={(str) => {
                    if (!str) {
                      onSearch('')
                    }
                    setSearch(str)
                  }}
                  onClear={onSearch}
                  placeholder="Search EAN, Name or Brand...."
                />
                <Box className={classes.wrapperArea}>
                  <CustomTextarea
                    customClass={classes.textArea}
                    placeholder="Copy and paste the EANs you want to select in the list into this text box..."
                    label="Copy & Paste entry"
                    value={textData}
                    onChange={(val) => {
                      setDataText(val)
                    }}
                  />
                  <Box className={classes.wrapperSelectBtn}>
                    <Box className={classes.form_footer_box}>
                      <Button
                        disabled={!textData?.length || !tableList.length}
                        onClick={onSelectItems}
                        variant="outline"
                        size="large"
                        color="primary"
                        className={classes.form_btn}
                        type="submit"
                      >
                        Select
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Link className={classes.closeBtn} to="/products">
          <CloseIcon />
        </Link>
      </Box>
    </PageWrapper>
  )
}
export default AddProduct

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '.MuiPopover-root': {
        borderRadius: '100px',
        boxShadow: '10px 10px 5px 0px rgba(0,0,0,0.75);',
      },
    },
    text_center: {
      textAlign: 'center',
      justifyContent: 'center !important',
      margin: '0 auto',
    },
    text_left: {
      textAlign: 'left',
      justifyContent: 'flex-start !important',
      margin: '0 auto 0 0',
    },
    page_wrapper: {},
    page_content: {
      position: 'relative',
      '& .MuiPaper-root': {
        background: '#fff',
        height: '100%',
        transition: '0.3s',
      },
      '& .Mui-TableHeadCell-Content-Labels': {
        alignItems: 'flex-start',
        width: '100%',

        '& .MuiBadge-root': {
          position: 'absolute',
          right: 5,
        },

        '& .Mui-TableHeadCell-Content-Wrapper:not(:last-child)': {
          '& >div>span:first-child': {
            display: 'block',
            paddingRight: 20,
          },
          '& >div>span:nth-child(2)': {
            display: 'block',
            paddingRight: 0,
          },
        },

        '& .Mui-TableHeadCell-Content-Wrapper': {
          width: '100%',
        },
      },
      '& .MuiBadge-root': {
        transform: 'translateY(3px)',
      },
      '& .MuiBox-root': {
        background: '#fff',
      },
      '& .MuiTableContainer-root': {
        height: 'calc(100% - 56px)',
        transition: '0.3s',
      },
      '& .MuiTableRow-root': {
        backgroundColor: 'transparent !important',
        '&:hover': {
          '& td': {
            backgroundColor: 'rgb(242, 242, 242) !important',
          },
        },
      },

      '& th': {
        '&:not(:nth-child(2))': {
          '& .Mui-TableHeadCell-Content': {
            justifyContent: 'flex-end',
          },
        },
        '&:nth-child(2), &:nth-child(3)': {
          '& .Mui-TableHeadCell-Content-Wrapper': {
            // textAlign: 'left',
          },
        },

        '&:nth-child(8), &:nth-child(9), &:nth-child(10)': {
          '& .Mui-TableHeadCell-Content-Wrapper': {
            // textAlign: 'center',
          },
        },
        padding: 0,
        fontWeight: 800,
        fontSize: 14,
        textAlign: 'right',
        minHeight: 84,
        '& .Mui-TableHeadCell-Content': {
          width: '100%',
          height: '100%',
          padding: '15px 12px 10px',
          display: 'flex',
          alignItems: 'flex-start',
        },

        '& th:first-child': {
          '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
            display: 'none',
          },
        },

        '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
          width: 1,
          background: 'transparent',
          height: '100%',
          top: 0,
          bottom: 0,
          right: '15px',
        },
      },
      '& th:not(:last-child)': {
        borderRight: '1px solid rgba(196, 196, 196, 0.25)',
      },
      '& tr': {
        '&:hover': {
          '& td': {
            // backgroundColor: 'rgb(242, 242, 242) !important',
          },
        },
        '& td': {
          // borderBottom: '1px solid rgba(196, 196, 196, 0.25)',
          // fontWeight: 300,
          // fontSize: 14,
          // textTransform: 'capitalize',
          // padding: 12,
        },
        '& td:not(:last-child)': {
          borderRight: '1px solid rgba(196, 196, 196, 0.25)',
        },
        '& td:not(:first-child):not(:nth-child(2))': {
          justifyContent: 'flex-end',
        },
        '& td:nth-child(8), td:nth-child(9), td:nth-child(10)': {
          // justifyContent: 'center !important',
        },
      },
    },
    header_col: {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
    },
    name_td: {
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    transform_td: {
      paddingRight: 20,
      '& + span': {
        position: 'absolute',
        right: 0,
      },
    },
    td_acos: {
      width: 50,
      height: 23,
      borderRadius: 5,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 600,
      fontSize: 14,
      lineHeight: 1,
      '&.white': {
        background: '#fff',
      },
      '&.red': {
        background: '#FFD9E0',
      },
      '&.blue': {
        background: '#97F0FF',
      },
    },
    bgFix: {
      width: '100%',
      height: 80,
      background: 'white',
      position: 'fixed',
      zIndex: 99,
    },
    headline: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      gap: 20,
      width: '100%',
      minWidth: '1250px',
      padding: '20px 0',
      transition: '0.3s',
    },
    headline_collapsed: {
      marginTop: -60,
    },
    headline_row: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
      width: '100%',
    },
    headline_filter: {
      display: 'flex',
      alignItems: 'center',
      flex: 1,
      '& > *': {
        flex: 'auto',
        '&': {
          marginRight: theme.spacing(2.5),
        },
      },
    },
    headline_select: {
      width: '100%',
      maxWidth: theme.spacing(20.67),
      '& > div': {
        width: '100%',
        maxWidth: '100%',
      },
      '& > div > div': {
        width: '100%',
        maxWidth: '100%',
      },
    },
    headline_slider: {
      width: '100%',
      maxWidth: theme.spacing(43.9),
      '&:nth-child(3)': {
        maxWidth: theme.spacing(46.6),
      },
    },
    headline_search: {
      width: '100%',
      maxWidth: theme.spacing(46.6),
    },
    headline_date: {
      width: '100%',
      maxWidth: theme.spacing(21),
      '& > div > div > div': {
        width: '100%',
      },
    },
    headline_save: {
      '& > *:not(:last-child)': {
        marginRight: 10,
      },
    },
    expand_button: {
      '&.MuiButtonBase-root': {
        padding: '0 10px',
        fontSize: 10,
        fontWeight: 300,
        lineHeight: '100%',
        textTransform: 'unset',
        minWidth: 'unset',
      },
      '& svg': {
        transition: '0.3s',
      },
    },
    expanded: {
      '& svg': {
        transform: 'rotate(180deg)',
      },
    },
    align_center: {
      display: 'flex',
      width: '100%',
      textAlign: 'center',
    },
    hide_col: {
      position: 'absolute',
      left: 10,
      top: 40,
      zIndex: 10,
    },
    popover: {
      width: '200px',
      height: '100px',
    },
    popover_header: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    csv_btn: {
      color: '#333333 !important',
      position: 'absolute !important',
      left: 20,
      bottom: 10,
      zIndex: 10,
      minHeight: '35px !important',
      padding: '0 10px !important',
      borderColor: '#333333 !important',
      fontSize: '14px  !important',
      '& span': {
        marginRight: '2px !important',
      },
      '& .MuiLoadingButton-loadingIndicator': {
        left: '5px !important',
      },
      '&:hover': {
        color: '#fff !important',
        // background: '#333333 !important',
      },
    },
  })
)
export default useStyles

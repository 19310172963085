import { projectConfig } from '../config'

/* eslint no-param-reassign: "error" */
export default function setupAxios(axios) {
  axios.defaults.baseURL = projectConfig.apiUrl
  axios.interceptors.request.use(
    (config) => config,
    (error) => Promise.reject(error)
  )
}

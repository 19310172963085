import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    slider: {
      display: 'flex',
      alignItems: 'center',
      background: '#f2f2f2',
      border: '0',
      borderRadius: 5,
      height: 40,
      padding: '0 15px',
      gap: 7,
      // theme.spacing(5.625)
    },
    slider_label: {
      fontWeight: 800,
      fontSize: 14,
      lineHeight: 1.5,
    },
    slider_elem: {
      display: 'flex',
      alignItems: 'center',
      gap: 14,
      flex: 1,
      '& .MuiSlider-rail': {
        height: 5,
        background: '#C6C6C6',
        opacity: 1,
      },
      '& .MuiSlider-track': {
        border: 'none',
        height: 5,
      },
      '& .MuiSlider-thumb': {
        width: 20,
        height: 20,
        '& .MuiSlider-valueLabel': {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'transparent',
          padding: 0,
          fontWeight: 700,
          fontSize: 10,
          lineHeight: 1.5,
        },
        '& .custom-bar': {
          height: 9,
          width: 1,
          backgroundColor: '#fff',
          marginLeft: 1,
          marginRight: 1,
        },
      },
    },
    range_input: {
      '&.MuiFormControl-root': {
        minWidth: 55,
        maxWidth: 60,
        height: 25,
        background: '#fff',
      },
      '& .MuiInputBase-root': {
        height: 100,
        padding: 5,
      },
      '&. MuiInputAdornment-root': {
        marginLeft: 1,
      },
      '& input': {
        minWidth: 25,
        maxWidth: 34,
        padding: 0,
        textAlign: 'center',
        fontSize: 14,
        '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },

        '&[type=number]': {
          '-moz-appearance': 'textfield',
        },
      },
    },
  })
)

export default useStyles

import { TABLE_VIEW_VARIANT } from './TABLE_TYPE'
import { rowGenerator } from './rowGenerator'
import { rowTotalGenetator } from './rowTotalGenetator'

export const buildTable = (response, selectedAcosRange) => {
  const res = response.performanceMetrics
  let tableType

  // Fix old realization when res.containedSubgroups.length === 1 && res.containedSubgroups.length > 1 and separated table displayed like combined when only one channel exist
  // if (res.containedSubgroups.length === 1) {
  //   if (res.containedSubgroups[0].containedProducts.length > 0) {
  //     tableType = TABLE_VIEW_VARIANT.SIMPLE

  //     const dataForTable = res.containedSubgroups[0]

  //     const totalData = rowTotalGenetator(dataForTable)

  //     const row = dataForTable?.containedProducts.map((item, index) =>
  //       rowGenerator(item, selectedAcosRange?.[0], selectedAcosRange?.[1], index)
  //     )

  //     return {
  //       tableData: {
  //         totalData,
  //         row,
  //       },
  //       tableType,
  //     }
  //   }

  //   const isSeparateType = res.containedSubgroups[0]?.containedSubgroups?.length > 0

  //   if (isSeparateType) {
  //     tableType = TABLE_VIEW_VARIANT.DOUBLE_NESTED
  //   } else {
  //     tableType = TABLE_VIEW_VARIANT.ONE_NESTED
  //   }

  //   const row = res.containedSubgroups.map((item, index) =>
  //     rowGenerator(item, selectedAcosRange?.[0], selectedAcosRange?.[1], index)
  //   )

  //   const totalData = rowTotalGenetator(res)

  //   return {
  //     tableData: {
  //       totalData,
  //       row,
  //     },
  //     tableType,
  //   }
  // }

  if (res.containedProducts.length) {
    tableType = TABLE_VIEW_VARIANT.SIMPLE

    const dataForTable = res

    const totalData = rowTotalGenetator(dataForTable)

    const row = dataForTable?.containedProducts.map((item, index) =>
      rowGenerator(item, selectedAcosRange?.[0], selectedAcosRange?.[1], index)
    )

    return {
      tableData: {
        totalData,
        row,
      },
      tableType,
    }
  }

  // Fix old realization when res.containedSubgroups.length === 1 && res.containedSubgroups.length > 1 and separated table displayed like combined when only one channel exist
  if (res.containedSubgroups.length >= 1) {
    const isSeparateType = res.containedSubgroups.some((item) => item?.containedSubgroups?.length > 0)

    if (isSeparateType) {
      tableType = TABLE_VIEW_VARIANT.DOUBLE_NESTED
      const row = res.containedSubgroups.map((item, index) =>
        rowGenerator(item, selectedAcosRange?.[0], selectedAcosRange?.[1], index)
      )

      const totalData = rowTotalGenetator(res)

      return {
        tableData: {
          totalData,
          row,
        },
        tableType,
      }
    }

    tableType = TABLE_VIEW_VARIANT.ONE_NESTED
    const row = res.containedSubgroups.map((item, index) =>
      rowGenerator(item, selectedAcosRange?.[0], selectedAcosRange?.[1], index)
    )

    const totalData = rowTotalGenetator(res)

    return {
      tableData: {
        totalData,
        row,
      },
      tableType,
    }
  }

  return {
    tableData: {
      totalData: null,
      row: [],
    },
    tableType: null,
  }
}

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

import useStyles from './styles'

const CustomSelectV2 = ({ label, value, options = [], onChange = () => {} }) => {
  const classes = useStyles()

  const handleChange = (event) => {
    onChange(event.target.value)
  }

  return (
    <Box className={classes.select}>
      <FormControl className={classes.select} fullWidth>
        <InputLabel className={classes.labelStyle} id="demo-simple-select-label">
          {label}
        </InputLabel>
        <Select
          IconComponent={KeyboardArrowDownIcon}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {options.map((option) => (
            <MenuItem value={option?.value} key={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomSelectV2

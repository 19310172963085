import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    terms: {
      backgroundColor: theme.palette.background.main,
      minHeight: '100vh',
    },
    xl_container: {
      maxWidth: theme.breakpoints.values.lg,
    },
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      margin: `${theme.spacing(0)} 0 ${theme.spacing(0)} auto`,
      paddingTop: '1rem',
      paddingBottom: '4rem',
    },
    main_header: {
      marginLeft: 'auto',
      marginTop: '4vh',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
      width: '100%',
      marginBottom: '45px',
    },
    main_title: {
      fontSize: '46px',
      fontWeight: 700,
      lineHeight: '46px',
      color: '#ffffff',
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
    },
    topic: {
      color: '#ffffff',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '20px',
      '&:not(:last-child)': {
        marginBottom: '40px',
      },
      '& p': {
        margin: 0,
      },
      '& h2': {
        fontWeight: 400,
        fontSize: '34px',
        lineHeight: '60px',
        marginTop: 0,
        marginBottom: '24px',
      },
    },
  })
)
export default useStyles

import MuliRegWoff2 from '../assets/fonts/Muli/Muliregular.woff2'
import MuliRegWoff from '../assets/fonts/Muli/Muliregular.woff'
import MuliblackWoff2 from '../assets/fonts/Muli/Muliblack.woff2'
import MuliblackWoff from '../assets/fonts/Muli/Muliblack.woff'
import MuliboldWoff2 from '../assets/fonts/Muli/Mulibold.woff2'
import MuliboldWoff from '../assets/fonts/Muli/Mulibold.woff'
import MulisemiboldWoff2 from '../assets/fonts/Muli/Mulisemibold.woff2'
import MulisemiboldWoff from '../assets/fonts/Muli/Mulisemibold.woff'
import MulilightWoff2 from '../assets/fonts/Muli/Mulilight.woff2'
import MulilightWoff from '../assets/fonts/Muli/Mulilight.woff'
import MuliextraboldWoff2 from '../assets/fonts/Muli/Muliextrabold.woff2'
import MuliextraboldWoff from '../assets/fonts/Muli/Muliextrabold.woff'
import MuliextralightWoff2 from '../assets/fonts/Muli/Muliextralight.woff2'
import MuliextralightWoff from '../assets/fonts/Muli/Muliextralight.woff'

const themeStyleOverrides = `
  img{
  max-width:100%; display:block;
  max-height:100%;
  }
  a{color:inherit}
  
  @font-face {
    font-family: "Muli";
    src: url(${MuliRegWoff2}) format("woff2"),
          url(${MuliRegWoff}) format("woff");
    font-display: swap;
    font-weight: 400;
    font-style: normal;
  }
  //@font-face {
  //  font-family: "Muli";
  //  src: url(${MuliblackWoff2}) format("woff2"),
  //  url(${MuliblackWoff}) format("woff");
  //  font-display: swap;
  //  font-weight: 900;
  //  font-style: normal;
  //}
  
  @font-face {
    font-family: "Muli";
    src: url(${MuliboldWoff2}) format("woff2"),
    url(${MuliboldWoff}) format("woff");
    font-display: swap;
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: "Muli";
    src: url(${MulisemiboldWoff2}) format("woff2"),
    url(${MulisemiboldWoff}) format("woff");
    font-display: swap;
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: "Muli";
    src: url(${MulilightWoff2}) format("woff2"),
    url(${MulilightWoff}) format("woff");
    font-display: swap;
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "Muli";
    src: url(${MuliextraboldWoff2}) format("woff2"),
    url(${MuliextraboldWoff}) format("woff");
    font-display: swap;
    font-weight: 800;
    font-style: normal;
  }
  //
  //@font-face {
  //  font-family: "Muli";
  //  src: url(${MuliextralightWoff2}) format("woff2"),
  //  url(${MuliextralightWoff}) format("woff");
  //  font-display: swap;
  //  font-weight: 100;
  //  font-style: normal;
  //}
`
export default themeStyleOverrides

import { Switch } from '@mui/material'
import { styled } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
import { useMixedSwitch } from './styles'

const useStyles = makeStyles(useMixedSwitch())

const IOSSwitch = styled((props) => {
  const mixed = useStyles()
  return (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple classes={props.isMixed ? mixed : {}} {...props} />
  )
})(({ theme }) => ({
  width: 36,
  height: 18,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',

    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.button.radio,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.button.radio,
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[300],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    boxShadow: 'none',
    marginLeft: 1,
  },
  '& .MuiSwitch-track': {
    borderRadius: 100,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.divider : theme.palette.secondary,
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}))
export default IOSSwitch

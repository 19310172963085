import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    authPage: {
      backgroundColor: theme.palette.background.main,
    },
    checkbox: {
      '& [type="checkbox"], & [type="radio"]': {
        position: 'absolute',
        left: '-9999px',
        width: 'inherit',
        height: 'inherit',
        '& ~ label': {
          position: 'relative',
          display: 'block',
          minHeight: 30,
          paddingLeft: 20,
          margin: '-5px 0',
          cursor: 'pointer',
          fontWeight: 400,
          fontSize: 14,
          lineHeight: 1.5,
          '& a': {
            color: '#000',
          },
          '&::after': {
            content: '""',
            display: 'block',
            position: 'absolute',
            background: 'transparent',
            top: 8,
            left: 3,
            width: 14,
            height: 14,
            borderRadius: '50%',
            transition: 'all .3s ease',
          },
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            background: 'transparent',
            top: 5,
            left: 0,
            width: 20,
            height: 20,
            border: '1px solid #000',
            borderRadius: '50%',
            transition: 'all .3s ease',
          },
          '&:hover': {
            '&::before': {},
          },
          '&:not(:empty)': {
            margin: 0,
            paddingLeft: 36,
          },
        },
        '&:disabled': {
          '& ~ label': {
            '&::after': {
              opacity: 0,
            },
          },
        },
        '&:checked': {
          '& ~ label': {
            '&::after': {
              background: theme.palette.button.radio,
            },
          },
        },
      },
      '& [type="radio"]': {
        '& ~ label': {
          '&::before, &::after': {
            borderRadius: '50%',
          },
        },
      },
    },
  })
)
export default useStyles

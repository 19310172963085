import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    wrapperSpinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '90vh',
      zIndex: '99',
      '& .MuiCircularProgress-root': {
        color: '#333333',
      },
    },
  })
)
export default useStyles

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    page_wrapper: {},
    page_content: {
      padding: '45px 125px',
    },
    headline: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    headline_link: {
      display: 'flex',
      alignItems: 'center',
    },
    headline_btn: {
      minWidth: 80,
      marginLeft: 'auto',
    },
    title: {
      fontSize: 26,
      fontWeight: 800,
      marginBottom: 10,
    },
    description: {
      fontSize: 14,
      fontWeight: 400,
      marginBottom: 45,
      maxWidth: 658,
    },
    description_disabled: {
      color: 'rgba(0,0,0,0.2)',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      gap: 43,
    },
    rules: {},
    rule_item: {
      display: 'flex',
      alignItems: 'center',
      gap: 25,
    },
    rule_item_text: {
      fontSize: 15,
      fontWeight: 700,
    },
    rule_item_text_disabled: {
      color: 'rgba(0,0,0,0.2)',
    },
    rule_criteria_value_select: {
      width: 120,
      '& >*': {
        width: '100%',
        maxWidth: '100%',
      },
      '& >*>*': {
        width: '100%',
        maxWidth: '100%',
      },
    },
    rule_criteria_select: {
      width: 220,
      '& >*': {
        width: '100%',
        maxWidth: '100%',
      },
      '& >*>*': {
        width: '100%',
        maxWidth: '100%',
      },
    },
    rule_action_select: {
      width: 120,
      '& >*': {
        width: '100%',
      },
      '& >*>*': {
        width: '100%',
        maxWidth: '100%',
      },
    },
    loaderWrapper: {
      marginTop: 20,
      position: 'relative',
      width: '100%',
      height: 85,
    },
    transparent: {
      background: 'transparent !important',
    },
  })
)
export default useStyles

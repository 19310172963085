import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    page_wrapper_row: {
      display: 'flex',
    },
    // page_wrapper: {
    // overflow: 'hidden',
    // },
    page_wrapper_content: {
      width: '70%',
      '&.MuiBox-root': {
        padding: 0,
      },
    },
    dashboard_papep: {
      marginTop: theme.spacing(2.5),
      padding: `${theme.spacing(4.3)} ${theme.spacing(4.3)} ${theme.spacing(1.5)}`,
    },
  })
)
export default useStyles

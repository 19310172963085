import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    slider_thumb: {
      '& .MuiSlider-thumb': {
        width: 20,
        height: 20,
        zIndex: 2,
        '& .MuiSlider-valueLabel': {
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: 'transparent',
          padding: 0,
          fontWeight: 700,
          fontSize: 10,
          lineHeight: 1.5,
        },
        '& .custom-bar': {
          height: 9,
          width: 1,
          backgroundColor: '#fff',
          marginLeft: 1,
          marginRight: 1,
        },
      },
    },
  })
)

export default useStyles

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    page_headline: {
      display: 'flex',
      alignItems: 'center',
      // overflowX: 'auto',
      background: theme.palette.background.gray,
      minHeight: theme.spacing(10),
      padding: `0 ${theme.spacing(5.625)}`,
      // position: 'relative',
      position: 'sticky',
      top: 0,
      zIndex: 100,
      // '&::before': {
      //   display: 'block',
      //   position: 'absolute',
      //   content: "''",
      //   left: 0,
      //   top: 0,
      //   bottom: 0,
      //   right: '-100%',
      //   background: theme.palette.background.gray,
      //   zIndex: -100,
      // },
    },
    sticky: {
      position: 'fixed',
      left: '230px',
      top: 0,
      right: '2px',
      zIndex: 100,
    },
    page_headline_white: {
      background: '#fff',
      boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.15);',
      padding: '0 20px',
    },
  })
)

export default useStyles

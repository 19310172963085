import { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ThemeProvider } from '@mui/material/styles'
import { CssBaseline } from '@mui/material'
import { ToastContainer } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

import { getAccountInfoAction, getUser } from './store/slice/user'
import { getAccountID, useAuth } from './utils/auth'
import RouterConfig from './routes/RouterConfig'
import ThemeNSLDark from './theme/theme-nsl'

function App() {
  const auth = useAuth()

  const dispatch = useDispatch()

  useEffect(() => {
    if (auth) {
      dispatch(getUser({ accountID: getAccountID() })).then((res) => {
        dispatch(getAccountInfoAction(res.payload.accountID))
      })
    }
  }, [auth, getAccountID])

  return (
    <ThemeProvider theme={ThemeNSLDark}>
      <CssBaseline />
      <BrowserRouter>
        <RouterConfig />
        <ToastContainer />
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App

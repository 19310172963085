import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 650,
      width: '100%',
    },
    form: {
      width: '100%',
      maxWidth: 500,
    },
    form_alert: {
      marginTop: theme.spacing(4),
      border: '1px solid #FF0000',
      borderRadius: 5,
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
      fontSize: 16,
      lineHeight: 1.4,
    },
    form_group: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      '&.MuiFormGroup-root': {
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          flexWrap: 'nowrap',
        },
      },
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
      '& .MuiFormControl-root': {
        '&:not(:last-child)': {
          marginBottom: theme.spacing(3),
        },
      },
      '& .MuiFormLabel-root': {
        fontWeight: 300,
        fontSize: 12,
        lineHeight: 1.5,
      },
      // '& .MuiSelect-select': {
      //   fontWeight: '800 !important',
      //   fontSize: 12,
      //   lineHeight: '2.5!important',
      //   textTransform: 'uppercase',
      //   display: '-webkit-box',
      //   'webkit-box-orient': 'vertical',
      //   '-webkit-line-clamp': 1,
      //   '-webkit-box-orient': 'vertical',
      //   ' white-space': 'normal!important',
      //   overflow: 'hidden',
      //   'text-overflow': 'ellipsis',
      // },
    },
    form_group_col: {
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(3),
          marginBottom: 0,
        },
      },
    },
    form_input: {
      // '& .MuiFormLabel-root': {
      //   fontWeight: 300,
      //   fontSize: 12,
      //   lineHeight: 1.5,
      //   textTransform: 'uppercase',
      //   color: '#000',
      //   top: '50%',
      //   transform: 'translate(20px, -50%) scale(1)',
      //   padding: 0,
      //   '&.MuiFormLabel-filled, &.MuiInputLabel-shrink': {
      //     opacity: 0,
      //     pointerEvents: 'none',
      //   },
      //   '&.Mui-error': {
      //     color: '#000',
      //   },
      // },
      // '& .MuiInputBase-input': {
      //   fontWeight: 800,
      //   fontSize: 12,
      //   lineHeight: 1.5,
      //   color: '#000',
      //   padding: '0 1.25rem',
      //   height: 40,
      //   '&::placeholder': {
      //     fontWeight: 300,
      //     textTransform: 'capitalize',
      //     fontStyle: 'italic',
      //     color: '#000000',
      //     opacity: 1,
      //   },
      // },
      // '& .MuiOutlinedInput-notchedOutline': {
      //   borderColor: 'rgba(26, 18, 63, 0.5)',
      //   top: 0,
      //   '& legend': {
      //     opacity: 0,
      //     pointerEvents: 'none',
      //     background: 'transparent',
      //     display: 'none',
      //   },
      // },

      width: '100%',
      '& .MuiInputBase-input': {
        background: '#F2F2F2',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 1.5,
        color: '#000',
        padding: '0 45px 0 10px',
        height: 40,

        '&::placeholder': {
          lineHeight: 1.5,
          fontSize: 12,
          fontWeight: 400,
          color: '#000',
          opacity: 1,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(26, 18, 63, 0.5)',
        top: 0,
        '& legend': {
          opacity: 0,
          pointerEvents: 'none',
          background: 'transparent',
          display: 'none',
        },
      },
      '& fieldset': {
        border: 0,
      },
    },
    form_footer: {
      padding: '0 !important',
      marginTop: theme.spacing(3),
      justifyContent: 'flex-end',
    },
    form_footer_box: {
      width: 125,
      textAlign: 'center',
      '&:not(:last-child)': {
        marginRight: theme.spacing(3),
      },
      '&:not(:first-of-type)': {
        marginLeft: '0 !important',
      },
      '& > a': {
        display: 'flex',
        textDecoration: 'none',
      },
      '& .MuiButtonBase-root': {
        border: '1px solid #1A0063',
        color: '#1A0063',
        transition: '0.3s',
        '&.Mui-disabled': {
          borderColor: '#C4C4C4',
          color: '#C4C4C4',
        },
        '&:hover': {
          backgroundColor: '#1A0063',
          color: '#fff',
        },
      },
    },
    form_btn: {
      width: '100%',
      flexGrow: 1,
      fontWeight: 'bold',
      textTransform: 'capitalize!important',
    },
    header: {
      marginBottom: theme.spacing(4.375),
      maxWidth: 626,
    },
    header_title: {
      textAlign: 'left',
      fontWeight: 800,
      fontSize: '1.625rem',
      lineHeight: 1.26,
      color: '#000',
      marginBottom: 10,
    },
    descriptionText: {
      fontSize: 14,
    },
    labelText: {
      minWidth: 200,
      fontSize: 15,
      fontWeight: 700,
      '& + div': {
        maxWidth: '100%',
        width: '100%',
        '& > div': {
          maxWidth: '100%',
          width: '100%',
        },
      },
    },
    info: {
      position: 'absolute',
      right: '-49px',
      top: 'calc(50% - 14px)',
      minWidth: 28,
      width: 28,
      height: 28,
      borderRadius: '50%',
      background: '#1A0063',
      marginLeft: 20,
      color: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
    },
  })
)
export default useStyles

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    authPage: {
      backgroundColor: theme.palette.background.main,
    },
    wrapperBlock: {},
    label: {
      fontSize: 14,
      fontWeight: '800',
      marginBottom: 18,
    },
    textArea: {
      resize: 'none',
      padding: 20,
      width: 459,
      minHeight: 170,
      borderRadius: 15,
      outline: 'none',
      '&::placeholder': {
        fontSize: 14,
        color: '#333',
        fontFamily: 'Muli,Arial,sans-serif',
      },
    },
  })
)
export default useStyles

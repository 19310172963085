import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { userData } from '../store/slice/user'
import { useAuth } from '../utils/auth'

const UnauthorizedRoute = ({ children, ...props }) => {
  const auth = useAuth()
  const { accountID } = useSelector(userData)

  if (auth || accountID) {
    return props.redirect ? <Navigate to={props.redirect} /> : <Navigate to="/404" />
  }

  return children
}
export default UnauthorizedRoute

import { Checkbox } from '@mui/material'
import { useEffect, useState } from 'react'
import useStyles from './styles'

const CustomCheckbox = ({ value, onChange = () => {}, disabled }) => {
  const classes = useStyles()
  const [checked, setChecked] = useState(value)
  useEffect(() => {
    setChecked(value)
  }, [value])
  const handleChange = () => {
    setChecked(!checked)
    onChange(!checked)
  }
  return (
    <div className={classes.checkbox}>
      <Checkbox disabled={disabled} onChange={handleChange} checked={checked} />
    </div>
  )
}

export default CustomCheckbox

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    hide_col_btn: {
      width: 50,
      position: 'absolute',
      bottom: 0,
      left: -52,
    },
    popover: {
      width: '250px',
      height: 450,
    },
    popover_header: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
      padding: '0 10px',
      display: 'flex',
      justifyContent: 'space-between',
    },
    popover_list: {
      padding: '10px 20px',
      fontSize: 18,
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
      height: 400,
      overflowY: 'auto',
    },
    popover_list_item: {
      display: 'grid',
      gridTemplateColumns: '3fr 1fr',
      gap: '10',
    },
  })
)
export default useStyles

/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createPlatformAccounts, getPlatformAccounts, uploadProductFile } from '../../api/platformAccounts'
import { errorParserRequest } from '../../utils/errorParser'

const initialState = {
  platformAccountsList: [],
  isPlatformAccountsListLoading: false,
  platformAccountsListError: null,
  isPlatformAccountCreateLoading: false,

  isProductFileLoading: false,
}

export const getPlatformAccountsAction = createAsyncThunk(
  'platformAccounts/getPlatformAccountsAction',
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await getPlatformAccounts(accountId)
      if (response.status === 200) {
        if (response.data?.length) {
          return { data: response.data }
        }
        return { data: [] }
      }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)
export const createPlatformAccountsAction = createAsyncThunk(
  'platformAccounts/createPlatformAccountsAction',
  async (data, { rejectWithValue }) => {
    try {
      const response = await createPlatformAccounts(data.id, data.data)
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
    } catch (error) {
      return rejectWithValue(errorParserRequest(error))
    }
  }
)
export const uploadProductFileAction = createAsyncThunk(
  'platformAccounts/uploadProductFileAction',
  async (data, { rejectWithValue }) => {
    try {
      const response = await uploadProductFile(data.id, data.channel, data.file)
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
    } catch (error) {
      return rejectWithValue(errorParserRequest(error))
    }
  }
)

/* eslint-disable no-param-reassign */
export const platformAccountsSlice = createSlice({
  name: 'platformAccounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlatformAccountsAction.pending, (state) => {
        state.isPlatformAccountsListLoading = true
      })
      .addCase(getPlatformAccountsAction.fulfilled, (state, action) => {
        state.platformAccountsList = action.payload.data
        state.isPlatformAccountsListLoading = false
        state.platformAccountsListError = null
      })
      .addCase(getPlatformAccountsAction.rejected, (state, action) => {
        state.isPlatformAccountsListLoading = false
        state.platformAccountsListError = action.payload.message
        state.platformAccountsList = []
      })
      .addCase(createPlatformAccountsAction.pending, (state) => {
        state.isPlatformAccountCreateLoading = true
      })
      .addCase(createPlatformAccountsAction.fulfilled, (state) => {
        state.isPlatformAccountCreateLoading = false
      })
      .addCase(createPlatformAccountsAction.rejected, (state) => {
        state.isPlatformAccountCreateLoading = false
      })
      .addCase(uploadProductFileAction.pending, (state) => {
        state.isProductFileLoading = true
      })
      .addCase(uploadProductFileAction.fulfilled, (state) => {
        state.isProductFileLoading = false
      })
      .addCase(uploadProductFileAction.rejected, (state) => {
        state.isProductFileLoading = false
      })
  },
})

export default platformAccountsSlice.reducer
export const platformAccountsList = (state) => state.platformAccounts.platformAccountsList
export const isPlatformAccountsListLoading = (state) => state.platformAccounts.isPlatformAccountsListLoading
export const selectProductFileUploading = (state) => state.platformAccounts.isProductFileLoading
export const isPlatformAccountCreateLoading = (state) => state.platformAccounts.isPlatformAccountCreateLoading

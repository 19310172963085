import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    slider: {
      width: '100%',
      background: 'transparent !important',
      position: 'relative',

      '& .MuiSlider-rail': {
        background: 'linear-gradient(90deg, #1A0063 1.47%, #FA00FF 99.71%)',
        transition: '0.3s',
        opacity: 1,
        height: 6,
      },

      '& .MuiSlider-track': {
        background: 'transparent',
        border: 0,
        height: 5,
      },

      '& .MuiSlider-root > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#fff !important',
        color: '#fff',

        '&  > span': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#fff !important',
          color: '#1A0063',
          fontSize: 12,
          fontWeight: 700,
        },
        '& .custom-bar': {
          backgroundColor: '#1A0063',
          color: '#1A0063',
        },
      },
    },
    opacity: {
      '& .MuiSlider-rail': {
        background: 'linear-gradient(90deg, rgba(26, 0, 99, 0.50) 1.47%, rgba(250, 0, 255, 0.50) 99.71%)',
      },
    },

    empty: {
      '& .MuiSlider-rail': {
        background: '#E2E8F0',
      },
    },
    diapason: {
      position: 'absolute',
      top: 'calc(50% - 4px)',
      height: 2,
      background: '#fff',
      borderRadius: '40px',
      zIndex: 1,
    },
  })
)

export default useStyles

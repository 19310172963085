import Box from '@mui/material/Box'
import { InputAdornment } from '@mui/material'
import { actionsVerbEnum } from '../../../store/slice/rules'
import IOSSwitch from '../../../components/Form/IOSSwitch'
import CustomSelect from '../../../components/Form/CustomSelect'

import useStyles from '../styles'

const ruleActionText = {
  [actionsVerbEnum.decreaseBids]: ' reduce bids by',
  [actionsVerbEnum.stopAdvertising]: ' stop advertising.',
  [actionsVerbEnum.multiplyBidsByFactorAndUploadNegativeToFullAutos]: ' Bid reduction: ',
  [actionsVerbEnum.multiplyBidsByPercentageAndUploadNegativeToFullAutos]: ' Bid reduction: ',
}

const toggleActionInitState = {
  [actionsVerbEnum.decreaseBids]: true,
  [actionsVerbEnum.stopAdvertising]: false,
  [actionsVerbEnum.multiplyBidsByFactorAndUploadNegativeToFullAutos]: true,
  [actionsVerbEnum.multiplyBidsByPercentageAndUploadNegativeToFullAutos]: true,
}

const actionOptionsRange = Array.from({ length: 19 }, (x, i) => (i + 1) * 5)
const actionOptions = actionOptionsRange.map((item) => ({
  label: item,
  value: item,
}))

const criterionFieldsOptions = [
  {
    label: 'remaining days of stock',
    value: 'remainingStockInDays',
  },
  {
    label: 'units in stock',
    value: 'remainingStockAbsoluteUnits',
  },
]

const criterionValuesOptionsRange = Array.from({ length: 15 }, (x, i) => i + 1)
const criterionValuesOptionsRange2 = Array.from({ length: 9 }, (x, i) => 20 + i * 5)
const criterionValuesOptions = [
  ...criterionValuesOptionsRange.map((item) => ({
    label: item,
    value: item,
  })),
  ...criterionValuesOptionsRange2.map((item) => ({
    label: item,
    value: item,
  })),
]

const criterionValuesOptions2 = [
  ...Array.from({ length: 29 }, (x, i) => i + 1).map((item) => ({
    label: item + 1,
    value: item + 1,
  })),
]
const actionOptions2 = Array.from({ length: 10 }, (x, i) => i + 1).map((item) => ({
  label: item * 10,
  value: item * 10,
}))

const RuleItem = ({
  data,
  disabled = false,
  onActiveChange = () => {},
  onCriteriaValueChange = () => {},
  onCriteriaFieldChange = () => {},
  onActionParameterChange = () => {},
}) => {
  const classes = useStyles()
  const actionVerb = data.actions?.[0]?.actionVerb
  const actionParameter = data.actions?.[0]?.actionParameter
  const criteria = data.scopeSelector.selectorCriterion

  return (
    <Box className={classes.rule_item}>
      <Box>
        <IOSSwitch checked={!!data.active} onChange={onActiveChange} disabled={disabled} />
      </Box>
      <Box className={[classes.rule_item_text, disabled && classes.rule_item_text_disabled]}>
        {data.name === 'organicRankBasedBidding' ? 'Organic rank threshold' : 'As long as a product has'}
      </Box>
      <Box className={[classes.rule_item_text, disabled && classes.rule_item_text_disabled]}>&#60;</Box>
      <Box className={classes.rule_criteria_value_select}>
        <CustomSelect
          value={criteria.criterionValueOrFunction}
          options={data.name === 'organicRankBasedBidding' ? criterionValuesOptions2 : criterionValuesOptions}
          onChange={onCriteriaValueChange}
          disabled={disabled}
        />
      </Box>
      {data.name !== 'organicRankBasedBidding' && (
        <Box className={classes.rule_criteria_select}>
          <CustomSelect
            value={criteria.criterionFieldOrFunction}
            options={criterionFieldsOptions}
            onChange={onCriteriaFieldChange}
            disabled={disabled}
          />
        </Box>
      )}
      <Box className={[classes.rule_item_text, disabled && classes.rule_item_text_disabled]}>
        {ruleActionText[actionVerb]}
      </Box>
      {toggleActionInitState[actionVerb] && (
        <Box className={classes.rule_action_select}>
          <CustomSelect
            value={actionParameter}
            options={data.name === 'organicRankBasedBidding' ? actionOptions2 : actionOptions}
            endAdornment={<InputAdornment position="end">%</InputAdornment>}
            onChange={onActionParameterChange}
            disabled={disabled}
          />
        </Box>
      )}
    </Box>
  )
}

export default RuleItem

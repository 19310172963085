import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    page_wrapper: {},
    page_content: {
      padding: '45px 40px',
    },
    configuration: {
      maxWidth: 626,
    },
    configuration_box: {
      '&:not(:last-child)': {
        marginBottom: 70,
      },
    },
    configuration_title: {
      padding: '5px 0',
      fontWeight: 800,
      fontSize: 14,
      lineHeight: 1.28,
      textTransform: 'uppercase',
      color: '#000',
      '&:not(:last-child)': {
        marginBottom: 5,
      },
    },
    configuration_desc: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.4,
      color: '#000',
      '&:not(:last-child)': {
        marginBottom: 25,
      },
    },
    configuration_btn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    configuration_btn_component: {
      flex: 1,
      '& button': {
        width: '100%',
      },
      '&:not(:last-child)': {
        marginRight: 25,
      },
    },
    hidden: {
      display: 'none',
      pointerEvents: 'none',
      opacity: 0,
    },
  })
)
export default useStyles

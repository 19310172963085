/* eslint-disable no-unreachable */
import Box from '@mui/material/Box'
import useStyles from '../style'
import CustomCheckbox from '../../../../components/CustomCheckbox'

const TableRow = ({ data, onChangeSelect, search }) => {
  const classes = useStyles()
  function highlightText(text, searchText) {
    if (searchText) {
      const parts = text.toLowerCase().split(searchText.toLowerCase())
      return parts.map((part, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={index}>
          {index > 0 ? <span className={classes.bolderText}>{searchText}</span> : null}
          {part}
        </span>
      ))
    }
    return text
  }
  return (
    <Box className={classes.tr}>
      <Box className={`${classes.td} ${classes.td_select}`}>
        <CustomCheckbox value={data.selected} onChange={(e) => onChangeSelect(data.id, e)} />
      </Box>
      <Box className={`${classes.td} ${classes.td_platform}`}>{highlightText(data?.EAN, search)}</Box>
      <Box className={`${classes.td} ${classes.td_name}`}>{highlightText(data?.productLabel, search)}</Box>
      <Box className={`${classes.td} ${classes.td_brand}`}>{highlightText(data?.productBrand, search)}</Box>
    </Box>
  )
}

export default TableRow

import { memo } from 'react'
import IOSSwitch from '../../../components/Form/IOSSwitch'

const MemoSwitch = ({ active, onChangeHandler }) => (
  <IOSSwitch
    isMixed={active === -1}
    checked={active}
    onChange={(e) => {
      onChangeHandler(e.target.checked)
    }}
  />
)

export default memo(MemoSwitch)

export const errorParserSingle = (error, formik) => {
  if (error.status === 422) {
    Object.keys(error.data.errorMessages).forEach((errorItem) => {
      formik.setFieldError(errorItem, error.data.errors[errorItem])
    })
  }
  if (error.status > 399 && error.status < 410) {
    const errorsList = error.data?.errorMessages?.map((item) => item?.errorMessage)
    formik.setStatus({ message: errorsList.toString() })
  }
  if (error?.status === 500) {
    formik.setStatus({ message: 'Something went wrong!' })
  }
}

export const errorParserRequest = (error) => {
  let text = error.toString()

  if (error?.response?.data?.errors?.length) {
    text = error.response.data.errors
      .map(
        (item) => `
          ${item.message}
          ${item.path ? `path: ${item.path}` : ''}
        `
      )
      .join('; ')
  } else if (error?.response?.data?.errorMessages?.length) {
    text = error.response.data.errorMessages.map((item) => item.errorMessage).join('; ')
  } else if (error?.response?.data && typeof error.response.data === 'string') {
    text = error.response.data
  } else if (error?.response?.data?.Error) {
    return error?.response?.data?.Error
  }

  return text
}

export const isValueExist = (value) => value !== null && value !== undefined

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Box, CircularProgress } from '@mui/material'
import { setPage } from '../../store/slice/page'
import { exportReq } from '../../api/export'
import { errorParserRequest } from '../../utils/errorParser'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'

import useStyles from './styles'
import { downloadURI } from '../../utils/download-file'

const Export = () => {
  const dispatch = useDispatch()
  const [loader, setLoader] = useState(false)
  const classes = useStyles()

  const params = useParams()
  const navigate = useNavigate()

  const sendRequest = async (isMounted) => {
    setLoader(true)

    const id = new URLSearchParams(window.location.search).get('id')

    exportReq({
      exportID: id,
    })
      .then((res) => {
        if (res.status === 200 && isMounted && res.data) {
          const blob = new Blob(
            [
              new Uint8Array([0xef, 0xbb, 0xbf]), // UTF-8 BOM
              res.data,
            ],
            { type: 'application/csv;charset=utf-8' }
          )
          const u = window.URL.createObjectURL(blob)
          downloadURI(u, `Export_${id}.csv`)
          window.URL.revokeObjectURL(u)
        }
      })
      .catch((err) => {
        if (isMounted) {
          toast.error(errorParserRequest(err), TOAST_DEFAULT_CONFIG)
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoader(false)
          navigate('/')
        }
      })
  }

  useEffect(() => {
    let isMounted = true

    sendRequest(isMounted)

    return () => {
      isMounted = false
    }
  }, [params])

  useEffect(() => {
    dispatch(setPage('auth'))
  })

  return (
    loader && (
      <Box className={classes.wrapperSpinner}>
        <CircularProgress />
      </Box>
    )
  )
}
export default Export

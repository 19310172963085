export const useMixedSwitch = () => ({
  root: {
    '& .Mui-checked': {
      transform: 'translateX(65%) !important',
    },
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#5e42e361 !important',
    },
  },
})

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    wrapperSpinner: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '50px',
      zIndex: '99',
      '& .MuiCircularProgress-root': {
        color: '#ffffff',
      },
    },
    verifyEmail: {
      backgroundColor: theme.palette.background.main,
    },
    xl_container: {
      maxWidth: theme.breakpoints.values.lg,
    },
    container: {
      minHeight: '100vh',
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      margin: `${theme.spacing(0)} 0 ${theme.spacing(0)} auto`,
      paddingTop: '1rem',
      paddingBottom: '2rem',
    },
    main_footer: {
      marginTop: 'auto',
      marginBottom: '4vh',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: '100%',
    },
    main_grid: {
      width: '100%',
      marginTop: 'auto',
      flexWrap: 'wrap',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
    },
    main_copyright: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '34.5%',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '2rem',
        marginBottom: '2rem',
      },
    },
    wrapperText: {
      margin: 'auto',
    },
    text: {
      fontSize: '4vw',
      fontWeight: 'bold',
      color: 'white',
      margin: '20px',
      textAlign: 'center',
    },
    sub_text: {
      fontSize: '2vw',
      fontWeight: 'bold',
      color: 'white',
      margin: '20px',
      textAlign: 'center',
    },
    linkText: {
      fontSize: '1vw',
      color: 'white',
      textDecoration: 'none',
      margin: 'auto',
      display: 'flex',
      justifyContent: 'center',
    },
  })
)
export default useStyles

const themePaletteDark = {
  type: 'dark',
  primary: {
    main: '#1A0063',
  },
  secondary: {
    main: '#1A0063',
  },
  background: {
    default: '#fff',
    main: '#1A0063',
    gray: '#F2F2F2',
  },
  button: {
    background: '#5E42E3',
    color: '#fff',
    dissable: {
      background: 'rgba(94, 66, 227, 0.5)',
      color: '#fff',
    },
    radio: '#5E42E3',
    outline: {
      hover: {
        background: '#1A0063',
        color: '#fff',
      },
    },
  },
  disabled: {
    default: '#E2E8F0',
  },
  text: {
    primary: '#333333',
    secondary: '#2d3748',
    disabled: '#c4c4c4',
    hint: '#a0aec0',
    white: '#ffffff',
  },
  error: {
    main: '#c80000',
  },
  warning: {
    main: '#d48d03',
  },
  info: {
    main: '#1A0063',
  },
  success: {
    main: '#00c850',
  },
  divider: '#e2e8f0',
}
export default themePaletteDark

import { createTheme } from '@mui/material/styles'

import themePaletteDark from './theme-palette-dark'
import themeBreakpoints from './theme-breakpoints'
import themeStyleOverrides from './theme-styleOverrides'
import themeTypography from './theme-typography'
import themeRoot from './theme-root'
import themeShape from './theme-shape'

const nsl = {
  root: themeRoot,
  palette: themePaletteDark,
  typography: themeTypography,
  components: {
    MuiCssBaseline: {
      styleOverrides: themeStyleOverrides,
    },
    MuiDrawer: {
      styleOverrides: {
        docked: {},
        paper: {
          backgroundColor: themePaletteDark.background.main,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: themePaletteDark.text.white,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 'bold',
          boxShadow: 'none',
          '&.MuiButton-contained': {
            backgroundColor: themePaletteDark.button.background,
            color: themePaletteDark.button.dissable.color,
            transition: '0.3s',
            '&.Mui-disabled': {
              backgroundColor: themePaletteDark.button.dissable.background,
              color: themePaletteDark.button.dissable.color,
            },
          },
          '&.MuiButton-outlined': {
            transition: '0.3s',
            '&:hover': {
              backgroundColor: themePaletteDark.button.outline.hover.background,
              color: themePaletteDark.button.outline.hover.color,
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          boxShadow: 'none',
          borderRadius: 5,
          margin: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            fontSize: 10,
            position: 'absolute',
            right: 0,
            top: '100%',
            textAlign: 'right',
            marginLeft: 0,
            marginRight: 0,
            marginTop: 5,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#4F4F4F',
          lineHeight: 1,
          padding: '0 0.25rem',
          '&[data-shrink="true"]': {
            padding: '0',
            fontSize: '0.75rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
        input: {
          padding: '1rem 1.25rem',
          height: 'auto',
        },
        notchedOutline: {
          borderColor: '#E2E8F0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          borderRadius: 5,
          fontSize: '1rem',
          padding: '1rem 1.5rem',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        cell: {
          '&:focus,&:focus-within': {
            outline: 'none',
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          padding: '10px 0 !important',
        },
        rail: {
          color: '#C4C4C4',
        },
        thumb: {
          width: 5,
          height: 5,
          '&:after': {
            width: 20,
            height: 20,
          },
          '&.Mui-active': {
            boxShadow: '0px 0px 0px 6px rgb(94 66 227 / 16%)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-error[data-shrink="true"]': {
            color: themePaletteDark.text.primary,
          },
          '&.Mui-error': {
            color: themePaletteDark.error.main,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
      styleOverrides: {
        h6: {
          fontSize: '1.325rem',
          fontWeight: 'bold',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          minHeight: 30,
        },
      },
    },
  },
  breakpoints: themeBreakpoints,
  shape: themeShape,
}

export default createTheme(nsl)

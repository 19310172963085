import { createTheme } from '@mui/material/styles'

import themeBreakpoints from './theme-breakpoints'
import themePaletteLight from './theme-palette-light'
import themeStyleOverrides from './theme-styleOverrides'
import themeTypography from './theme-typography'
import themeRoot from './theme-root'
import themeShape from './theme-shape'

const nslLight = {
  root: themeRoot,
  palette: themePaletteLight,
  typography: themeTypography,
  components: {
    MuiCssBaseline: {
      styleOverrides: themeStyleOverrides,
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: 'none',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          fontWeight: 'bold',
          boxShadow: 'none',
          minHeight: 45,
          '&.MuiButton-contained': {
            backgroundColor: themePaletteLight.button.background,
            color: themePaletteLight.button.dissable.color,
            transition: '0.3s',
            '&.Mui-disabled': {
              backgroundColor: themePaletteLight.button.dissable.background,
              color: themePaletteLight.button.dissable.color,
            },
          },
          '&.MuiButton-outlined': {
            transition: '0.3s',
            '&:hover': {
              backgroundColor: themePaletteLight.button.outline.hover.background,
              color: themePaletteLight.button.outline.hover.color,
            },
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: '#A0AEC0',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          boxShadow: 'none',
          borderRadius: 5,
          margin: 0,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiFormHelperText-root': {
            fontSize: 10,
            position: 'absolute',
            right: 0,
            top: '100%',
            textAlign: 'right',
            marginLeft: 0,
            marginRight: 0,
            marginTop: 5,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#4F4F4F',
          lineHeight: 1,
          padding: '0 0.25rem',
          '&[data-shrink="true"]': {
            padding: '0',
            fontSize: '0.75rem',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 5,
        },
        input: {
          padding: '1rem 1.25rem',
          height: 'auto',
        },
        notchedOutline: {
          borderColor: '#E2E8F0',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          borderRadius: 5,
          fontSize: '1rem',
          padding: '1rem 1.5rem',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          padding: '10px 0 !important',
        },
        rail: {
          color: '#C4C4C4',
        },
        thumb: {
          width: 5,
          height: 5,
          '&:after': {
            width: 10,
            height: 10,
          },
          '&.Mui-active': {
            boxShadow: '0px 0px 0px 6px rgb(94 66 227 / 16%)',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-error': {
            color: themePaletteLight.error.main,
          },
          '&.Mui-error[data-shrink="true"]': {
            color: themePaletteLight.text.primary,
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
      styleOverrides: {
        h6: {
          fontSize: '1.325rem',
          fontWeight: 'bold',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          marginTop: '-0.7rem !important',
        },
        tooltip: {
          backgroundColor: '#333',
          borderRadius: 8,
          width: 'fit-content',
          maxWidth: 250,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontSize: 16,
          border: 'none',
          color: '#000000',
        },
        columnHeader: {
          '&:focus,&:focus-within': {
            outline: 'none',
          },
        },
        overlay: {
          backgroundColor: '#f8f8f8',
        },
        columnHeaderTitle: {
          fontSize: 12,
          fontWeight: 800,
          color: themePaletteLight.text.hint,
        },
        cell: {
          borderTop: `1px solid #E2E8F0`,
          borderBottom: 0,
          // whiteSpace: 'initial',
          // display: '-webkit-box',
          // WebkitLineClamp: 3,
          // webkitBoxOrient: 'vertical',

          '&:focus,&:focus-within': {
            outline: 'none',
          },
        },
        row: {
          '&.MuiDataGrid-row--editing': {
            boxShadow: 'none',
          },
        },
        iconButtonContainer: {
          visibility: 'visible',
          width: 'auto',
        },
        columnSeparator: {
          display: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: 40,
          height: 40,
          boxSizing: 'border-box',
          paddingTop: 17,
          paddingBottom: 5,
          fontSize: 12,
          lineHeight: 1.5,
          fontWeight: 400,
          background: '#fff',
          '&[aria-expanded="true"] ~ fieldset': {
            border: '1px solid rgba(26, 18, 63, 0.5)',
            borderBottom: 0,
            borderRadius: '5px 5px 0 0',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: 0,
          borderRadius: 0,
          boxShadow: 'none',
          color: '#000',
          '& .MuiMenuItem-root': {
            borderLeft: '1px solid rgba(26, 18, 63, 0.5)',
            borderRight: '1px solid rgba(26, 18, 63, 0.5)',
            paddingLeft: 20,
            paddingRight: 15,
            whiteSpace: 'break-spaces',
            '&:last-child': {
              borderBottom: '1px solid rgba(26, 18, 63, 0.5)',
              borderRadius: '0 0 5px 5px',
            },
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          width: 1,
          minWidth: '100%',
          boxShadow: 'none',
          borderRadius: '0 0 5px 5px',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '& .MuiPaper-root': {
            width: 'auto',
            minWidth: 100,
            borderRadius: '5px',
          },
        },
      },
    },
  },
  breakpoints: themeBreakpoints,
  shape: themeShape,
}

export default createTheme(nslLight)

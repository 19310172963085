import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    authPage: {
      backgroundColor: theme.palette.background.main,
    },
    checkbox: {
      '& .MuiButtonBase-root': {
        padding: 0,
      },
      '& .MuiButtonBase-root.Mui-checked': {
        color: '#5E42E3',
      },
    },
  })
)
export default useStyles

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { toast } from 'react-toastify'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { useNavigate } from 'react-router-dom'
import { TextField } from '@mui/material'
import { ReactComponent as AddCircleOutlineIcon } from '../../assets/icons/circle-progress.svg'
import CustomSelectV2 from '../../components/Form/CustomSelectV2'

import { setPage } from '../../store/slice/page'
import { ADD_CHANNEL } from '../../routes/constants'

import PageWrapper from '../../containers/PageWrapper'
import PageHeadline from '../../components/PageHeadline'
import CustomButton from '../../components/CustomButton'

import { isPlatformAccountsListLoading } from '../../store/slice/platformAccounts'
import { userData } from '../../store/slice/user'
import { channelsList, getChannelsAction, isChannelsListLoading } from '../../store/slice/channel'
import { changeChannels, changeChannelsLabel } from '../../api/channels'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'
import { fontAwesomeIcons } from '../Cockpit/utils/tableIcons'
import HtmlTooltip from '../../components/HtmlTooltip'

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import { ReactComponent as ConfirmIcon } from '../../assets/icons/confirm.svg'

import BolLogo from '../../assets/logo/bol.jpg'
import AzLogo from '../../assets/logo/az.svg'
import KlLogo from '../../assets/logo/kl.svg'
import GoogleLogo from '../../assets/logo/google.svg'

import useStyles from './styles'

const logoList = {
  BOL: BolLogo,
  Amazon: AzLogo,
  Kaufland: KlLogo,
  Google: GoogleLogo,
}

const acosRange = Array.from({ length: 51 }, (x, i) => i)
const commissionRange = Array.from({ length: 26 }, (x, i) => i)
const marginRange = Array.from({ length: 71 }, (x, i) => i + 10)
const shippingRange = Array.from({ length: 49 }, (x, i) => i + 2)
const returnCostRange = Array.from({ length: 48 }, (x, i) => i + 3)
const returnRateRange = Array.from({ length: 51 }, (x, i) => i)

const grossMarginOptions = marginRange.map((item) => ({
  label: item,
  value: item / 100,
}))

const shippingCostOptions = shippingRange.map((item) => ({
  label: item,
  value: item,
}))

const returnCostOptions = returnCostRange.map((item) => ({
  label: item,
  value: item,
}))

const returnRateOptions = returnRateRange.map((item) => ({
  label: item,
  value: item / 100,
}))

const acosOptions = acosRange.map((item) => ({
  label: item,
  value: item / 100,
}))

const commisionOptions = commissionRange.map((item) => ({
  label: item,
  value: item / 100,
}))

const Settings = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(userData)
  const channelsDataList = useSelector(channelsList)
  const platformAccountsListLoading = useSelector(isPlatformAccountsListLoading)
  const channelsListLoading = useSelector(isChannelsListLoading)
  const [postDataLoading, setPostDataLoading] = useState(false)
  const [changesRange, setChangesRange] = useState([])
  const [list, setList] = useState([])

  const prepearedData = useMemo(
    () =>
      list.map((item) => {
        const { platformAccount } = item?.platformAccountAndCountry || {}
        const { countryName } = item.country

        return {
          ...item,
          platformAccount: item.platformAccount1?.label,
          platformCode: platformAccount?.platform.platformCode,
          label: platformAccount.label,
          platform_2: item.platformAccount2?.label,
          countryName,
          lang: '-',
          totalCost: (parseFloat(item.ACoSTarget + item.commission) * 100).toFixed(0),
        }
      }),
    [list]
  )

  const isBolAccount = useMemo(
    () =>
      prepearedData.length && prepearedData[0].platformAccountAndCountry?.platformAccount?.platform?.platformID === 3,
    [prepearedData]
  )

  const navigate = useNavigate()
  // const [totalCommission, setTotalCommission] = useState(data?.commission)
  // const [totalAcos, setTotalAcos] = useState(data?.ACoSTarget)
  const [newLabel, setNewLabel] = useState('')

  const goToAddProduct = (id) => {
    navigate(`/products/${id}/add`)
  }

  useEffect(() => {
    dispatch(setPage('settings'))
  })

  useEffect(() => {
    if (channelsDataList) {
      const newList = channelsDataList?.map((item) => ({ ...item, isEdit: false }))
      setList(newList)
    }
  }, [channelsDataList])

  useEffect(() => {
    if (user?.accountID) {
      dispatch(getChannelsAction(user?.accountID))
    }
  }, [user])

  const onEditLabel = (data) => {
    const arr = list.map((item) => {
      if (item.id === data?.id) {
        return { ...item, isEdit: true }
      }
      return { ...item, isEdit: false }
    })
    setList(arr)
  }

  const cancelEditLabel = () => {
    const arr = list.map((item) => ({ ...item, isEdit: false }))
    setList(arr)
  }

  const saveEditLabel = (data) => {
    const arr = list.map((item) => {
      if (item.id === data?.id) {
        return { ...item, channelName: data?.channelName, isEdit: false }
      }
      return { ...item, isEdit: false }
    })

    const formData = {
      channelID: data?.id,
      channelName: data.channelName,
    }

    changeChannelsLabel(user?.accountID, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success('Label successfully changed', TOAST_DEFAULT_CONFIG)
          setList(arr)
        }
      })
      .catch((err) => {
        const error = err.response.data?.errorMessages?.map((item) => item?.errorMessage).join('\n')
        toast.error(error, TOAST_DEFAULT_CONFIG)
      })
  }

  const addToChangeRange = useCallback(
    (item) => {
      setList((prev) =>
        prev.map((listItem) => {
          if (listItem.id === item.channel) {
            return {
              ...listItem,
              ACoSTarget: item.ACoSTarget,
              commission: item.commission,
              averageGrossMargin: item.averageGrossMargin,
              averageShippingCosts: item.averageShippingCosts,
              averageReturnCosts: item.averageReturnCosts,
              averageReturnRate: item.averageReturnRate,
            }
          }
          return listItem
        })
      )
      const filterArr = changesRange.filter((el) => el.channel !== item.channel)
      setChangesRange([...filterArr, item])
    },
    [changesRange, list]
  )

  const changeFieldAction = (key, value, data) => {
    addToChangeRange({
      channel: data.id,
      commission: data.commission,
      ACoSTarget: data.ACoSTarget,
      averageGrossMargin: data.averageGrossMargin,
      averageShippingCosts: data.averageShippingCosts,
      averageReturnCosts: data.averageReturnCosts,
      averageReturnRate: data.averageReturnRate,
      [key]: value,
    })
  }

  const handleSave = () => {
    const promises = []
    setPostDataLoading(true)
    changesRange.forEach((item) => {
      const promise = changeChannels(
        user.accountID,
        item.channel,
        item.ACoSTarget,
        item.commission,
        item.averageGrossMargin,
        item.averageShippingCosts,
        item.averageReturnCosts,
        item.averageReturnRate
      )
        .then((res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success(`${res?.data?.Response} `, TOAST_DEFAULT_CONFIG)
          }
        })
        .catch((err) => {
          const errors = err.response.data?.errorMessages?.reduce((acc, curr) => [...acc, curr?.errorMessage], [])
          toast.error(`${errors.join(', ')}`, TOAST_DEFAULT_CONFIG)
        })
      promises.push(promise)
    })
    Promise.all(promises)
      .then(() => {
        dispatch(getChannelsAction(user?.accountID))
      })
      .finally(() => {
        setPostDataLoading(false)
        setChangesRange([])
      })
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'channelName',
        header: 'Channel',
        Header: (
          <div className={classes.header_col} style={{ paddingRight: '43px' }}>
            <span>Channel</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <div
            className={
              row.original.isEdit
                ? `${classes.td} ${classes.td_channel} ${classes.td_channelEdit}`
                : `${classes.td} ${classes.td_channel}`
            }
          >
            {row.original.isEdit ? (
              <TextField
                className={classes.form_input}
                id="channelName"
                type="text"
                label="Channel name"
                placeholder="Channel name"
                value={newLabel}
                onChange={(val) => {
                  setNewLabel(val?.target.value)
                }}
              />
            ) : (
              <span title={renderedCellValue}> {renderedCellValue}</span>
            )}
            <div
              className={
                !row.original.isEdit
                  ? `${classes.td} ${classes.td_buttons} ${classes.td_buttonsNoEdit}`
                  : `${classes.td} ${classes.td_buttons}`
              }
            >
              {row.original.isEdit ? (
                <div className={`${classes.wrapperFlexBtn}`}>
                  <div
                    className={`${classes.button} ${classes.greenIcon}`}
                    onClick={() => {
                      saveEditLabel({ ...row.original, channelName: newLabel })
                    }}
                  >
                    <ConfirmIcon />
                  </div>
                  <div
                    className={`${classes.button}`}
                    onClick={() => {
                      cancelEditLabel()
                    }}
                  >
                    <CancelIcon />
                  </div>
                </div>
              ) : (
                <div
                  className={`${classes.button}`}
                  onClick={() => {
                    setNewLabel(renderedCellValue)
                    onEditLabel(row.original)
                  }}
                >
                  <EditIcon />
                </div>
              )}
            </div>
          </div>
        ),
        enableSorting: false,
        minSize: 350,
        size: 350,
      },
      {
        accessorKey: 'platformCode',
        header: 'Platform',
        Cell: ({ renderedCellValue, row }) => (
          <div className={`${classes.td_platform}`}>
            {logoList[renderedCellValue] && (
              <div className={classes.td_platform_icon}>
                <img src={logoList[renderedCellValue]} alt="Platform logo" />
              </div>
            )}
            <div className={classes.td_platform_text}>{renderedCellValue}</div>
          </div>
        ),
        enableSorting: false,
        size: 120,
        minSize: 120,
      },
      {
        accessorKey: 'platformAccount',
        header: 'Platform account',
        enableSorting: false,
        size: 320,
        minSize: 320,
      },
      {
        accessorKey: 'platform_2',
        header: 'Secondary platform account (BOL)',
        enableSorting: false,
        size: 320,
        minSize: 320,
      },
      {
        accessorKey: 'countryName',
        header: 'Country',
        enableSorting: false,
        size: 100,
        minSize: 100,
      },
      {
        accessorKey: 'managedProducts',
        header: 'Products managed',
        Header: (
          <div className={classes.header_col}>
            <span>Products managed</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_product}>
            {renderedCellValue}
            <div className={classes.td_add_products_upload}>
              <div className={classes.wrapperBtn}>
                <Button
                  onClick={() => goToAddProduct(row.original.id)}
                  variant="outline"
                  size="small"
                  color="primary"
                  type="reset"
                  className={classes.form_btn}
                >
                  Add products
                </Button>
              </div>
            </div>
          </div>
        ),
        size: 170,
        minSize: 170,
      },
      {
        accessorKey: 'averageGrossMargin',
        header: 'Gross margin (Ø, %)',
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_select}>
            <CustomSelectV2
              label="Set gross margin*"
              value={renderedCellValue}
              options={grossMarginOptions}
              onChange={(e) => changeFieldAction('averageGrossMargin', e, row.original)}
            />
          </div>
        ),
        enableSorting: false,
        size: 165,
        minSize: 165,
      },
      {
        accessorKey: 'averageShippingCosts',
        header: 'Shipping costs (Ø, €)',
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_select}>
            <CustomSelectV2
              label="Set shipping costs*"
              value={renderedCellValue}
              options={shippingCostOptions}
              onChange={(e) => changeFieldAction('averageShippingCosts', e, row.original)}
            />
          </div>
        ),
        enableSorting: false,
        size: 170,
        minSize: 170,
      },
      {
        accessorKey: 'averageReturnCosts',
        header: ' Return costs (Ø, €)',
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_select}>
            <CustomSelectV2
              label="Set return costs*"
              value={renderedCellValue}
              options={returnCostOptions}
              onChange={(e) => changeFieldAction('averageReturnCosts', e, row.original)}
            />
          </div>
        ),
        enableSorting: false,
        size: 160,
        minSize: 160,
      },
      {
        accessorKey: 'averageReturnRate',
        header: 'Return rate (Ø, %)',
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_select}>
            <CustomSelectV2
              label="Set return rate*"
              value={renderedCellValue}
              options={returnRateOptions}
              onChange={(e) => changeFieldAction('averageReturnRate', e, row.original)}
            />
          </div>
        ),
        enableSorting: false,
        size: 150,
        minSize: 150,
      },
      {
        accessorKey: 'commission',
        header: 'Commission (%)',
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_select}>
            <CustomSelectV2
              label="Set commision*"
              value={renderedCellValue}
              options={commisionOptions}
              onChange={(e) => changeFieldAction('commission', e, row.original)}
            />
          </div>
        ),
        enableSorting: false,
        size: 140,
        minSize: 140,
      },
      {
        accessorKey: 'ACoSTarget',
        header: 'Standard AСoS (%)',
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_select}>
            <CustomSelectV2
              label="Set Acos*"
              value={renderedCellValue}
              options={acosOptions}
              onChange={(e) => changeFieldAction('ACoSTarget', e, row.original)}
            />
          </div>
        ),
        enableSorting: false,
        size: 160,
        minSize: 160,
      },
      {
        accessorKey: 'totalCost',
        header: 'Platform costs (%)',
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_select}>
            {(parseFloat(row.original.ACoSTarget + row.original.commission) * 100).toFixed(0)}
          </div>
        ),
        enableSorting: false,
        size: 160,
        minSize: 160,
      },
      {
        accessorKey: 'indicatorProductsDownloadedFromPlatform',
        Header: <div className={classes.new_col}>Available products status</div>,
        Cell: ({ renderedCellValue, row }) => (
          <HtmlTooltip title={`Timestamp: ${row.original.lastDownloadedAvailableProducts}`}>
            <div className={`${classes.td_access_circle} ${renderedCellValue ? 'success' : 'failure'}`} />
          </HtmlTooltip>
        ),
        enableSorting: false,
        size: 160,
        minSize: 160,
      },
      {
        accessorKey: 'indicatorCampaignStructure',
        Header: <div className={classes.new_col}>Platform campaign creation status</div>,
        Cell: ({ renderedCellValue, row }) => (
          <HtmlTooltip title={`Timestamp: ${row.original.lastMasterSync}`}>
            <div className={`${classes.td_access_circle} ${renderedCellValue ? 'success' : 'failure'}`} />
          </HtmlTooltip>
        ),
        enableSorting: false,
        size: 160,
        minSize: 160,
      },
      {
        accessorKey: 'indicatorBidsAndActivity',
        Header: <div className={classes.new_col}>Platform campaign activation and bid status</div>,
        Cell: ({ renderedCellValue, row }) => (
          <HtmlTooltip title={`Timestamp: ${row.original.lastTransmittedBidsAndActivation}`}>
            <div className={`${classes.td_access_circle} ${renderedCellValue ? 'success' : 'failure'}`} />
          </HtmlTooltip>
        ),
        enableSorting: false,
        size: 160,
        minSize: 160,
      },
    ],
    [prepearedData, newLabel]
  )

  const table = useMaterialReactTable({
    columns,
    data: prepearedData,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableExpanding: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowVirtualization: true,
    enablePagination: true,
    muiCircularProgressProps: {
      color: 'secondary',
      thickness: 5,
      size: 55,
    },
    muiSkeletonProps: {
      animation: 'pulse',
      height: 28,
    },
    enableColumnResizing: true,
    icons: fontAwesomeIcons,
    enableTopToolbar: false,
    filterFromLeafRows: true,
    initialState: {
      expanded: true,
      pagination: {
        pageSize: 100,
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
      shape: 'rounded',
    },
    paginationDisplayMode: 'pages',
    state: {
      showLoadingOverlay: channelsListLoading,
      columnVisibility: {
        // TODO: after updates requirement need to always display
        // averageReturnRate: !isBolAccount,
        averageReturnRate: true,
      },
    },
  })

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <PageHeadline>
          <Box className={classes.headline}>
            <Box className={classes.headline_link}>
              <Box className={classes.headline_btn}>
                <CustomButton href={ADD_CHANNEL.link} icon={<AddCircleOutlineIcon />}>
                  <span>Add channel</span>
                </CustomButton>
              </Box>
            </Box>
            <Box className={classes.headline_save}>
              <Button
                variant="outlined"
                onClick={handleSave}
                disabled={platformAccountsListLoading || channelsListLoading || postDataLoading || !changesRange.length}
              >
                Save changes
              </Button>
            </Box>
          </Box>
        </PageHeadline>
        <Box className={classes.page_content}>
          <MaterialReactTable table={table} />
        </Box>
      </Box>
    </PageWrapper>
  )
}
export default Settings

import axios from 'axios'

export const userAuth = ({ email, password }) =>
  axios.get(`/users/authenticate?${email ? `email=${email}&` : ''}${password ? `password=${password}&` : ''}`, {})
// {
//   let data = {}
//   try {
//     const res = await axios.get(
//       `/users/authenticate?${email ? `email=${email}&` : ''}${password ? `password=${password}&` : ''}`
//     )
//     data = res?.data
//   } catch (e) {
//     throw (data = {
//       error: e.toString(),
//     })
//   }
//   return data
// }

export const userCreate = ({ companyName, firstName, lastName, email, phone, password }) =>
  axios.get(
    `/users/createAccount?${companyName ? `companyName=${companyName}&` : ''}${
      firstName ? `firstName=${firstName}&` : ''
    }${lastName ? `lastName=${lastName}&` : ''}${email ? `email=${email}&` : ''}${phone ? `phone=${phone}&` : ''}${
      password ? `password=${password}&` : ''
    }`
  )

export const userEmailVerify = ({ email, emailVerificationCode }) =>
  axios.get(
    `/users/createAccount/verifyEmail?${email ? `email=${email}&` : ''}${
      emailVerificationCode ? `emailVerificationCode=${emailVerificationCode}&` : ''
    }`
  )
export const getAccountInfo = ({ accountId }) => axios.get(`/accounts/${accountId}`)
export const getUserInfo = ({ userId, accountId }) => axios.get(`/users/${accountId}/${userId}`)

/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { TextField } from '@mui/material'
import { toast } from 'react-toastify'
import { ReactComponent as AddCircleOutlineIcon } from '../../assets/icons/circle-progress.svg'

import { setPage } from '../../store/slice/page'
import { ADD_PLATFORM } from '../../routes/constants'
import PageWrapper from '../../containers/PageWrapper'
import PageHeadline from '../../components/PageHeadline'
import CustomButton from '../../components/CustomButton'

import useStyles from './styles'
import {
  getPlatformAccountsAction,
  isPlatformAccountsListLoading,
  platformAccountsList,
} from '../../store/slice/platformAccounts'
import { userData } from '../../store/slice/user'
import { fontAwesomeIcons } from '../Cockpit/utils/tableIcons'

import BolLogo from '../../assets/logo/bol.jpg'
import AzLogo from '../../assets/logo/az.svg'
import KlLogo from '../../assets/logo/kl.svg'
import GoogleLogo from '../../assets/logo/google.svg'

import { ReactComponent as EditIcon } from '../../assets/icons/edit.svg'
import { ReactComponent as CancelIcon } from '../../assets/icons/cancel.svg'
import { ReactComponent as ConfirmIcon } from '../../assets/icons/confirm.svg'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'
import { updatePlatformAccounts } from '../../api/platformAccounts'

const logoList = {
  BOL: BolLogo,
  Amazon: AzLogo,
  Kaufland: KlLogo,
  Google: GoogleLogo,
}

const ApiKeysPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(userData)
  const accountsList = useSelector(platformAccountsList)
  const platformAccountsListLoading = useSelector(isPlatformAccountsListLoading)
  const [list, setList] = useState([])

  const [newLabel, setNewLabel] = useState('')

  const [isUpdateLoading, setIsUpdateLoading] = useState(true)

  const onEditLabel = (data) => {
    const arr = list.map((item) => {
      if (item.platformAccountID === data?.platformAccountID) {
        return { ...item, isEdit: true }
      }
      return { ...item, isEdit: false }
    })
    setList(arr)
  }
  const cancelEditLabel = () => {
    const arr = list.map((item) => ({ ...item, isEdit: false }))
    setList(arr)
  }

  const saveEditLabel = (data) => {
    const arr = list.map((item) => {
      if (item.platformAccountID === data?.platformAccountID) {
        return { ...item, label: data?.label, isEdit: false }
      }
      return { ...item, isEdit: false }
    })
    const formData = {
      platformAccountID: data?.platformAccountID,
      label: data.label,
    }
    setIsUpdateLoading(true)
    updatePlatformAccounts(user?.accountID, formData)
      .then((res) => {
        if (res.status === 200 || res.status === 201) {
          toast.success('Label successfully changed', TOAST_DEFAULT_CONFIG)
          setList(arr)
        }
      })
      .catch((err) => {
        const error = err.response.data?.errorMessages?.map((item) => item?.errorMessage).join('\n')
        toast.error(error, TOAST_DEFAULT_CONFIG)
      })
      .finally(() => {
        setIsUpdateLoading(false)
      })
  }

  const columns = useMemo(
    () => [
      {
        accessorKey: 'platformAccountID',
        header: 'Platform account ID',
        enableSorting: false,
        minSize: 200,
        size: 200,
      },
      {
        accessorKey: 'label',
        header: 'API connection label',
        Header: (
          <div className={classes.header_col} style={{ paddingRight: '43px' }}>
            <span>API connection label</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <div
            className={
              row.original.isEdit
                ? `${classes.td} ${classes.td_label} ${classes.td_labelEdit}`
                : `${classes.td} ${classes.td_label}`
            }
          >
            {row.original.isEdit ? (
              <TextField
                className={classes.form_input}
                id="label"
                type="text"
                label="Label"
                placeholder="Label"
                value={newLabel}
                onChange={(val) => {
                  setNewLabel(val?.target.value)
                }}
              />
            ) : (
              <span className={classes.label} title={row.original.label}>
                {row.original.label}
              </span>
            )}
            <div
              className={
                !row.original.isEdit
                  ? `${classes.td} ${classes.td_buttons} ${classes.td_buttonsNoEdit}`
                  : `${classes.td} ${classes.td_buttons}`
              }
            >
              {row.original.isEdit ? (
                <div className={`${classes.wrapperFlexBtn}`}>
                  <div
                    className={`${classes.button}`}
                    onClick={() => {
                      saveEditLabel({ ...row.original, label: newLabel })
                    }}
                  >
                    <ConfirmIcon />
                  </div>
                  <div
                    className={`${classes.button}`}
                    onClick={() => {
                      cancelEditLabel()
                    }}
                  >
                    <CancelIcon />
                  </div>
                </div>
              ) : (
                <div
                  className={`${classes.button}`}
                  onClick={() => {
                    setNewLabel(row.original.label)
                    onEditLabel(row.original)
                  }}
                >
                  <EditIcon />
                </div>
              )}
            </div>
          </div>
        ),
        enableSorting: false,
        size: 450,
        minSize: 450,
      },
      {
        accessorKey: 'platform',
        header: 'Platform',
        enableSorting: false,
        size: 150,
        minSize: 150,
        Cell: ({ renderedCellValue, row }) => (
          <div className={`${classes.td_platform}`}>
            {renderedCellValue.logoURL && (
              <div className={classes.td_platform_icon}>
                <img src={logoList[renderedCellValue.platformCode]} alt="Platform logo" />
              </div>
            )}
            <div className={classes.td_platform_text}>{renderedCellValue.platformCode}</div>
          </div>
        ),
      },
      {
        accessorKey: 'accountType',
        header: 'Account type',
        enableSorting: false,
        size: 150,
        minSize: 150,
      },
      {
        accessorKey: 'connectionOK',
        header: 'API access',
        Cell: ({ renderedCellValue, row }) => (
          <div className={`${classes.td_access_circle} ${renderedCellValue ? 'success' : 'failure'}`} />
        ),
        enableSorting: false,
        size: 125,
        minSize: 125,
      },
    ],
    [list, newLabel]
  )

  const table = useMaterialReactTable({
    columns,
    data: list,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableExpanding: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowVirtualization: true,
    enablePagination: true,
    muiCircularProgressProps: {
      color: 'secondary',
      thickness: 5,
      size: 55,
    },
    muiSkeletonProps: {
      animation: 'pulse',
      height: 28,
    },
    enableColumnResizing: true,
    icons: fontAwesomeIcons,
    enableTopToolbar: false,
    filterFromLeafRows: true,
    initialState: {
      expanded: true,
      pagination: {
        pageSize: 100,
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
      shape: 'rounded',
    },
    paginationDisplayMode: 'pages',
    state: {
      showLoadingOverlay: platformAccountsListLoading,
    },
  })

  useEffect(() => {
    if (user?.accountID) {
      dispatch(getPlatformAccountsAction(user?.accountID))
    }
  }, [user])

  useEffect(() => {
    if (accountsList) {
      const newList = accountsList?.map((item) => ({
        ...item,
        isEdit: false,
        accountType:
          // eslint-disable-next-line no-nested-ternary
          item.platformAccountType === 1 ? 'Advertising API' : item.platformAccountType === 2 ? 'Retailer API' : '-',
      }))
      setList(newList)
    }
  }, [accountsList])

  useEffect(() => {
    dispatch(setPage('settings'))
  }, [])

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <PageHeadline>
          <Box className={classes.headline}>
            <Box className={classes.headline_link}>
              <Box className={classes.headline_btn}>
                <CustomButton href={ADD_PLATFORM.link} className={classes.button} icon={<AddCircleOutlineIcon />}>
                  <span>CONNECT API</span>
                </CustomButton>
              </Box>
            </Box>
          </Box>
        </PageHeadline>
        <Box className={classes.page_content}>
          <Box className={classes.page_content}>
            <MaterialReactTable table={table} />
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  )
}
export default ApiKeysPage

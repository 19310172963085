import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Container } from '@mui/material'

import { setPage } from '../../store/slice/page'

import MainHeadline from '../../components/MainHeadline'
import Copyright from '../../components/Copyright'
import Footer from '../../containers/Footer'
import LoginForm from '../../containers/Form/LoginForm'

import useStyles from './styles'

const Login = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPage('login'))
  })

  return (
    <div className={classes.authPage}>
      <Container className={classes.xl_container}>
        <Box className={classes.container}>
          <Box className={classes.main_grid}>
            <Box className={classes.headline_wrp}>
              <MainHeadline text="Cross-marketplace advertising." />
            </Box>
            <Box className={classes.main_form_wrp}>
              <LoginForm />
            </Box>
          </Box>
          <Box className={classes.main_footer}>
            <Footer />
            <Box className={classes.main_copyright}>
              <Copyright />
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  )
}
export default Login

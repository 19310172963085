export const TOAST_DEFAULT_CONFIG = {
  position: 'top-right',
  autoClose: 12000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: 'light',
}

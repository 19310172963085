// Old icons
// import { ReactComponent as ConfigurationIcon } from '../assets/icons/setting.svg'
// import { ReactComponent as ChartIcon } from '../assets/icons/chart.svg'
// import { ReactComponent as SettingsIcon } from '../assets/icons/global-network.svg'
// import { ReactComponent as LogoutIcon } from '../assets/icons/logout.svg'
// import { ReactComponent as ProductIcon } from '../assets/icons/shopping-bag.svg'
// import { ReactComponent as ApiKeysIcon } from '../assets/icons/keys.svg'

import { ReactComponent as ConfigurationIcon } from '../assets/icons/configuration.svg'
import { ReactComponent as ChartIcon } from '../assets/icons/cockpit.svg'
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg'
import { ReactComponent as LogoutIcon } from '../assets/icons/logout-2.svg'
import { ReactComponent as ProductIcon } from '../assets/icons/add-product.svg'
import { ReactComponent as RulesIcon } from '../assets/icons/rules.svg'
import { ReactComponent as ApiKeysIcon } from '../assets/icons/api-key.svg'

import { logout } from '../utils/logout'

export const ROOT = {
  text: 'Login',
  icon: null,
  link: '/',
}

export const COCKPIT = {
  text: 'Product cockpit',
  icon: <ChartIcon />,
  link: '/cockpit',
}

export const CONFIGUTATION = {
  text: 'Configuration',
  icon: <ConfigurationIcon />,
  link: '/configuration',
}

export const SETTINGS = {
  text: 'Channel settings',
  icon: <SettingsIcon />,
  link: '/settings',
}

export const ADD_PLATFORM = {
  text: 'Add platform',
  icon: <SettingsIcon />,
  link: '/account/api-keys/add-platform',
}

export const PRODUCTS = {
  text: 'Add products',
  icon: <ProductIcon />,
  link: '/products',
}

export const ADD_PRODUCTS = {
  text: 'Add products',
  icon: <ProductIcon />,
  link: '/products/:id/add',
}

export const ADD_CHANNEL = {
  text: 'Add channel',
  icon: <SettingsIcon />,
  link: '/settings/add-channel',
}

export const RULES = {
  text: 'Rules (beta)',
  icon: <RulesIcon />,
  link: '/rules',
}

export const API_KEYS = {
  text: 'API keys',
  icon: <ApiKeysIcon />,
  link: '/account/api-keys',
}

export const SIGN_OUT = {
  text: 'Sign out',
  icon: <LogoutIcon />,
  isLogout: true,
  isSignOut: true,
  link: '/',
  onClick: (dispatch) => logout(dispatch, ROOT.link),
}

export const navListProducts = [COCKPIT, PRODUCTS, CONFIGUTATION]
export const navListChannels = [SETTINGS]
export const navListProcesses = [RULES]
export const navListAccount = [API_KEYS, SIGN_OUT]

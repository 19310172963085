import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  page: '',
}

/* eslint-disable no-param-reassign */
export const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.page = action.payload
    },
  },
})
/* eslint-enable no-param-reassign */

export const getPage = (state) => state.page.page
export const { setPage } = pageSlice.actions

export default pageSlice.reducer

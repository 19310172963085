/* eslint-disable no-unreachable */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, Button, CardActions, FormGroup, ThemeProvider, TextField } from '@mui/material'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import { userData } from '../../../store/slice/user'
import { TOAST_DEFAULT_CONFIG } from '../../../constants/toast'
import { isPlatformAccountCreateLoading } from '../../../store/slice/platformAccounts'
import ThemeNSLLight from '../../../theme/theme-nsl-light'
import { addPlatformAccountsInitiateConnection, getPlatformList } from '../../../api/platformAccounts'
import amazonImg from '../../../assets/upload/amazon2.png'
import bolImg from '../../../assets/upload/bol.png'

import useStyles from './styles'

export const platformLogo = { Amazon: amazonImg, BOL: bolImg }

const SignInFormSchema = () =>
  Yup.object().shape({
    platform: Yup.string().required('Required'),
    customLabel: Yup.string()
      .required('Custom label is a required field')
      .max(50, 'Field is too long - max 50 characters')
      .trim()
      .matches(/^[a-zA-Z0-9\s]+$/, 'Incorrect value - value must be an alphanumeric'),
  })

const initialValues = {
  platform: '',
  customLabel: '',
}

const AddPlatformForm = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const user = useSelector(userData)
  const isPlatformCreateLoading = useSelector(isPlatformAccountCreateLoading)
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const [platformOptions, setPlatformOptions] = useState([])
  useEffect(() => {
    if (user?.accountID) {
      getPlatformList(user?.accountID).then((res) => {
        if (res.status === 200) {
          const arr = res.data
          const newArr = arr.map((item) => ({
            label: item?.platformCode,
            value: item.platformID,
            id: item.platformID,
            logo: platformLogo[item.platformCode],
          }))
          setPlatformOptions(newArr)
        }
      })
    }
  }, [user])

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: SignInFormSchema(),
    onSubmit: async (data) => {
      const { customLabel } = data
      addPlatformAccountsInitiateConnection(user.accountID, data.platform, customLabel)
        .then((res) => {
          if (res.status === 200) {
            window.open(res.data.redirectURL, '_blank')
            navigate('/account/api-keys')
          }
        })
        .catch((err) => {
          let arrMess = []
          if (err.response.data.errors) {
            arrMess = err.response.data.errors.map((item) => item.message)
          } else if (err.response.data.errorMessages) {
            arrMess = err.response.data.errorMessages.map((item) => item.errorMessage)
          } else if (err.response.data.Error) {
            arrMess = [err.response.data.Error]
          }
          toast.error(arrMess.join(' '), TOAST_DEFAULT_CONFIG)
        })
      // dispatch(
      //   createPlatformAccountsAction({
      //     id: user.accountID,
      //     data: {
      //       label: customLabel,
      //       bol_api_clientId: clientId,
      //       bol_api_clientSecret: clientSecret,
      //       platformAccountType,
      //     },
      //   })
      // )
      //   .unwrap()
      //   .then(() => {
      //     toast.success(`Platform account with API connection successfully added`, TOAST_DEFAULT_CONFIG)
      //     formik.resetForm()
      //     navigate('/settings')
      //   })
      //   .catch((err) => {
      //     toast.error(err, TOAST_DEFAULT_CONFIG)
      //     errorParserSingle(err, formik)
      //   })
    },
  })

  useEffect(() => {
    const { platform } = formik.values
    const errorLength = Object.keys(formik.errors).length
    if (platform && errorLength <= 0 && !isPlatformCreateLoading) {
      setIsButtonDisabled(false)
    } else {
      setIsButtonDisabled(true)
    }
  }, [formik.values, formik.errors, isPlatformCreateLoading])

  const handleKeyPress = (event) => {
    const { which, keyCode } = event
    if (keyCode === 13 || which === 13) {
      if (!isButtonDisabled) {
        formik.handleSubmit()
      }
    }
  }

  const handleInputChange = (name, value) => {
    formik.setFieldValue(name, value)
  }

  return (
    <ThemeProvider theme={ThemeNSLLight}>
      <Box className={classes.card}>
        <form className={classes.form} noValidate autoComplete="off" onSubmit={formik.handleSubmit}>
          <Box className={classes.header}>
            <div className={classes.header_title}>Connect API</div>
          </Box>
          <Box className={classes.description}>
            <div className={classes.descriptionText}>Please select your integration:</div>
          </Box>
          <FormGroup className={`${classes.form_group} ${classes.wrapperList}`}>
            {platformOptions?.map((item) => (
              <Box
                onClick={() => {
                  handleInputChange('platform', item?.value)
                }}
                className={
                  item.value === formik.values?.platform ? `${classes.platformActiveBtn}` : classes.platformBtn
                }
                key={item?.id}
              >
                <img src={item?.logo} alt={item.label} />
              </Box>
            ))}
          </FormGroup>
          <FormGroup
            className={`${classes.form_group} ${classes.form_groupLabel} ${
              !!formik.errors?.customLabel && !!formik.touched?.customLabel ? classes.form_input_error : ''
            }`}
          >
            <TextField
              className={`${classes.form_input} ${classes.form_inputLabel}`}
              error={!!formik.errors?.customLabel && !!formik.touched?.customLabel}
              fullWidth
              id="customLabel"
              type="text"
              // label="Label"
              placeholder="API connection label"
              value={formik.values?.customLabel}
              helperText={formik.touched?.customLabel && formik.errors?.customLabel ? formik.errors?.customLabel : ''}
              onChange={(e) => handleInputChange('customLabel', e.target.value)}
              onFocus={() => formik.setFieldTouched('customLabel', false)}
              onBlur={() => formik.setFieldTouched('customLabel', true)}
              onKeyPress={handleKeyPress}
            />
            <CardActions className={classes.form_footer}>
              <Box className={classes.form_footer_box}>
                <Button
                  variant="outline"
                  size="large"
                  color="primary"
                  className={classes.form_btn}
                  disabled={isButtonDisabled}
                  type="submit"
                >
                  Proceed
                </Button>
              </Box>
            </CardActions>
          </FormGroup>

          {formik.status && (
            <Box className={classes.form_alert}>
              <strong>Error Message</strong> {formik.status?.message}
            </Box>
          )}
        </form>
      </Box>
    </ThemeProvider>
  )
}
export default AddPlatformForm

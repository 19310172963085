import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: '100%',
      height: 45,
      padding: '5px 10px',
      display: 'flex',
      alignItems: 'center',
      background: 'transparent',
      border: '1px solid #1A0063',
      borderRadius: 5,
      textDecoration: 'none',
      color: '#1A0063',
      cursor: 'pointer',
      transition: '0.3s',
      '&:hover': {
        backgroundColor: '#1A0063',
        color: '#fff',
        '& svg path': {
          fill: '#fff',
        },
        '& svg circle': {
          stroke: '#fff',
        },
      },
    },
    button_icon: {
      display: 'flex',
      width: 20,
      height: 20,
      '&:not(:last-child)': {
        marginRight: 15,
      },
      '& .MuiCircularProgress-root': {
        width: '100% !important',
        height: '100% !important',
      },
      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
    icon_progress: {
      position: 'relative',
      display: 'flex',
      width: 31,
      height: 31,
      '&:not(:last-child)': {
        marginRight: 15,
      },
      '& .MuiCircularProgress-root': {
        width: '100% !important',
        height: '100% !important',
      },
      '& svg': {
        width: '100%',
        height: '100%',
      },
      '& > div': {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        fontFamily: 'Muli, Arial, sans-serif',
        fontWeight: 700,
        fontSize: 8,
        lineHeight: '10px',
      },
    },
    button_text: {
      fontFamily: 'Muli, Arial, sans-serif',
      fontWeight: 500,
      fontSize: 16,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      marginRight: 'auto',
      marginLeft: 'auto',
      '&$button_text_loading': {
        fontWeight: 700,
      },
    },
    button_text_loading: {},
  })
)
export default useStyles

// #D9D9D9;#D9D9D9;

import ErrorIcon from '@mui/icons-material/Error'
import { Box, Container, Typography } from '@mui/material'

const NotFound = () => (
  <Container>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <ErrorIcon sx={{ fontSize: 70, marginRight: '1rem' }} />
      <Typography variant="h3" component="span" sx={{ fontWeight: 'bold', textTransform: 'uppercase' }}>
        Page Not Found
      </Typography>
    </Box>
  </Container>
)

export default NotFound

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    footer: {
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    logo: {
      [theme.breakpoints.up('sm')]: {
        maxHeight: '10rem',
        maxWidth: '20rem',
      },
      [theme.breakpoints.down('sm')]: {
        maxWidth: '30vw',
      },
    },
  })
)
export default useStyles

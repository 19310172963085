import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Container } from '@mui/material'
import { useNavigate, useSearchParams, createSearchParams, Link } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'
import { toast } from 'react-toastify'
import { setPage } from '../../store/slice/page'

import Copyright from '../../components/Copyright'
import Footer from '../../containers/Footer'

import useStyles from './styles'
import { userEmailVerify } from '../../api/user'

const VerifyEmail = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [loader, setLoader] = useState(false)
  const [error, setError] = useState(false)
  useEffect(() => {
    dispatch(setPage('verifyEmail'))
  })
  useEffect(() => {
    let isMounted = true

    const email = searchParams.get('email')
    const emailVerificationCode = searchParams.get('emailVerificationCode')

    if (email && emailVerificationCode && isMounted) {
      setLoader(true)
      userEmailVerify({ email, emailVerificationCode })
        .then((res) => {
          if (res.status === 200 && isMounted) {
            setError(false)
            toast.success(`Super! We have successfully verified your account. We will be contacting you shortly.`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
            navigate({
              pathname: '/sign-in',
              search: createSearchParams({
                email,
              }).toString(),
            })
          }
        })
        .catch((err) => {
          const errors = err.response.data?.errorMessages?.reduce((acc, curr) => [...acc, curr?.errorMessage], [])
          if (isMounted) {
            setError(true)
            toast.error(`${errors.join(', ')}`, {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            })
          }
        })
        .finally(() => {
          if (isMounted) {
            setLoader(false)
          }
        })
    }

    return () => {
      isMounted = false
    }
  }, [])

  return (
    <div className={classes.verifyEmail}>
      <Container className={classes.xl_container}>
        <Box className={classes.container}>
          <Box className={classes.wrapperText}>
            <p className={classes.text}>Account approved</p>
            {error && (
              <Link className={classes.linkText} to="/sign-up">
                Sign up
              </Link>
            )}
            {!error && (
              <p className={classes.sub_text}>We will review your account and come back to you as soon as possible</p>
            )}
          </Box>
          {loader && (
            <Box className={classes.wrapperSpinner}>
              <CircularProgress />
            </Box>
          )}

          <Box className={classes.main_footer}>
            <Footer />
            <Box className={classes.main_copyright}>
              <Copyright />
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  )
}
export default VerifyEmail

/* eslint-disable no-nested-ternary */
/* eslint-disable  consistent-return */

import { TABLE_VIEW_VARIANT } from './TABLE_TYPE'
import { calculateBoosting } from './calculateBoosting'

export const getAllProducts = (data, viewVariant) => {
  let products = []

  if (viewVariant === TABLE_VIEW_VARIANT.SIMPLE) {
    return products.concat(data)
  }

  if (viewVariant === TABLE_VIEW_VARIANT.ONE_NESTED) {
    data.forEach((item) => {
      products = products.concat(item.subRows)
    })
    return products
  }

  if (viewVariant === TABLE_VIEW_VARIANT.DOUBLE_NESTED) {
    data.forEach((item) => {
      item.subRows.forEach((item1) => {
        products = products.concat(item1.subRows)
      })
    })
  }

  return products
}

export const getAllChangedProducts = (data, viewVariant) => {
  const filtered = getAllProducts(data, viewVariant).filter(
    (item) => Boolean(item.activeEdited) !== Boolean(item.active) || item.boostEdited !== item.boost
  )
  return filtered.length ? filtered : null
}

export const checkIsAllListActive = (list) => {
  // 1 - all active
  // 0 - all inactive
  // -1 - mixed

  const allActive = list.every((item) => item.activeEdited && item.activeEdited !== -1)
  const allNoActive = list.every((item) => !item.activeEdited)

  return allActive ? true : allNoActive ? false : -1
}

const RECALCULATE_BY_FIELD = {
  activeEdited: (data) => ({ activeEdited: checkIsAllListActive(data) }),
  boostEdited: (data) => ({
    boostEdited: !data.length ? 0 : calculateBoosting(data),
    minBoost: !data.length ? 0 : Math.min(...data.map((item) => item.minBoost)),
    maxBoost: !data.length ? 0 : Math.max(...data.map((item) => item.maxBoost)),
  }),
}

const SET_PRODUCT_VALUE_BY_FIELD = {
  activeEdited: (value) => ({ activeEdited: value }),
  boostEdited: (value) => ({
    boostEdited: value,
    minBoost: value,
    maxBoost: value,
  }),
}

// JS enum of editable fields
export const COCKPIT_TABLE_CHANGABLE_FIELDS = {
  activeEdited: 'activeEdited',
  boostEdited: 'boostEdited',
}

// Bulk change field value of nested items after change value on group of products
const bulkChange = (data, newValue, fieldName) => {
  if (!data?.subRows?.length) {
    if (data[fieldName] === newValue) {
      return data
    }

    return {
      ...data,
      [fieldName]: newValue,
      minBoost: fieldName === COCKPIT_TABLE_CHANGABLE_FIELDS.boostEdited ? newValue : data.minBoost,
      maxBoost: fieldName === COCKPIT_TABLE_CHANGABLE_FIELDS.boostEdited ? newValue : data.maxBoost,
    }
  }

  return {
    ...data,
    [fieldName]: newValue,
    minBoost: fieldName === COCKPIT_TABLE_CHANGABLE_FIELDS.boostEdited ? newValue : data.minBoost,
    maxBoost: fieldName === COCKPIT_TABLE_CHANGABLE_FIELDS.boostEdited ? newValue : data.maxBoost,
    subRows: data.subRows.map((item) => bulkChange(item, newValue, fieldName)),
  }
}

// Find product by path and change it status
// path type format of 0-2 depths: "0", "0.0", "0.0.0" etc.
const changeFieldOnProductClick = (data, newValue, productPath, tableView, fieldName) => {
  let temp = []

  if (tableView === TABLE_VIEW_VARIANT.DOUBLE_NESTED) {
    temp = data.map((mainGroupItem, index) => {
      if (index === productPath[0]) {
        return {
          ...mainGroupItem,
          subRows: mainGroupItem.subRows.map((subGroupItem, subIndex) => {
            if (subIndex === productPath[1]) {
              return {
                ...subGroupItem,
                subRows: subGroupItem.subRows.map((product, productIndex) => {
                  if (productIndex === productPath[2]) {
                    return {
                      ...product,
                      ...SET_PRODUCT_VALUE_BY_FIELD[fieldName](newValue),
                    }
                  }
                  return product
                }),
              }
            }
            return subGroupItem
          }),
        }
      }
      return mainGroupItem
    })
  }

  if (tableView === TABLE_VIEW_VARIANT.ONE_NESTED) {
    temp = data.map((mainGroupItem, index) => {
      if (index === productPath[0]) {
        return {
          ...mainGroupItem,
          subRows: mainGroupItem.subRows.map((product, productIndex) => {
            if (productIndex === productPath[1]) {
              return {
                ...product,
                ...SET_PRODUCT_VALUE_BY_FIELD[fieldName](newValue),
              }
            }
            return product
          }),
        }
      }
      return mainGroupItem
    })
  }

  if (tableView === TABLE_VIEW_VARIANT.SIMPLE) {
    temp = data.map((item, index) => {
      if (index === productPath[0]) {
        return {
          ...item,
          ...SET_PRODUCT_VALUE_BY_FIELD[fieldName](newValue),
        }
      }
      return item
    })
  }

  return temp
}

// After change status on some product in group need recalculate all parents group status
// path type format of  0-2 depths: "0", "0.0", "0.0.0" etc.
export const recalculateGroup = (data, path, fieldName) => {
  const parsedPathToTarget = path.split('.').map((item) => Number(item))

  if (parsedPathToTarget && parsedPathToTarget.length === 1) {
    return data
  }

  if (parsedPathToTarget && parsedPathToTarget.length === 2) {
    return data.map((item, index) => {
      if (index === parsedPathToTarget[0]) {
        return {
          ...item,
          ...RECALCULATE_BY_FIELD[fieldName](item.subRows),
        }
      }
      return item
    })
  }

  if (parsedPathToTarget && parsedPathToTarget.length === 3) {
    return data.map((item, index) => {
      if (index === parsedPathToTarget[0]) {
        const subItems = item.subRows.map((subCategory, subCategoryIndex) => {
          if (subCategoryIndex === parsedPathToTarget[1]) {
            return {
              ...subCategory,
              ...RECALCULATE_BY_FIELD[fieldName](subCategory.subRows),
            }
          }
          return subCategory
        })

        return {
          ...item,
          ...RECALCULATE_BY_FIELD[fieldName](subItems),
          subRows: subItems,
        }
      }
      return item
    })
  }

  return data
}

// On switch toggle on product or group
// path type format of 0-2 depths: "0", "0.0", "0.0.1" etc.
export const changeProductField = ({ data = [], path = '', newValue, tableView, fieldName }) => {
  const parsedPathToTarget = path.split('.').map((item) => Number(item))

  let updatedTable

  // on group toggle
  if (
    (tableView === TABLE_VIEW_VARIANT.ONE_NESTED && parsedPathToTarget.length === 1) ||
    (tableView === TABLE_VIEW_VARIANT.DOUBLE_NESTED && parsedPathToTarget.length === 1)
  ) {
    updatedTable = data.map((dataItem, index) => {
      if (index === parsedPathToTarget[0]) {
        return bulkChange(dataItem, newValue, fieldName)
      }
      return dataItem
    })

    // on group toggle
  } else if (tableView === TABLE_VIEW_VARIANT.DOUBLE_NESTED && parsedPathToTarget.length <= 2) {
    updatedTable = data.map((dataItem, index) => {
      if (index === parsedPathToTarget[0]) {
        return {
          ...dataItem,
          [fieldName]: newValue,
          subRows: dataItem.subRows.map((subGroup, subIndex) => {
            if (subIndex === parsedPathToTarget[1]) {
              return bulkChange(subGroup, newValue, fieldName)
            }
            return subGroup
          }),
        }
      }
      return dataItem
    })

    // on product item toggle
  } else {
    updatedTable = changeFieldOnProductClick(data, newValue, parsedPathToTarget, tableView, fieldName)
  }

  const recalculatedGroups = recalculateGroup(updatedTable, path, fieldName)
  const onlyChangedItems = getAllChangedProducts(recalculatedGroups, tableView)

  return { data: recalculatedGroups, onlyChangedItems }
}

import { Box, CircularProgress } from '@mui/material'
import useStyles from './styles'

const Loader = ({ className }) => {
  const classes = useStyles()

  return (
    <div className={`${classes.loader_wrapper} ${className}`}>
      <Box className={classes.loader}>
        <CircularProgress />
      </Box>
    </div>
  )
}

export default Loader

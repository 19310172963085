import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    authPage: {
      backgroundColor: theme.palette.background.main,
    },
    xl_container: {
      maxWidth: theme.breakpoints.values.lg,
    },
    container: {
      minHeight: '100vh',
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      margin: `${theme.spacing(0)} 0 ${theme.spacing(0)} auto`,
      paddingTop: '1rem',
      paddingBottom: '2rem',
      paddingLeft: '70px',
    },
    main_footer: {
      marginTop: 'auto',
      marginBottom: '4vh',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      width: '100%',
    },
    main_grid: {
      width: '100%',
      marginTop: 'auto',
      flexWrap: 'wrap',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'space-between',
    },
    main_copyright: {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        width: '36.5%',
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: '2rem',
        marginBottom: '2rem',
      },
    },
    headline_wrp: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginTop: '56px',
      [theme.breakpoints.up('md')]: {
        width: '61%',
      },
      [theme.breakpoints.between('md', 'sm')]: {
        margin: `5rem 2rem 2rem`,
      },
      [theme.breakpoints.down('sm')]: {
        margin: `5rem auto 2rem`,
      },
    },
    main_form_wrp: {
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
      [theme.breakpoints.up('md')]: {
        width: '36.5%',
      },
    },
  })
)
export default useStyles

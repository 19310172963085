/* eslint-disable consistent-return */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { channelAccountCountryReq, createChannelReq, getChannels } from '../../api/channels'
import { errorParserRequest } from '../../utils/errorParser'
import { TOAST_DEFAULT_CONFIG } from '../../constants/toast'

const initialState = {
  accountCountryData: [],
  channelsList: [],
  isAccountCountryDataLoading: false,
  isCreateChannelLoading: false,
  isChannelsListLoading: false,
  channelsListError: null,
  queryCreatable: [],
  isQueryCreatableLoading: false,
}

const mutationToOptions = (data) =>
  data.map((item) => ({
    value: `${item.platformAccountID}-${item.country?.countryCode}`,
    label: `${item.platformAccountID}.${item.platformAccount?.platform?.platformCode}.${item.platformAccount?.label} ${item.country?.countryCode}`,
  }))

export const getAccountCountryData = createAsyncThunk(
  'channel/getAccountCountryData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await channelAccountCountryReq(data)
      if (response.status === 200 || response.status === 201) {
        if (response.data.length === 0) {
          toast.success(
            `You have already created channels for all currently supported country/platform account combinations. No new channels can currently be created`,
            TOAST_DEFAULT_CONFIG
          )
        }
        return response.data
      }
    } catch (error) {
      return rejectWithValue(errorParserRequest(error))
    }
  }
)
export const getQueryCreatableData = createAsyncThunk(
  'channel/getQueryCreatableData',
  async (data, { rejectWithValue }) => {
    try {
      const response = await channelAccountCountryReq(data)
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
    } catch (error) {
      return rejectWithValue(errorParserRequest(error))
    }
  }
)

export const createChannel = createAsyncThunk('channel/createChannel', async (data, { rejectWithValue }) => {
  try {
    const response = await createChannelReq(data.id, data.data, data.platformID)
    if (response.status === 200 || response.status === 201) {
      return response.data
    }
  } catch (error) {
    return rejectWithValue(errorParserRequest(error))
  }
})

export const getChannelsAction = createAsyncThunk(
  'channels/getChannelsAction',
  async (accountId, { rejectWithValue }) => {
    try {
      const response = await getChannels(accountId)
      if (response.data?.length) {
        return { data: response.data }
      }
      return { data: [] }
    } catch (error) {
      return rejectWithValue(error)
    }
  }
)

/* eslint-disable no-param-reassign */
export const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountCountryData.pending, (state) => {
        state.isAccountCountryDataLoading = true
      })
      .addCase(getAccountCountryData.fulfilled, (state, actions) => {
        state.isAccountCountryDataLoading = false
        state.accountCountryData = mutationToOptions(actions.payload)
      })
      .addCase(getAccountCountryData.rejected, (state) => {
        state.isAccountCountryDataLoading = false
      })

      .addCase(getQueryCreatableData.pending, (state) => {
        state.isQueryCreatableLoading = true
      })
      .addCase(getQueryCreatableData.fulfilled, (state, actions) => {
        state.isQueryCreatableLoading = false
        state.queryCreatable = actions.payload
      })
      .addCase(getQueryCreatableData.rejected, (state) => {
        state.isQueryCreatableLoading = false
        state.queryCreatable = []
      })

      .addCase(createChannel.pending, (state) => {
        state.isCreateChannelLoading = true
      })
      .addCase(createChannel.fulfilled, (state) => {
        state.isCreateChannelLoading = false
      })
      .addCase(createChannel.rejected, (state) => {
        state.isCreateChannelLoading = false
      })
      .addCase(getChannelsAction.pending, (state) => {
        state.isChannelsListLoading = true
      })
      .addCase(getChannelsAction.fulfilled, (state, action) => {
        state.channelsList = action.payload.data
        state.isChannelsListLoading = false
        state.channelsListError = null
      })
      .addCase(getChannelsAction.rejected, (state, action) => {
        state.isChannelsListLoading = false
        state.channelsListError = action.payload.message
        state.channelsList = []
      })
  },
})
/* eslint-enable no-param-reassign */

export const { userLogout } = channelSlice.actions

export const selectAccountCountryData = (state) => state.channel.accountCountryData
export const selectIsAccountCountryDataLoading = (state) => state.channel.isAccountCountryDataLoading
export const selectIsCreateChannelLoading = (state) => state.channel.isCreateChannelLoading
export const channelsList = (state) => state.channel.channelsList
export const isChannelsListLoading = (state) => state.channel.isChannelsListLoading

export default channelSlice.reducer

import { useDispatch, useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { Box, Button } from '@mui/material'
import List from '@mui/material/List'
// import MiniDrawer from '../navigation-drawer'
import Footer from '../Footer'
import { Drawer } from './Drawer'
import { accountInfo } from '../../store/slice/user'
import { navListAccount, navListProducts, navListChannels, navListProcesses } from '../../routes/constants'

// import AppBar from '../app-bar'

import useStyles from './styles'

const Sidebar = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const accountInfoData = useSelector(accountInfo)
  const { name: companyName } = accountInfoData || {}

  return (
    <Box className={classes.sidebar}>
      <Drawer variant="permanent" open="true" className={classes.drawer_paper} PaperProps={{ variant: 'elevation' }}>
        <Box className={classes.drawer_head}>{companyName}</Box>
        <Box className={classes.navlist_wrapper}>
          <Box className={classes.navlist_box}>
            <Box className={classes.navlist_box_title}>Products</Box>
            <List className={classes.navlist_box_list}>
              {navListProducts.map((item) => (
                <Box className={classes.navlist_box_item} key={item?.link}>
                  <NavLink to={item?.link} className={classes.navlist_box_link} key={item?.link}>
                    <Button startIcon={item?.icon} className={classes.navlist_box_btn} variant="contained">
                      {item?.text}
                    </Button>
                  </NavLink>
                </Box>
              ))}
            </List>
          </Box>
          <Box className={classes.navlist_box}>
            <Box className={classes.navlist_box_title}>Channels</Box>
            <List className={classes.navlist_box_list}>
              {navListChannels.map((item) => (
                <Box className={classes.navlist_box_item} key={item?.link}>
                  <NavLink
                    to={item?.link}
                    className={`${classes.navlist_box_link} ${classes.navlist_box_btn_big}`}
                    key={item?.link}
                  >
                    <Button startIcon={item?.icon} className={classes.navlist_box_btn} variant="contained">
                      {item?.text}
                    </Button>
                  </NavLink>
                </Box>
              ))}
            </List>
          </Box>
          <Box className={classes.navlist_box}>
            <Box className={classes.navlist_box_title}>Processes</Box>
            <List className={classes.navlist_box_list}>
              {navListProcesses.map((item) => (
                <Box className={classes.navlist_box_item} key={item?.link}>
                  <NavLink
                    to={item?.link}
                    className={`${classes.navlist_box_link} ${classes.navlist_box_btn_big}`}
                    key={item?.link}
                  >
                    <Button startIcon={item?.icon} className={classes.navlist_box_btn} variant="contained">
                      {item?.text}
                    </Button>
                  </NavLink>
                </Box>
              ))}
            </List>
          </Box>
          <Box className={classes.navlist_box}>
            <Box className={classes.navlist_box_title}>Account</Box>
            <List className={classes.navlist_box_list}>
              {navListAccount.map((item) => (
                <Box
                  className={classes.navlist_box_item}
                  key={item?.link}
                  onClick={() => {
                    if (item?.isSignOut) {
                      if (item.isLogout) {
                        item?.onClick(dispatch)
                      } else {
                        item?.onClick()
                      }
                    }
                  }}
                >
                  <NavLink to={item?.link} className={classes.navlist_box_link} key={item?.link}>
                    <Button startIcon={item?.icon} className={classes.navlist_box_btn} variant="contained">
                      {item?.text}
                    </Button>
                  </NavLink>
                </Box>
              ))}
            </List>
          </Box>
        </Box>
        <Box className={classes.drawer_footer}>
          <Footer />
        </Box>
      </Drawer>
    </Box>
  )
}
export default Sidebar

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    page_wrapper: {},
    page_content: {
      padding: '0px',

      height: 'calc(100vh - 80px)',

      '& .MuiPaper-root': {
        background: '#fff',
        height: '100%',
        transition: '0.3s',
      },
      '& .Mui-TableHeadCell-Content-Labels': {
        alignItems: 'flex-start',

        '& .MuiBadge-root': {
          position: 'absolute',
          right: 5,
        },

        '& .Mui-TableHeadCell-Content-Wrapper:not(:last-child)': {
          '& >div>span:first-child': {
            display: 'block',
            paddingRight: 20,
          },
          '& >div>span:nth-child(2)': {
            display: 'block',
            paddingRight: 0,
          },
        },
      },
      '& .MuiBadge-root': {
        transform: 'translateY(3px)',
      },
      '& .MuiBox-root': {
        background: '#fff',
      },
      '& .MuiTableContainer-root': {
        height: 'calc(100% - 56px)',
        maxHeight: '100%',
        transition: '0.3s',
      },
      '& .MuiTableRow-root': {
        backgroundColor: 'transparent !important',
        '&:hover': {
          '& td': {
            backgroundColor: 'rgb(242, 242, 242) !important',
          },
        },
      },

      '& th': {
        '&:not(:nth-child(2))': {
          '& .Mui-TableHeadCell-Content': {},
        },

        '&:first-child': {
          paddingLeft: 35,
        },

        padding: 0,
        fontWeight: 800,
        fontSize: 14,
        textAlign: 'left',
        justifyContent: 'flex-start',

        '& hr': {
          borderColor: 'transparent',
        },
        '& .Mui-TableHeadCell-Content': {
          width: '100%',
          height: '100%',
          padding: '15px 12px 10px',
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        },

        '& th:first-child': {
          '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
            display: 'none',
          },
        },

        '& .Mui-TableHeadCell-ResizeHandle-Wrapper': {
          width: 1,
          background: 'transparent',
          height: '100%',
          top: 0,
          bottom: 0,
          right: '15px',
        },
      },
      '& th:not(:last-child)': {
        borderRight: '1px solid rgba(196, 196, 196, 0.25)',
      },
      '& tr': {
        '&:hover': {
          '& td': {
            // backgroundColor: 'rgb(242, 242, 242) !important',
          },
        },
        '& td': {
          '&:first-child': {
            paddingLeft: 45,
          },
          // borderBottom: '1px solid rgba(196, 196, 196, 0.25)',
          // fontWeight: 300,
          // fontSize: 14,
          // textTransform: 'capitalize',
          // padding: 12,

          justifyContent: 'flex-start',
          textAlign: 'left',
        },
        '& td:not(:last-child)': {
          borderRight: '1px solid rgba(196, 196, 196, 0.25)',
        },
      },
    },
    td_platform_icon: {
      width: 20,
      height: 20,
      marginRight: 5,
      '& > *': {
        width: '100%',
        height: '100%',
      },
    },
    td_access_circle: {
      width: 15,
      height: 15,
      borderRadius: '50%',
      '&.success': {
        background: '#00FF47',
      },
      '&.failure': {
        background: 'orange',
      },
    },
    new_col: {
      display: 'inline-block',

      lineHeight: '1rem',
      minWidth: '4ch',
      overflow: 'unset',
      textOverflow: 'unset',
      whiteSpace: 'normal',
    },
    td_platform: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
    page_account: {
      '&:not(:last-child)': {
        marginBottom: 85,
      },
    },
    headline: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    headline_link: {
      display: 'flex',
      alignItems: 'center',
    },
    headline_btn: {
      minWidth: 205,
      '&:not(:last-child)': {
        marginRight: 28,
      },
    },
    td_product: {
      width: '100%',
      display: 'flex',
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
      gap: '20px',
      alignItems: 'center',
    },
    wrapperBtn: {
      width: 102,
      textAlign: 'center',
      '&:not(:first-of-type)': {
        marginLeft: '0 !important',
      },
      '& > a': {
        display: 'flex',
        textDecoration: 'none',
      },
      '& .MuiButtonBase-root': {
        minHeight: 25,
        height: 40,
        border: '1px solid #1A0063',
        color: '#1A0063',
        transition: '0.3s',
        fontSize: 11,
        textTransform: 'unset',
        padding: 8,
        '&.Mui-disabled': {
          borderColor: '#C4C4C4',
          color: '#C4C4C4',
        },
        '&:hover': {
          backgroundColor: '#1A0063',
          color: '#fff',
        },
      },
    },
    td_select: {
      width: 75,
      margin: '0 auto',
    },
    td_label: {
      display: 'flex!important',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 20,
      minHeight: 30,
      '&:hover': {
        '& $td_buttonsNoEdit': {
          visibility: 'visible',
          justifyContent: 'flex-start',
        },
      },
    },
    form_input: {
      width: '100%',
      backgroundColor: '#F3F3F3',
      border: '0!important',
      '& .MuiFormLabel-root': {
        fontSize: 16,
        lineHeight: 1.5,
        textTransform: 'uppercase',
        color: '#000',
        top: '50%',
        transform: 'translate(20px, -50%) scale(1)',
        padding: 0,
        '&.MuiFormLabel-filled, &.MuiInputLabel-shrink': {
          opacity: 0,
          pointerEvents: 'none',
        },
        '&.Mui-error': {
          color: '#000',
        },
      },
      '& .MuiInputBase-input': {
        fontSize: 16,
        lineHeight: 1.5,
        color: '#000',
        padding: '0 1rem',
        height: 30,
        '&::placeholder': {
          fontWeight: 300,
          textTransform: 'uppercase',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent!important',
        top: 0,
        '& legend': {
          opacity: 0,
          pointerEvents: 'none',
          background: 'transparent',
          display: 'none',
        },
      },
    },
    label: {
      maxHeight: 20,
      display: '-webkit-box',
      '-webkit-line-clamp': 1,
      '-webkit-box-orient': 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingLeft: '1rem',
    },
    td_buttonsNoEdit: {
      visibility: 'hidden',
    },
    wrapperFlexBtn: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      gap: 10,
    },
    td_channel: {
      width: '100%',
      display: 'flex!important',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: 20,
      '&:hover': {
        '& $td_buttonsNoEdit': {
          visibility: 'visible',
        },
      },
    },
  })
)
export default useStyles

import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'

import Slider from '@mui/material/Slider'
import InputAdornment from '@mui/material/InputAdornment'
import { TextField } from '@mui/material'
import useStyles from './styles'
import CustomThumbComponent from '../CustomSliderThumb'

const CustomSlider = ({
  minDistance = 0,
  label,
  minRange = 0,
  step = 1,
  maxRange = 100,
  minValue = minRange,
  maxValue = maxRange / 2,
  onChange,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState([0, 0])

  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return
    }

    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(newValue[0], 100 - minDistance)
        setValue([clamped, clamped + minDistance])
        onChange([clamped, clamped + minDistance])
      } else {
        const clamped = Math.max(newValue[1], minDistance)
        setValue([clamped - minDistance, clamped])
        onChange([clamped - minDistance, clamped])
      }
    } else {
      setValue([Math.floor(newValue[0]), Math.floor(newValue[1])])
      onChange([Math.floor(newValue[0]), Math.floor(newValue[1])])
    }
  }

  const calculateInputValue = (e) => {
    const parsed = Number(parseFloat(e.target.value, 10).toFixed(1))
    const res = Number.isNaN(parsed) ? 0 : parsed

    if (res < minRange) {
      return minRange
    }
    if (res > maxRange) {
      return maxRange
    }
    return res
  }

  useEffect(() => {
    setValue([minValue, maxValue])
  }, [minValue, maxValue])

  return (
    <Box className={classes.slider}>
      <Box className={classes.slider_label}>{label}</Box>
      <Box className={classes.slider_elem}>
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
          className={classes.range_input}
          value={value[0]}
          onChange={(e) => setValue([e.target.value, value[1]])}
          onBlur={(e) => {
            const newVal = calculateInputValue(e)

            if (value[1] < newVal) {
              setValue([newVal, newVal])
              onChange([newVal, newVal])
            } else {
              setValue([newVal, value[1]])
              onChange([newVal, value[1]])
            }
            e.target.value = newVal
          }}
        />
        <Slider
          getAriaLabel={() => 'Minimum distance shift'}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="off"
          min={minRange}
          step={step}
          max={maxRange}
          disableSwap
          slots={{ thumb: CustomThumbComponent }}
        />
        <TextField
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          value={value[1]}
          type="number"
          onChange={(e) => setValue([value[0], e.target.value])}
          onBlur={(e) => {
            const newVal = calculateInputValue(e)

            if (value[0] > newVal) {
              setValue([newVal, newVal])
              onChange([newVal, newVal])
            } else {
              setValue([value[0], newVal])
              onChange([value[0], newVal])
            }
            e.target.value = newVal
          }}
          className={classes.range_input}
        />
      </Box>
    </Box>
  )
}

export default CustomSlider

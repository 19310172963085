const SortIcon = (props) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 8 15"
    fill="none"
    style={{
      transform: 'rotate(0deg)',
    }}
  >
    <path
      d="M4.43301 14.25C4.24056 14.5833 3.75944 14.5833 3.56699 14.25L0.96891 9.75C0.77646 9.41667 1.01702 9 1.40192 9L6.59808 9C6.98298 9 7.22354 9.41667 7.03109 9.75L4.43301 14.25Z"
      fill="black"
      fillOpacity="0.5"
    />
    <path
      d="M3.56699 0.75C3.75944 0.416667 4.24056 0.416667 4.43301 0.750001L7.03109 5.25C7.22354 5.58333 6.98298 6 6.59808 6L1.40192 6C1.01702 6 0.77646 5.58333 0.96891 5.25L3.56699 0.75Z"
      fill="black"
      fillOpacity="0.5"
    />
  </svg>
)

const SortIconArrow = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 8 15" fill="none">
    <path
      d="M4.43301 14.25C4.24056 14.5833 3.75944 14.5833 3.56699 14.25L0.96891 9.75C0.77646 9.41667 1.01702 9 1.40192 9L6.59808 9C6.98298 9 7.22354 9.41667 7.03109 9.75L4.43301 14.25Z"
      fill="black"
      fillOpacity="0.5"
    />
  </svg>
)

export const fontAwesomeIcons = {
  ArrowDownwardIcon: (props) => <SortIconArrow {...props} />,
  SyncAltIcon: (props) => <SortIcon {...props} />,
}

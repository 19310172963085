import { Box, ThemeProvider } from '@mui/material'

import Sidebar from '../Sidebar'
import useStyles from './styles'
import ThemeNSLLight from '../../theme/theme-nsl-light'

const PageWrapper = ({ children }) => {
  const classes = useStyles()

  return (
    <main className={classes.page_wrapper}>
      <Box className={classes.page_wrapper_row}>
        <Sidebar />
        <ThemeProvider theme={ThemeNSLLight}>
          <Box className={classes.page_wrapper_content} sx={{ flexGrow: 1, pt: 4, pb: 4, pl: 3, pr: 3 }}>
            {children}
          </Box>
        </ThemeProvider>
      </Box>
    </main>
  )
}
export default PageWrapper

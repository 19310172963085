import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    search_wrapper: {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
    },
    search_text: {
      width: '100%',
      '& .MuiInputBase-input': {
        background: '#F2F2F2',
        fontWeight: 400,
        fontSize: 12,
        lineHeight: 1.5,
        color: '#000',
        padding: '0 45px 0 10px',
        height: 40,

        '&::placeholder': {
          lineHeight: 1.5,
          fontSize: 12,
          fontWeight: 400,
          color: '#000',
          opacity: 1,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(26, 18, 63, 0.5)',
        top: 0,
        '& legend': {
          opacity: 0,
          pointerEvents: 'none',
          background: 'transparent',
          display: 'none',
        },
      },
      '& fieldset': {
        border: 0,
      },
    },
    search_button_wrapper: {
      position: 'absolute',
      right: 10,
      top: 'calc(50% - 12.5px)',
      width: '25px',
      height: '25px',

      '& .MuiButtonBase-root ': {
        width: '100%',
        height: '100%',
        minWidth: '100% !important',
        maxWidth: '100%',
        minHeight: '100% !important',
        fontSize: 10,
        fontWeight: 700,
        lineHeight: '100%',
        border: '1px solid rgba(26, 18, 63, 0.50);',
        color: '#000',
      },
      '& .MuiSvgIcon-root': {
        width: 20,
        height: 20,
      },
      '& .MuiButtonBase-root:hover': {
        '& .MuiSvgIcon-root': {
          color: '#fff',
        },
      },
    },
    apply_btn: {
      margin: '15px 15px 15px auto !important',
      fontSize: '12px !important',
      lineHeight: '17px !important',
      minHeight: '30px !important',
      fontWeight: 700,
      padding: '7px !important',
      border: '1px solid #1A0063 !important',
      color: '#1A0063 !important',
      transition: '0.3s',
      '&.Mui-disabled': {
        borderColor: '#C4C4C4',
        color: '#C4C4C4',
      },
      '&:hover': {
        backgroundColor: '#1A0063 !important',
        color: '#fff !important',
      },
    },
  })
)

export default useStyles

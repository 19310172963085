import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    // sidebar: {
    //   '& .MuiDrawer-paper': {
    //     backgroundColor: theme.palette.background.main,
    //   },
    // },
    sidebar: {
      width: '30%',
      maxWidth: '230px',
    },
    navi_categogy_name: {
      fontWeight: 'bold',
      fontSize: 12,
      lineHeight: 1.5,
      textTransform: 'uppercase',
      color: '#828282',
      margin: `${theme.spacing(5)} 0 ${theme.spacing(0.5)} ${theme.spacing(1.2)}`,
    },
    company_name: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
      lineHeight: 1.5,
      padding: `0 0 0 0`,
    },
    drawer_head: {
      fontWeight: 'bold',
      fontSize: '1.1rem',
      lineHeight: 1.5,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: theme.spacing(3.125, 4.25),
      color: theme.palette.text.white,
      '&:not(last-child)': {
        borderBottom: '1px solid #684ED3',
      },
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    drawer_footer: {
      marginTop: 'auto',
      padding: `${theme.spacing(4)}`,
    },
    drawer_paper: {
      '&.MuiDrawer-root': {
        // width:'15rem',
      },
      '&.MuiDrawer-root .MuiDrawer-paper': {
        // width:'15rem',
        borderColor: 'rgba(255, 255, 255, 0.25)',
      },
    },
    navlist_wrapper: {},
    navlist_box: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: theme.spacing(2.5, 4.375, 5.5),
      '&:not(:last-child)': {
        borderBottom: '1px solid #684ED3',
      },
    },
    navlist_box_title: {
      fontWeight: 700,
      fontSize: '0.75rem',
      lineHeight: 1.5,
      textTransform: 'uppercase',
      color: '#ccc',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
    navlist_box_list: {
      '&.MuiList-root': {
        padding: 0,
      },
    },
    navlist_box_item: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1.75),
      },
    },
    navlist_box_link: {
      textDecoration: 'inherit',
      '& svg, & svg path': {
        fill: '#fff',
        width: '18px',
        height: '18px',
      },
      '& .MuiButton-root': {},
      '&.active, &:hover': {
        '& .MuiButton-root': {
          backgroundColor: 'transparent',
          '& .MuiButton-startIcon': {
            backgroundColor: '#fff',
            borderColor: 'transparent',
          },
        },
        '& svg, & svg path': {
          fill: `${theme.palette.primary.main}`,
        },
      },
    },
    navlist_box_btn: {
      '&.MuiButton-root': {
        color: '#ffffff',
        textTransform: 'initial',
        fontWeight: 600,
        fontSize: 14,
        lineHeight: 1.5,
        padding: 0,
        width: '100%',
        justifyContent: 'flex-start',
        '&.MuiButton-contained': {
          backgroundColor: 'transparent',
        },
        '&:hover, &:active': {
          boxShadow: 'none',
        },
        '& .MuiButton-startIcon': {
          backgroundColor: 'transparent',
          boxShadow: '0px 3.5px 5.5px rgba(0, 0, 0, 0.02)',
          borderRadius: 12,
          width: theme.spacing(3.75),
          height: theme.spacing(3.75),
          minWidth: theme.spacing(3.75),
          minHeight: theme.spacing(3.75),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: theme.spacing(1.25),
          marginLeft: 0,
          border: '1px solid #fff',
        },
      },
    },
    navlist_box_btn_big: {
      '& svg': {
        width: '20px !important',
        height: '20px !important',
      },
    },
  })
)
export default useStyles

/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box'

import { MaterialReactTable, useMaterialReactTable } from 'material-react-table'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { setPage } from '../../store/slice/page'

import PageWrapper from '../../containers/PageWrapper'

import BolLogo from '../../assets/logo/bol.jpg'
import AzLogo from '../../assets/logo/az.svg'
import KlLogo from '../../assets/logo/kl.svg'
import GoogleLogo from '../../assets/logo/google.svg'

import { userData } from '../../store/slice/user'
import { channelsList, getChannelsAction, isChannelsListLoading } from '../../store/slice/channel'
import { fontAwesomeIcons } from '../Cockpit/utils/tableIcons'
import PageHeadline from '../../components/PageHeadline'

import useStyles from './styles'

const logoList = {
  BOL: BolLogo,
  Amazon: AzLogo,
  Kaufland: KlLogo,
  Google: GoogleLogo,
}

const ProductsPage = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(userData)
  const channelsDataList = useSelector(channelsList)
  const channelsListLoading = useSelector(isChannelsListLoading)

  const navigate = useNavigate()

  const goToAddProduct = (id) => {
    navigate(`/products/${id}/add`)
  }

  const prepearedData = useMemo(
    () =>
      channelsDataList.map((item) => {
        const { platformAccount } = item.platformAccountAndCountry || {}
        const { channelName, managedProducts, id } = item
        const { countryName } = item.country

        return {
          id,
          channelName,
          platformCode: platformAccount?.platform.platformCode || '',
          label: platformAccount.label,
          platformAccount_2: '-',
          countryName,
          lang: '-',
          managedProducts,
        }
      }),
    [channelsDataList]
  )

  const columns = useMemo(
    () => [
      {
        accessorKey: 'channelName',
        header: 'Channel',
        enableSorting: false,
        minSize: 400,
        size: 400,
        Cell: ({ renderedCellValue, row }) => (
          <div>
            <span title={renderedCellValue}>{renderedCellValue}</span>
          </div>
        ),
      },
      {
        accessorKey: 'platformCode',
        header: 'Platform',
        enableSorting: false,
        size: 121,
        minSize: 121,
        Cell: ({ renderedCellValue, row }) => (
          <div className={`${classes.td_platform}`}>
            {logoList[renderedCellValue] && (
              <div className={classes.td_platform_icon}>
                <img src={logoList[renderedCellValue]} alt="Platform logo" />
              </div>
            )}
            <div className={classes.td_platform_text}>{renderedCellValue}</div>
          </div>
        ),
      },
      {
        accessorKey: 'countryName',
        header: 'Country',
        enableSorting: false,
        size: 100,
        minSize: 100,
      },
      {
        accessorKey: 'managedProducts',
        header: 'Products managed',
        Header: (
          <div className={classes.header_col}>
            <span>Products managed</span>
          </div>
        ),
        Cell: ({ renderedCellValue, row }) => (
          <div className={classes.td_product}>
            {renderedCellValue}
            <div className={classes.td_add_products_upload}>
              <div className={classes.wrapperBtn}>
                <Button
                  onClick={() => goToAddProduct(row.original.id)}
                  variant="outline"
                  size="small"
                  color="primary"
                  type="reset"
                  className={classes.form_btn}
                >
                  Add products
                </Button>
              </div>
            </div>
          </div>
        ),
        size: 170,
        minSize: 170,
      },
    ],
    [prepearedData]
  )

  const table = useMaterialReactTable({
    columns,
    data: prepearedData,
    enableColumnActions: false,
    enableColumnFilters: false,
    enableExpanding: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableRowVirtualization: true,
    enablePagination: true,
    muiCircularProgressProps: {
      color: 'secondary',
      thickness: 5,
      size: 55,
    },
    muiSkeletonProps: {
      animation: 'pulse',
      height: 28,
    },
    enableColumnResizing: true,
    icons: fontAwesomeIcons,
    enableTopToolbar: false,
    filterFromLeafRows: true,
    initialState: {
      expanded: true,
      pagination: {
        pageSize: 10000000,
      },
    },
    muiPaginationProps: {
      showRowsPerPage: false,
      shape: 'rounded',
    },
    paginationDisplayMode: 'pages',
    state: {
      showLoadingOverlay: channelsListLoading,
    },
  })

  useEffect(() => {
    dispatch(setPage('settings'))
  })

  useEffect(() => {
    if (user?.accountID) {
      dispatch(getChannelsAction(user?.accountID))
    }
  }, [user])

  return (
    <PageWrapper>
      <Box className={classes.page_wrapper}>
        <PageHeadline>
          <Box className={classes.headline} />
        </PageHeadline>
        <Box className={classes.page_content}>
          <Box className={classes.page_channel}>
            <MaterialReactTable table={table} />
          </Box>
        </Box>
      </Box>
    </PageWrapper>
  )
}
export default ProductsPage

export const getAccosColor = (acos, min, max) => {
  let acosColor = 'transparent'

  if (acos === 0 || acos === '0.0') {
    return 'grey'
  }

  if (acos < min) {
    acosColor = 'blue'

    return acosColor
  }

  if (acos > max) {
    acosColor = 'red'

    return acosColor
  }

  return acosColor
}

export const getTaccosColor = (tacos, min, max) => {
  let tacosColor = 'transparent'

  if (tacos === 0 || tacos === '0.0') {
    return 'grey'
  }

  if (tacos < min) {
    tacosColor = 'blue'

    return tacosColor
  }

  if (tacos > max) {
    tacosColor = 'red'

    return tacosColor
  }

  return tacosColor
}

export const getNetProfitColor = (value, min, max) => {
  let color = 'transparent'

  if (value >= min && value <= max) {
    return 'grey'
  }

  if (value < min) {
    color = 'red'

    return color
  }

  if (value > max) {
    color = 'blue'

    return color
  }

  return color
}

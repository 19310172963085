const themeBreakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1650,
    lg1: 1440,
    xl: 1960,
  },
}
export default themeBreakpoints

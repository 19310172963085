import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box, CircularProgress, Container } from '@mui/material'
// import { Link, useParams } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setPage } from '../../store/slice/page'

// import Copyright from '../../components/Copyright'
// import Footer from '../../containers/Footer'

import useStyles from './styles'
import { receiveExternalResponse } from '../../api/channels'

const PlatformConnect = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [error, setError] = useState(false)
  const [loader, setLoader] = useState(false)

  const params = useParams()

  const sendRequest = async (isMounted) => {
    setLoader(true)

    const { search } = window.location

    receiveExternalResponse({ requestedURLParameters: `${search.replace('?', '')}` })
      .then((res) => {
        if (res.status === 200 && isMounted) {
          setError(false)
        }
      })
      .catch((err) => {
        const errors = err.response.data?.errorMessages?.reduce((acc, curr) => [...acc, curr?.errorMessage], [])
        if (isMounted) {
          setError(true)
          toast.error(`${errors.join(', ')}`, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'light',
          })
        }
      })
      .finally(() => {
        if (isMounted) {
          setLoader(false)
        }
      })
  }

  useEffect(() => {
    let isMounted = true

    if (params.id) {
      sendRequest(isMounted)
    }

    return () => {
      isMounted = false
    }
  }, [params])

  useEffect(() => {
    dispatch(setPage('auth'))
  })

  return (
    <div className={classes.verifyEmail}>
      <Container className={classes.xl_container}>
        <Box className={classes.container}>
          <Box className={classes.wrapperText}>
            {error && !loader && (
              <>
                <p className={classes.text}>Error</p>
                <p className={classes.sub_text}>Something went wrong, please try again</p>
                {/* <Link className={classes.linkText} to="/account/api-keys">
                  Back to Api keys page
                </Link> */}
              </>
            )}
            {!error && !loader && (
              <>
                <p className={classes.text}>Success</p>
                <p className={classes.sub_text}>Please close this window and go back to DeepCliq</p>
                {/* <Link className={classes.linkText} to="/account/api-keys">
                  Back to Api keys page
                </Link> */}
              </>
            )}
          </Box>
          {loader && (
            <Box className={classes.wrapperSpinner}>
              <CircularProgress />
            </Box>
          )}

          {/* <Box className={classes.main_footer}>
            <Footer />
            <Box className={classes.main_copyright}>
              <Copyright />
            </Box>
          </Box> */}
        </Box>
      </Container>
    </div>
  )
}
export default PlatformConnect

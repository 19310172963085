/* eslint-disable consistent-return */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable import/no-extraneous-dependencies */
import { useEffect, useMemo, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import { DateRangePicker } from 'react-date-range'
import { Button } from '@mui/material'
import { addDays, addYears, endOfDay, isSameDay } from 'date-fns'
import { useOnClickOutside } from '../../../utils/hooks/useClickOutside'

import 'react-datepicker/dist/react-datepicker.css'
import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import useStyles from './styles'
import { dateRanges, getRangeLabel } from '../../../utils/date'

const CustomDataPicker = ({ onChange, initStartRange, initEndRange }) => {
  const dateFormat = 'yyyy-MM-dd'
  const classes = useStyles()

  const [rangePickerValue, setRangePickerValue] = useState([])
  const [rangeIsOpen, setRangeIsOpen] = useState(false)
  const contentRef = useRef(null)

  const rangeLabel = useMemo(() => {
    if (rangePickerValue.length) {
      return getRangeLabel(rangePickerValue[0].startDate, rangePickerValue[0].endDate)
    }
  }, [rangePickerValue])

  const handleRangePicker = (value) => {
    setRangePickerValue([value.selection])
  }

  useOnClickOutside(contentRef, () => {
    setRangeIsOpen(false)
  })

  useEffect(() => {
    const startDate = new Date(initStartRange || dateRanges.pastMonth.startDate)
    const endDate = new Date(initEndRange || dateRanges.pastMonth.endDate)

    setRangePickerValue([
      {
        startDate,
        endDate,
        key: 'selection',
      },
    ])
  }, [initStartRange, initEndRange])

  return (
    <Box className={classes.date_range_wraper}>
      <div
        className={classes.date_range_text}
        role="button"
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.code === 'Enter') {
            setRangeIsOpen(true)
          }
        }}
        onClick={() => {
          setRangeIsOpen(true)
        }}
      >
        <span>Period:</span>
        <br />
        {rangeLabel}
      </div>
      {rangeIsOpen && (
        <Box className={classes.date_range} ref={contentRef}>
          <DateRangePicker
            onChange={handleRangePicker}
            showSelectionPreview
            editableDateInputs={false}
            moveRangeOnFirstSelection={false}
            months={2}
            minDate={endOfDay(addYears(new Date(), -3))}
            maxDate={endOfDay(addDays(new Date(), 0))}
            dateDisplayFormat={dateFormat}
            ranges={rangePickerValue}
            direction="horizontal"
            staticRanges={[
              // {
              //   label: 'Today',
              //   range: () => dateRanges.today,
              //   isSelected(range) {
              //     const definedRange = this.range()
              //     return (
              //       isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
              //     )
              //   },
              // },
              {
                label: 'Yesterday',
                range: () => dateRanges.yesterday,
                isSelected(range) {
                  const definedRange = this.range()
                  return (
                    isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                  )
                },
              },
              {
                label: 'Last 7 days',
                range: () => dateRanges.lastSevenDays,
                isSelected(range) {
                  const definedRange = this.range()
                  return (
                    isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                  )
                },
              },
              {
                label: 'Past month',
                range: () => dateRanges.pastMonth,
                isSelected(range) {
                  const definedRange = this.range()
                  return (
                    isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                  )
                },
              },
              {
                label: 'Past 3 months',
                range: () => dateRanges.past3Month,
                isSelected(range) {
                  const definedRange = this.range()
                  return (
                    isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                  )
                },
              },
              {
                label: 'Past 6 months',
                range: () => dateRanges.past6Month,
                isSelected(range) {
                  const definedRange = this.range()
                  return (
                    isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                  )
                },
              },
              {
                label: 'Past year',
                range: () => dateRanges.pastYear,
                isSelected(range) {
                  const definedRange = this.range()
                  return (
                    isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                  )
                },
              },
              {
                label: 'Past 2 years',
                range: () => dateRanges.past2Year,
                isSelected(range) {
                  const definedRange = this.range()
                  return (
                    isSameDay(range.startDate, definedRange.startDate) && isSameDay(range.endDate, definedRange.endDate)
                  )
                },
              },
            ]}
            inputRanges={[]}
          />
          <Button
            variant="outline"
            size="large"
            color="primary"
            className={classes.apply_btn}
            onClick={() => {
              onChange([rangePickerValue[0].startDate, rangePickerValue[0].endDate])
              setRangeIsOpen(false)
            }}
          >
            Apply
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CustomDataPicker

import Box from '@mui/material/Box'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import { InputAdornment } from '@mui/material'
import { ReactComponent as KeyboardArrowDownIcon } from '../../../assets/icons/chevron-down-2.svg'

import useStyles from './styles'

const CustomSelect = ({ label, value, options = [], onChange = () => {}, endAdornment, disabled = false }) => {
  const classes = useStyles()

  const handleChange = (event) => {
    if (!disabled) {
      onChange(event.target.value)
    }
  }

  return (
    <Box className={`${classes.select} ${!label ? classes.without_label : ''}`}>
      <FormControl className={classes.select} fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          IconComponent={KeyboardArrowDownIcon}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
          endAdornment={endAdornment ? <InputAdornment position="end">%</InputAdornment> : null}
          inputProps={{
            MenuProps: {
              PaperProps: {
                sx: {
                  backgroundColor: '#f2f2f2',
                  borderColor: 'transparent',
                },
              },
              MenuListProps: {
                sx: {
                  '& .MuiButtonBase-root.Mui-selected': {
                    backgroundColor: '#fff',
                  },
                },
              },
            },
          }}
          disabled={disabled}
        >
          {options.map((option) => (
            <MenuItem value={option?.value} key={option?.value}>
              {option?.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  )
}

export default CustomSelect

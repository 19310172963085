import { Box, CircularProgress } from '@mui/material'
import TableRow from './TableRow'
import useStyles from './style'

const ProductsTable = ({ list, accountId, loading, onChangeSelect, search, loadingText }) => {
  const classes = useStyles()
  return (
    <div className={classes.table}>
      <div className={classes.table_wrapper}>
        <Box className={classes.thead}>
          <Box className={classes.tr}>
            <Box className={`${classes.th} ${classes.th_select}`}>Select</Box>
            <Box className={`${classes.th} ${classes.th_platform}`}>EAN</Box>
            <Box className={`${classes.th} ${classes.th_name}`}>Name</Box>
            <Box className={`${classes.th} ${classes.th_brand}`}>Brand</Box>
          </Box>
        </Box>
        {loading ? (
          <div className={classes.mainLoaderWrapper}>
            <div className={classes.loaderWrapper}>
              <CircularProgress />
              <p className={classes.loaderText}>{loadingText}</p>
            </div>
          </div>
        ) : (
          <>
            {!list?.length && (
              <div className={classes.mainLoaderWrapper}>
                <div className={classes.loaderWrapper}>
                  <p className={classes.attention}>!</p>
                  <p className={classes.loaderText}>Sorry, nothing found. Try again.</p>
                </div>
              </div>
            )}
            <Box className={classes.tbody}>
              <Box className={classes.wrapperBody}>
                {list?.map((item, ind) => (
                  <TableRow
                    search={search}
                    onChangeSelect={onChangeSelect}
                    data={item}
                    key={item?.platformAccountID || ind}
                    accountId={accountId}
                  />
                ))}
              </Box>
            </Box>
          </>
        )}
      </div>
    </div>
  )
}
export default ProductsTable

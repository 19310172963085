import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'

import {
  ROOT,
  COCKPIT,
  CONFIGUTATION,
  SETTINGS,
  ADD_PLATFORM,
  ADD_CHANNEL,
  API_KEYS,
  PRODUCTS,
  ADD_PRODUCTS,
  RULES,
} from './constants'
import PrivateRoute from './PrivateRoute'
import UnauthorizedRoute from './UnauthorizedRoute'

import CockpitPage from '../pages/Cockpit'
import Configuration from '../pages/Configuration'
import Login from '../pages/Login'
import SignUp from '../pages/SignUp'
import Settings from '../pages/Settings'
import AddPlatfrom from '../pages/AddPlatfrom'
import NotFound from '../pages/NotFound'
import AddChannel from '../pages/AddChannel'
import VerifyEmail from '../pages/VerifyEmail'
import Terms from '../pages/Terms'
import Privacy from '../pages/Privacy'
import ApiKeysPage from '../pages/ApiKeysPage'
import ProductsPage from '../pages/ProductsPage'
import AddProduct from '../pages/AddProduct'
import PlatformConnect from '../pages/PlatformConnect'
import RulesPage from '../pages/Rules'
import Export from '../pages/Export'

const RouterConfig = () => (
  <Routes>
    {/* List all unauthorized routes START */}
    <Route
      path="/sign-in"
      exact
      element={
        <UnauthorizedRoute redirect="/">
          <Login />
        </UnauthorizedRoute>
      }
    />

    <Route
      path="/sign-up"
      exact
      element={
        <UnauthorizedRoute redirect="/">
          <SignUp />
        </UnauthorizedRoute>
      }
    />
    <Route
      path="/users/createAccount/verifyEmail"
      element={
        <UnauthorizedRoute redirect="/">
          <VerifyEmail />
        </UnauthorizedRoute>
      }
    />
    <Route
      path="/privacy"
      element={
        <UnauthorizedRoute redirect="/">
          <Privacy />
        </UnauthorizedRoute>
      }
    />
    <Route
      path="/terms-and-conditions"
      element={
        <UnauthorizedRoute redirect="/">
          <Terms />
        </UnauthorizedRoute>
      }
    />
    {/* List all unauthorized routes END */}

    {/* List all privat routes START */}

    <Route
      path={ROOT.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <Navigate to={COCKPIT.link} />
        </PrivateRoute>
      }
    />
    <Route
      path={COCKPIT.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <CockpitPage />
        </PrivateRoute>
      }
    />
    <Route
      path={CONFIGUTATION.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <Configuration />
        </PrivateRoute>
      }
    />
    <Route
      path={SETTINGS.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <Settings />
        </PrivateRoute>
      }
    />
    <Route
      path={ADD_PLATFORM.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <AddPlatfrom />
        </PrivateRoute>
      }
    />
    <Route
      path={ADD_CHANNEL.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <AddChannel />
        </PrivateRoute>
      }
    />
    <Route
      path={PRODUCTS.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <ProductsPage />
        </PrivateRoute>
      }
    />
    <Route
      path={ADD_PRODUCTS.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <AddProduct />
        </PrivateRoute>
      }
    />
    <Route
      path={RULES.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <RulesPage />
        </PrivateRoute>
      }
    />
    <Route
      path={API_KEYS.link}
      element={
        <PrivateRoute redirect="/sign-in">
          <ApiKeysPage />
        </PrivateRoute>
      }
    />
    {/* List all privat routes END */}

    {/* List a generic 404-Not Found route here */}
    <Route path="/export" element={<Export />} />
    <Route path="/auth/:id" element={<PlatformConnect />} />
    <Route path="*" element={<NotFound />} />
  </Routes>
)

export default RouterConfig

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%',
      maxWidth: '100%',
      overflow: 'auto',
      '& $th, $td': {
        textTransform: 'capitalize',
        // display: '-webkit-box',
        // '-webkit-line-clamp': 1,
        // '-webkit-box-orient': 'vertical',
        // overflow: 'hidden',
        // textOverflow: 'ellipsis',
        '&:not(:last-child)': {
          marginRight: 20,
        },

        '&$th_select, &$td_select': {
          minWidth: 50,
          width: 50,
        },
        '&$th_platform, &$td_platform': {
          minWidth: 150,
          width: 150,
        },
        '&$th_brand, &$td_brand': {
          minWidth: 150,
          width: 150,
        },
        '&$th_name, &$td_name': {
          minWidth: 230,
          width: 230,
        },
        '&$th_product_manager, &$td_product_manager': {
          minWidth: 110,
          width: 110,
        },
        '&$th_add_products, &$td_add_products': {
          minWidth: 120,
          width: 120,
        },
        '&$th_commision, &$td_commision': {
          minWidth: 128,
          width: 128,
        },
        '&$th_acos, &$td_acos': {
          minWidth: 133,
          width: 133,
        },
        '&$th_total_cost, &$td_total_cost': {
          minWidth: 100,
          width: 100,
        },
      },
    },
    bolderText: {
      fontWeight: '700',
    },
    table_wrapper: {
      minWidth: 657,
    },
    loaderWrapper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 25,
    },
    loaderText: {},
    mainLoaderWrapper: {
      position: 'relative',
      width: '100%',
      height: 667,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
    },
    button: {
      cursor: 'pointer',
    },
    wrapperFlexBtn: {
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      gap: 10,
    },
    greenIcon: {
      '& svg, & svg path': {
        fill: '#00FF47',
      },
    },
    thead: {
      width: '100%',
      paddingBottom: 15,
      marginBottom: 15,
      borderBottom: '1px solid #C4C4C4',
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.4,
      color: '#000',
      '& > .MuiBox-root': {
        '&:not(:last-child)': {
          marginBottom: 20,
        },
      },
      '& $th, $td': {
        textTransform: 'capitalize',
        alignItems: 'flex-start',
      },
    },
    tr: {
      width: '100%',
      display: 'flex',
      '&:not(:last-child)': {
        marginBottom: 16,
      },
    },
    th: {
      fontWeight: 800,
      fontSize: 14,
      lineHeight: '18px',
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'flex-end',
    },
    td: {
      fontWeight: 400,
      fontSize: 16,
      lineHeight: 1.4,
      display: 'grid',
      alignItems: 'center',
    },
    tbody: {
      fontWeight: 300,
      fontSize: 14,
      lineHeight: 1.4,
      color: '#000',
      marginTop: 0,
      '& $tr': {
        borderBottom: 0,
        '&:not(:last-child)': {
          paddingBottom: 16,
          borderBottom: '1px solid #C4C4C4',
        },
      },
    },
    attention: {
      color: ' #1A0063',
      textAlign: 'center',
      fontSize: 48,
      fontStyle: 'normal',
      fontWeight: 900,
      lineHeight: 0,
      fontFamily: ' Muli,Arial,sans-serif',
      margin: 0,
    },
    wrapperBody: {
      maxHeight: 500,
      overflowY: 'auto',
    },
    th_select: {},
    th_edit: {},
    th_platform: {},
    th_name: {},
    th_brand: {},
    th_product_manager: {},
    th_add_products: {},
    th_commision: {},
    th_acos: {},
    th_total_cost: {},
    td_select: {},
    td_edit: {},
    td_platform: {
      display: 'inline-flex',
    },
    td_name: {
      display: 'block',
    },
    td_brand: {
      display: 'inline-flex',
    },
    td_product_manager: {},
    td_add_products: {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        '&:not(:last-child)': {
          marginRight: 10,
        },
      },
    },
    td_add_products_icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 30,
      height: 30,
      border: '1px solid #C6C6C6',
      borderRadius: '50%',
      transition: '0.3s',
      cursor: 'pointer',
      '& svg, path': {
        width: 11,
        height: 11,
        fill: '#000000',
      },
      '&:hover': {
        transition: '0.3s',
        background: '#1A0063',
        '& svg, path': {
          width: 11,
          height: 11,
          fill: 'white',
        },
      },
    },
    wrapperSpinner: {
      width: 30,
      height: 30,
      '& .MuiCircularProgress-root': {
        width: '100% !important',
        height: '100% !important',
      },
      '& svg': {
        width: '100%',
        height: '100%',
      },
    },
    td_add_products_file_input: {
      display: 'none',
      opacity: 0,
      pointerEvents: 'none',
    },
    td_commision: {},
    td_commision_slider: {
      width: '100%',
      maxWidth: 103,
      '& .MuiInputBase-input': {
        fontSize: '16px !important',
        lineHeight: '1.4 !important',
      },
    },
    wrapperBtn: {
      width: 102,
      textAlign: 'center',
      '&:not(:first-of-type)': {
        marginLeft: '0 !important',
      },
      '& > a': {
        display: 'flex',
        textDecoration: 'none',
      },
      '& .MuiButtonBase-root': {
        minHeight: 40,
        border: '1px solid #1A0063',
        color: '#1A0063',
        transition: '0.3s',
        fontSize: 11,
        textTransform: 'capitalize',
        padding: 8,
        '&.Mui-disabled': {
          borderColor: '#C4C4C4',
          color: '#C4C4C4',
        },
        '&:hover': {
          backgroundColor: '#1A0063',
          color: '#fff',
        },
      },
    },
    form_btn: {
      width: '100%',
      flexGrow: 1,
      fontWeight: 'bold',
    },
    td_acos: {},
    td_acos_slider: {
      width: '100%',
      maxWidth: 103,
      '& .MuiInputBase-input': {
        fontSize: '16px !important',
        lineHeight: '1.4 !important',
      },
    },
    td_total_cost: {},
    form_input: {
      '& .MuiFormLabel-root': {
        fontSize: 12,
        lineHeight: 1.5,
        textTransform: 'uppercase',
        color: '#000',
        top: '50%',
        transform: 'translate(20px, -50%) scale(1)',
        padding: 0,
        '&.MuiFormLabel-filled, &.MuiInputLabel-shrink': {
          opacity: 0,
          pointerEvents: 'none',
        },
        '&.Mui-error': {
          color: '#000',
        },
      },
      '& .MuiInputBase-input': {
        fontSize: 12,
        lineHeight: 1.5,
        color: '#000',
        padding: '0 1rem',
        height: 40,
        '&::placeholder': {
          fontWeight: 300,
          textTransform: 'uppercase',
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(26, 18, 63, 0.5)',
        top: 0,
        '& legend': {
          opacity: 0,
          pointerEvents: 'none',
          background: 'transparent',
          display: 'none',
        },
      },
    },
  })
)
export default useStyles

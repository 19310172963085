import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    loader_wrapper: {
      width: '100%',
      height: '100%',
      background: '#00000010',
      position: 'absolute',
      left: '0',
      top: '0',
    },
    loader: {
      position: 'absolute',
      left: 'calc(50% - 20px)',
      top: '20px',
    },
  })
)
export default useStyles

import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Container } from '@mui/material'
import { setPage } from '../../store/slice/page'

import logo from '../../assets/icons/logo-v-2.svg'

import useStyles from './styles'

const Terms = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPage('terms'))
  })

  return (
    <div className={classes.terms}>
      <Container className={classes.xl_container}>
        <Box className={classes.container}>
          <Box className={classes.main_header}>
            <Box className={classes.main_title}>Neural Search Labs GmbH</Box>
            <div className={classes.logo}>
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </Box>
          <Box className={classes.content}>
            <Box className={classes.topic}>
              <h2>General Terms and Conditions</h2>
              <p>
                Neural Search Labs GmbH (hereinafter: &quot;NSL&quot;) develops and operates a system for managing and
                optimizing online marketing activities called DeepCliq (hereinafter: &quot;Product&quot;). This Product
                is made available on a &quot;Software as a Service&quot; basis to companies (hereinafter:
                &quot;Customers&quot;) that are active in the online selling of physical goods. The use of this Product
                is subject to these general terms and conditions (hereinafter: &quot;GTC&quot;).
              </p>
              <br />
              <p>
                The Product facilitates the advertising across marketplaces, such as Bol.com or Amazon, as well as
                advertising platforms, such as Google. At its core the Product makes advertising decisions on behalf of
                the Customer that it communicates to the marketplaces and advertising platforms. These decisions are
                rooted in advanced statistical analysis algorithms and have the aim of optimizing advertisements in the
                best interests of the Customer.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>1. Scope</h2>
              <p>
                1.1 These GTC apply to all agreements concluded between NSL and the Customer regarding the use of the
                Product (hereinafter: &quot;Agreement&quot;).
              </p>
              <br />
              <p>
                1.2 In such cases where the Customer&apos;s general terms and conditions deviate from NSL&apos;s GTC,
                NSL&apos;s GTC shall prevail unless stated otherwise by NSL in writing.
              </p>
              <br />
              <p>
                1.3 NSL reserves the right to change or extend these GTC at any time. The Customer will be notified of
                any changes in writing at least eight (8) weeks prior to the changes taking effect. This notification
                may take place in the form of e-mail or via the password-protected section of the Product.
              </p>
              <br />
              <p>
                1.4 In case the Customer has not objected to the changes within a period of four (4) weeks after
                receiving the notification of changes, they are considered to having been accepted and shall become an
                integral part of the Agreement.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>2. Conclusion of the Agreement</h2>
              <p>
                2.1 To use the Product, the customer must register on the NSL website at www.deepcliq.com upon which the
                customer will receive a confirmation e-mail requesting them to activate their DeepCliq account. By
                registering and activating the account, the customer places an order for the use of the Product.
              </p>
              <br />
              <p>
                2.2. Activation of the customer&apos;s account does not imply the conclusion of the Agreement. The
                Agreement is only concluded at the discretion of NSL. This can either be done by successfully activating
                the Product or by a written acceptance of the customer&apos;s order by NSL.
              </p>
              <br />
            </Box>
            <Box className={classes.topic}>
              <h2>3. Access to the Product and Customer&apos;s obligations</h2>
              <p>
                3.1 The Customer grants the right to NSL to access their accounts on the agreed marketplaces and
                platforms, which includes the right to make changes in these accounts.
              </p>
              <br />
              <p>
                3.2 The Customer shall support NSL to establish the digital connections to their accounts which
                includes, but is not limited to, providing account numbers, granting of the necessary permissions and
                providing authentication keys.
              </p>
              <br />
              <p>
                3.3 The customer grants NSL the right to download and use the data from its accounts on the marketplaces
                and platforms for its statistical analyses. This data may also be used for deriving anonymous meta-data
                and testing purposes.
              </p>
              <br />
              <p>
                3.4 The client is responsible for complying with the applicable terms of use and other regulations of
                the marketplace or platform. The use of the Product is explicitly not intended to violate any
                regulations of the marketplace or platform. NSL reserves the right to terminate the Agreement in case of
                breach of such terms of use and other regulations is observed.
              </p>
              <br />
              <p>
                3.5 Only employees of the Customer or authorised representatives are entitled to use the Product. To
                avoid errors one concurrent user can use it only. The Customer is responsible for keeping personal
                access data confidential.
              </p>
              <br />
              <p>
                3.6 The Customer safeguards that registration data is correct and accurate. If such data changes after
                registration, the customer shall notify NSL of the relevant changes without delay.
              </p>
              <br />
              <p>
                3.7 The Customer must make sure that the respective accounts are correctly set up for advertising. In
                the case of Google Ads, conversion tracking needs to be working correctly with a 30-day conversion
                window.
              </p>
              <br />
              <p>3.8 The Customer is responsible for making appropriate back-ups of his data. </p>
              <br />
              <p>
                3.9 The customer will perform all necessary acts of cooperation for the fulfilment of the Agreement.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>4. Rights of use</h2>
              <p>
                NSL grants the customer a non-exclusive (simple), non-transferable and non-sublicensable right to use of
                the Product by users covered by the Agreement as a paid service (free of charge for an agreed trial
                period). All copyrights and other rights relating to the Product as well as any content provided by NSL
                are reserved.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>5. Invoicing and payment</h2>
              <p>
                5.1. The prices of NSL services that are valid at the time of the conclusion of the Agreement can be
                found at deepcliq.ai. These prices apply unless otherwise agreed by the parties in writing.
              </p>
              <br />
              <p>5.2 The variable monthly remuneration shall be payable at the end of the respective calendar month.</p>
              <br />
              <p>
                5.3 In the event of a customer default, NSL has the right to block the service until all outstanding
                dues have been paid. NSL may charge interest on the debts. The customer shall only offset undisputed
                claims after agreement by NSL.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>6. Availability and liability</h2>
              <p>
                6.1 NSL guarantees an annual average availability of the Product of 99%. This excludes the time during
                which the Product might not be accessible due to technical or other problems for which NSL is not
                responsible (such as Internet/network failures, failures on the part of the marketplaces or platforms,
                force majeure). Also necessary and scheduled maintenance work is not included.
              </p>
              <br />
              <p>
                6.2 The algorithms with which the Product is built are constantly evolving. Also the market (customers,
                competitors, marketplaces) is subject to a constant dynamic as well as the Customer&apos;s own product
                catalogue. NSL does therefore not assume any guarantee nor does it warrant for the achievement of any
                specific business goals (e.g. sales of profit goals). The Customer accepts this and will not make any
                financial claims relating to the performance of the Product.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>7. References</h2>
              <p>
                NSL shall be entitled to make reference to the Customer on its website or in other marketing
                communications. This agreement may be revoked at any time.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>8. Final Provisions</h2>
              <p>
                8.1 The place of performance of the service is equal to the place where NSL has its statutory office.
              </p>
              <br />
              <p>
                8.2 The place of jurisdiction for any disputes regarding these GTC shall be Düsseldorf. The relationship
                between NSL and the Customer is exclusively governed by the laws of the Federal Republic of Germany,
                excluding its conflict of laws rules and the UN Convention on Contracts for the International Sale of
                Goods.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>9. Severability</h2>
              <p>
                9.1 Should provisions of these GTC or other agreements between NSL and the Customer, in whole or in
                part, be or become incomplete, invalid, void and/or unenforceable, then this shall not affect the
                validity of the remaining provisions.
              </p>
              <br />
              <p>
                9.2 In the event of a force majeure (e.g. war, pandemics, strikes, natural disasters or strikes), the
                Agreement may be suspended for the duration of such events.
              </p>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  )
}
export default Terms

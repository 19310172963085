import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(() =>
  createStyles({
    page_wrapper: {},
    page_content: {
      padding: 125,
    },
    closeBtn: {
      width: 45,
      height: 45,
      alignItems: 'center',
      justifyContent: 'center',
      display: 'flex',
      position: 'absolute',
      top: 25,
      right: 25,
      border: '1px solid #1A0063',
      borderRadius: 100,
      transition: ' 0.3s',
      '&:hover': {
        backgroundColor: '#1A0063',
        '& svg, path': {
          fill: 'white',
        },
      },
      '& svg, path': {
        width: 20,
        height: 20,
        fill: '#1A0063',
      },
    },
  })
)
export default useStyles

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexWrap: 'wrap',
      maxWidth: 451,
      width: '100%',
    },
    form: {
      width: '100%',
    },
    form_alert: {
      marginTop: theme.spacing(4),
      border: '1px solid #FF0000',
      borderRadius: 5,
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
      fontSize: 16,
      lineHeight: 1.4,
    },
    descriptionText: {
      marginTop: 10,
      marginBottom: 25,
      fontSize: 14,
      lineHeight: 1.9,
    },
    platformBtn: {
      border: '1px solid #A8A8A8',
      borderRadius: 10,
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
      filter: 'grayscale(100%)',
      cursor: 'pointer',
      '&:hover': {
        filter: 'grayscale(0)',
        border: '1px solid #7301FC',
        boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
      },
    },
    platformActiveBtn: {
      borderRadius: 10,
      padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
      cursor: 'pointer',
      filter: 'grayscale(0)',
      border: '1px solid #7301FC',
      boxShadow: '0px 0px 10px 0px rgba(0, 0, 0, 0.15)',
    },
    wrapperList: {
      gap: 20,
      flexWrap: 'wrap!important',
    },
    form_group: {
      '&.MuiFormGroup-root': {
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          flexWrap: 'nowrap',
        },
      },
      '& .MuiFormLabel-root': {
        fontWeight: 300,
        textTransform: 'capitalize!important',
        fontStyle: 'italic',
        color: '#000000',
        opacity: 1,
      },
      '& .MuiSelect-select': {
        fontWeight: '800 !important',
        fontSize: 12,
        lineHeight: 2.5,
        textTransform: 'uppercase',
        display: '-webkit-box',
        'webkit-box-orient': 'vertical',
        '-webkit-line-clamp': 1,
        '-webkit-box-orient': 'vertical',
        ' white-space': 'normal',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      },
    },
    form_group_col: {
      width: '100%',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
        [theme.breakpoints.up('md')]: {
          marginRight: theme.spacing(3),
          marginBottom: 0,
        },
      },
    },
    form_input: {
      '& .MuiFormLabel-root': {
        fontWeight: 300,
        textTransform: 'capitalize!important',
        fontStyle: 'italic',
        fontSize: 15,
        opacity: 1,
        minWidth: '100%',
        color: '#000',
        top: '50%',
        transform: 'translate(20px, -50%) scale(1)',
        padding: 0,
        '&.MuiFormLabel-filled, &.MuiInputLabel-shrink': {
          opacity: 0,
          pointerEvents: 'none',
        },
        '&.Mui-error': {
          color: '#000',
        },
      },
      '& .MuiInputBase-input': {
        fontWeight: 800,
        fontSize: 12,
        lineHeight: 1.5,
        color: '#000',
        padding: '0 1.25rem',
        height: 45,
        '&::placeholder': {
          fontWeight: 300,
          textTransform: 'capitalize',
          fontStyle: 'italic',
          color: '#000000',
          opacity: 1,
          fontSize: 15,
        },
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(26, 18, 63, 0.5)',
        top: 0,
        '& legend': {
          opacity: 0,
          pointerEvents: 'none',
          background: 'transparent',
          display: 'none',
        },
      },
    },
    form_input_error: {
      paddingBottom: 17,
    },
    form_footer: {
      padding: '0 !important',
      marginTop: 0,
      justifyContent: 'flex-end',
    },
    form_footer_box: {
      width: 125,
      textAlign: 'center',
      '&:not(:last-child)': {
        marginRight: theme.spacing(3),
      },
      '&:not(:first-of-type)': {
        marginLeft: '0 !important',
      },
      '& > a': {
        display: 'flex',
        textDecoration: 'none',
      },
      '& .MuiButtonBase-root': {
        border: '1px solid #1A0063',
        color: '#1A0063',
        transition: '0.3s',
        '&.Mui-disabled': {
          borderColor: '#C4C4C4',
          color: '#C4C4C4',
        },
        '&:hover': {
          backgroundColor: '#1A0063',
          color: '#fff',
        },
      },
    },
    form_btn: {
      width: '100%',
      flexGrow: 1,
      fontWeight: 'bold',
      textTransform: 'capitalize!important',
    },
    form_groupLabel: {
      marginTop: 55,
      gap: 20,
      alignItems: 'center',
    },
    form_inputLabel: {
      marginBottom: '0!important',
    },
    header: {
      maxWidth: 450,
    },
    header_title: {
      textAlign: 'left',
      fontWeight: 800,
      fontSize: '1.625rem',
      lineHeight: 1.26,
      textTransform: 'capitalize',
      color: '#000',
    },
  })
)
export default useStyles

import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexWrap: 'wrap',
      [theme.breakpoints.up('md')]: {
        maxWidth: 480,
        width: '100%',
        margin: `${theme.spacing(7)} ${theme.spacing(4.5)} ${theme.spacing(0)}`,
      },
      [theme.breakpoints.between('md', 'sm')]: {
        margin: `${theme.spacing(7)} 2rem ${theme.spacing(10)}`,
      },
      [theme.breakpoints.down('sm')]: {
        margin: `${theme.spacing(7)} 0 ${theme.spacing(10)}`,
      },
    },
    card_wrap: {
      width: '100%',
      padding: theme.spacing(6.25),
      borderRadius: '5px!important',
      minHeight: '575px',
      [theme.breakpoints.down('md')]: {
        padding: `${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(6)}`,
      },
    },
    form: {
      maxWidth: '348px',
      margin: 'auto',
    },
    form_alert: {
      marginTop: -theme.spacing(4),
      marginBottom: theme.spacing(2.5),
    },
    form_footer: {
      padding: '0 !important',
      marginTop: theme.spacing(3),
      flexDirection: 'column',
    },
    form_footer_box: {
      width: '100%',
      textAlign: 'center',
      '&:not(:last-child)': {
        marginBottom: theme.spacing(3),
      },
    },
    form_btn: {
      width: '100%',
      flexGrow: 1,
      fontWeight: 'bold',
    },
    form_footer_text: {
      '& .MuiLink-root, & a': {
        fontWeight: 700,
        color: theme.palette.text.primary,
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
    header: {
      marginBottom: theme.spacing(7.5),
    },
    header_title: {
      textAlign: 'left',
      fontWeight: 'bold',
      fontSize: '2rem',
      lineHeight: 1.3,
      '&:not(:last-child)': {
        marginBottom: theme.spacing(1),
      },
    },
    header_subtitle: {
      fontSize: '0.875rem',
      lineHeight: 1.4,
    },
    form_group: {
      '&:not(:last-child)': {
        marginBottom: theme.spacing(6),
      },
      '& .MuiFormControl-root': {
        '&:not(:last-child)': {
          marginBottom: theme.spacing(5),
        },
        // '& .MuiFormLabel-root.Mui-error': {
        //   color: theme.palette.error.main,
        // },
        // '& .MuiFormLabel-root[data-shrink="true"]': {
        //   color: theme.palette.text.primary,
        // },
      },
    },
    labelCheckbox: {
      marginLeft: 0,
      marginTop: 0,
      '& .MuiFormControlLabel-label': {
        marginLeft: 10,
      },
    },
  })
)
export default useStyles

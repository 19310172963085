import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Box, Container } from '@mui/material'
import { setPage } from '../../store/slice/page'

import logo from '../../assets/icons/logo-v-2.svg'

import useStyles from './styles'

const Privacy = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(setPage('privacy'))
  })

  return (
    <div className={classes.terms}>
      <Container className={classes.xl_container}>
        <Box className={classes.container}>
          <Box className={classes.main_header}>
            <Box className={classes.main_title}>Privacy Policy</Box>
            <div className={classes.logo}>
              <a href="/">
                <img src={logo} alt="logo" />
              </a>
            </div>
          </Box>
          <Box className={classes.content}>
            <Box className={classes.topic}>
              {/* <h2>Topic1</h2> */}
              <p>
                Neural Search Labs GmbH is committed to safeguarding your privacy. Contact us at{' '}
                <a href="mailto:privacy@neural-search.ai">privacy@neural-search.ai</a> if you have any questions or
                problems regarding the use of your Personal Data and we will gladly assist you.
              </p>
              <p>
                By using this site or/and our services, you consent to the Processing of your Personal Data as described
                in this Privacy Policy.
              </p>
              <p>
                This Privacy Policy is a part of our General Terms and Conditions; by agreeing to General Terms and
                Conditions you also agree to this Policy. In the event of collision of terms used in General Terms and
                Conditions and Privacy Policy, the latter shall prevail.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>Table of Contents</h2>
              <ol>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-definitions">
                    Definitions used in this Policy
                  </a>
                </li>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-principles">
                    Data protection principles we follow
                  </a>
                </li>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-rights">
                    What rights do you have regarding your Personal Data
                  </a>
                </li>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-information">
                    What Personal Data we gather about you
                  </a>
                </li>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-processing">
                    How we use your Personal Data
                  </a>
                </li>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-sharing">
                    Who else has access to your Personal Data
                  </a>
                </li>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-security">
                    How we secure your data
                  </a>
                </li>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-cookies">
                    Information about cookies
                  </a>
                </li>
                <li>
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-contact">
                    Contact information
                  </a>
                </li>
              </ol>
            </Box>
            <Box className={classes.topic}>
              <h2>Definitions</h2>
              <p>
                <strong>Personal Data</strong> -any information relating to an identified or identifiable natural
                person.
              </p>
              <p>
                <strong>Processing</strong> - any operation or set of operations which is performed on Personal Data or
                on sets of Personal Data.
              </p>
              <p>
                <strong>Data subject</strong> - a natural person whose Personal Data is being Processed.
              </p>
              <p>
                <strong>Child</strong> - a natural person under 16 years of age.
              </p>
              <p>
                <strong>We/us</strong> (either capitalized or not) - Neural Search Labs GmbH
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>Data Protection Principles</h2>
              <span>We promise to follow the following data protection principles:</span>
              <ul>
                <li>
                  Processing is lawful, fair, and transparent. Our Processing activities have lawful grounds. We always
                  consider your rights before Processing Personal Data. We will provide you information regarding
                  Processing upon request.
                </li>
                <li>
                  Processing is limited to the purpose. Our Processing activities fit the purpose for which Personal
                  Data was gathered.
                </li>
                <li>
                  Processing is done with minimal data. We only gather and Process the minimal amount of Personal Data
                  required for any purpose.
                </li>
                <li>
                  Processing is limited with a time period. We will not store your personal data for longer than needed.
                </li>
                <li>We will do our best to ensure the accuracy of data.</li>
                <li>We will do our best to ensure the integrity and confidentiality of data.</li>
              </ul>
            </Box>
            <Box className={classes.topic}>
              <h2>Data Subject&apos;s rights</h2>
              <span>The Data Subject has the following rights:</span>
              <ol>
                <li>
                  Right to information - meaning you have to right to know whether your Personal Data is being
                  processed; what data is gathered, from where it is obtained and why and by whom it is processed.
                </li>
                <li>
                  Right to access - meaning you have the right to access the data collected from/about you. This
                  includes your right to request and obtain a copy of your Personal Data gathered.
                </li>
                <li>
                  Right to rectification - meaning you have the right to request rectification or erasure of your
                  Personal Data that is inaccurate or incomplete.
                </li>
                <li>
                  Right to erasure - meaning in certain circumstances you can request for your Personal Data to be
                  erased from our records.
                </li>
                <li>
                  Right to restrict processing - meaning where certain conditions apply, you have the right to restrict
                  the Processing of your Personal Data.
                </li>
                <li>
                  Right to object to processing - meaning in certain cases you have the right to object to Processing of
                  your Personal Data, for example in the case of direct marketing.
                </li>
                <li>
                  Right to object to automated Processing - meaning you have the right to object to automated
                  Processing, including profiling; and not to be subject to a decision based solely on automated
                  Processing. This right you can exercise whenever there is an outcome of the profiling that produces
                  legal effects concerning or significantly affecting you.
                </li>
                <li>
                  Right to data portability - you have the right to obtain your Personal Data in a machine-readable
                  format or if it is feasible, as a direct transfer from one Processor to another.
                </li>
                <li>
                  Right to lodge a complaint - in the event that we refuse your request under the Rights of Access, we
                  will provide you with a reason as to why. If you are not satisfied with the way your request has been
                  handled please contact us.
                </li>
                <li>
                  Right for the help of{' '}
                  <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-contact">
                    supervisory authority
                  </a>{' '}
                  -meaning you have the right for the help of a supervisory authority and the right for other legal
                  remedies such as claiming damages.
                </li>
                <li>
                  Right to withdraw consent - you have the right withdraw any given consent for Processing of your
                  Personal Data.
                </li>
              </ol>
            </Box>
            <Box className={classes.topic}>
              <h2>Data we gather</h2>
              <p>
                <strong>Information you have provided us with</strong>
                <br />
                This might be your e-mail address, name, billing address, home address etc. – mainly information that is
                necessary for delivering you a product/service or to enhance your customer experience with us. We save
                the information you provide us with in order for you to comment or perform other activities on the
                website. This information includes, for example, your name and e-mail address.
              </p>
              <br />
              <p>
                <strong>Information automatically collected about you</strong>
                <br />
                This includes information that is automatically stored by cookies and other session tools. For example,
                your IP address, your shopping history (if there is any) etc. This information is used to improve your
                customer experience. When you use our services or look at the contents of our website, your activities
                may be logged.
              </p>
              <br />
              <p>
                <strong>Information from our partners</strong>
                <br />
                We gather information from our trusted partners with confirmation that they have legal grounds to share
                that information with us. This is either information you have provided them directly with or that they
                have gathered about you on other legal grounds. See the list of our partners{' '}
                <a href="https://neural-search.ai/wp-admin/tools.php?page=privacy&gdpr-tab=privacy-policy#gdpr-sharing">
                  here.
                </a>
              </p>
              <br />
              <p>
                <strong>Publicly available information</strong>
                <br />
                We might gather information about you that is publicly available.
              </p>
              <br />
              <div>
                <strong>How we use your Personal Data</strong>
                <br />
                We use your Personal Data in order to:
                <ul>
                  <li>
                    provide our service to you. This includes for example registering your account; providing you with
                    other products and services that you have requested; providing you with promotional items at your
                    request and communicating with you in relation to those products and services; communicating and
                    interacting with you; and notifying you of changes to any services.
                  </li>
                  <li>enhance your customer experience;</li>
                  <li>fulfil an obligation under law or contract;</li>
                </ul>
                <p>
                  We use your Personal Data on legitimate grounds and/or with your Consent. On the grounds of entering
                  into a contract or fulfilling contractual obligations, we Process your Personal Data for the following
                  purposes:
                </p>
                <ul>
                  <li>to identify you;</li>
                  <li>to provide you a service or to send/offer you a product;</li>
                  <li>to communicate either for sales or invoicing;</li>
                </ul>
                <p>On the ground of legitimate interest, we Process your Personal Data for the following purposes:</p>
                <ul>
                  <li>to send you personalized offers* (from us and/or our carefully selected partners);</li>
                  <li>
                    to administer and analyse our client base (purchasing behaviour and history) in order to improve the
                    quality, variety, and availability of products/ services offered/provided;
                  </li>
                  <li>to conduct questionnaires concerning client satisfaction;</li>
                </ul>
                <p>
                  As long as you have not informed us otherwise, we consider offering you products/services that are
                  similar or same to your purchasing history/browsing behaviour to be our legitimate interest. With your
                  consent we Process your Personal Data for the following purposes:
                </p>
                <ul>
                  <li>to send you newsletters and campaign offers (from us and/or our carefully selected partners);</li>
                  <li>for other purposes we have asked your consent for;</li>
                </ul>
                <br />
                <p>
                  We Process your Personal Data in order to fulfil obligation rising from law and/or use your Personal
                  Data for options provided by law. We reserve the right to anonymise Personal Data gathered and to use
                  any such data. We will use data outside the scope of this Policy only when it is anonymised. We save
                  your billing information and other information gathered about you for as long as needed for accounting
                  purposes or other obligations deriving from law, but not longer than 5 years.
                </p>
                <p>
                  We might process your Personal Data for additional purposes that are not mentioned here, but are
                  compatible with the original purpose for which the data was gathered. To do this, we will ensure that:
                </p>
                <ul>
                  <li>
                    the link between purposes, context and nature of Personal Data is suitable for further Processing;
                  </li>
                  <li>
                    the further Processing would not harm your interests and there would be appropriate safeguard for
                    Processing.
                  </li>
                </ul>
                <p>We will inform you of any further Processing and purposes.</p>
              </div>
            </Box>
            <Box className={classes.topic}>
              <h2>Who else can access your Personal Data</h2>
              <p>
                We do not share your Personal Data with strangers. Personal Data about you is in some cases provided to
                our trusted partners in order to either make providing the service to you possible or to enhance your
                customer experience. We share your data with our processing partners Stripe for invoicing purposes and
                Stadtsparkasse Düsseldorf.
              </p>
              <br />
              <p>
                We only work with Processing partners who are able to ensure adequate level of protection to your
                Personal Data. We disclose your Personal Data to third parties or public officials when we are legally
                obliged to do so. We might disclose your Personal Data to third parties if you have consented to it or
                if there are other legal grounds for it.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>How we secure your data</h2>
              <p>
                We do our best to keep your Personal Data safe. We use safe protocols for communication and transferring
                data (such as HTTPS). We use anonymising and pseudonymising where suitable. We monitor our systems for
                possible vulnerabilities and attacks.
              </p>
              <br />
              <p>
                Even though we try our best we can not guarantee the security of information. However, we promise to
                notify suitable authorities of data breaches. We will also notify you if there is a threat to your
                rights or interests. We will do everything we reasonably can to prevent security breaches and to assist
                authorities should any breaches occur. If you have an account with us, note that you have to keep your
                username and password secret.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>Cookies and other technologies we use</h2>
              <p>
                We use cookies and/or similar technologies to analyse customer behaviour, administer the website, track
                users&apos; movements, and to collect information about users. This is done in order to personalise and
                enhance your experience with us.
              </p>
              <br />
              <p>
                A cookie is a tiny text file stored on your computer. Cookies store information that is used to help
                make sites work. Only we can access the cookies created by our website. You can control your cookies at
                the browser level. Choosing to disable cookies may hinder your use of certain functions.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>We use cookies for the following purposes:</h2>
              <ol>
                <li>
                  Necessary cookies - these cookies are required for you to be able to use some important features on
                  our website, such as logging in. These cookies don&apos;t collect any personal information.
                </li>
                <li>
                  Functionality cookies - these cookies provide functionality that makes using our service more
                  convenient and makes providing more personalised features possible. For example, they might remember
                  your name and e-mail in comment forms so you don&apos;t have to re-enter this information next time
                  when commenting.
                </li>
                <li>
                  Analytics cookies - these cookies are used to track the use and performance of our website and
                  services
                </li>
                <li>
                  Advertising cookies - these cookies are used to deliver advertisements that are relevant to you and to
                  your interests. In addition, they are used to limit the number of times you see an advertisement. They
                  are usually placed to the website by advertising networks with the website operator&apos;s permission.
                  These cookies remember that you have visited a website and this information is shared with other
                  organisations such as advertisers. Often targeting or advertising cookies will be linked to site
                  functionality provided by the other organisation.
                </li>
              </ol>
              <p>
                You can remove cookies stored in your computer via your browser settings. Alternatively, you can control
                some 3rd party cookies by using a privacy enhancement platform such as{' '}
                <a href="http://optout.aboutads.info/#!/">optout.aboutads.info</a> or{' '}
                <a href="http://www.youronlinechoices.com/">youronlinechoices.com</a>. For more information about
                cookies, visit <a href="http://www.allaboutcookies.org/">allaboutcookies.org</a>.
              </p>
              <br />
              <p>
                We use Google Analytics to measure traffic on our website. Google has their own Privacy Policy which you
                can review <a href="https://support.google.com/analytics/answer/6004245">here</a>. If you&apos;d like to
                opt out of tracking by <a href="https://tools.google.com/dlpage/gaoptout">Google Analytics</a>, visit
                the Google Analytics opt-out page.
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>
                Contact Information
                <br />
                Data Protection Officer
              </h2>
              <p>
                If you have any questions regarding Processing your Personal Data, your rights regarding your Personal
                Data or this Privacy Policy, contact our Data Protection Officer Marc Janssen at{' '}
                <a href="mailto:marc@neural-search.ai">marc@neural-search.ai</a>
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>Supervisory Authority</h2>
              <p>
                Website: <a href="http://www.bfdi.bund.de/">http://www.bfdi.bund.de/</a>
                <br />
                Website: <a href="http://www.bfdi.bund.de/">http://www.bfdi.bund.de/</a>
                <br />
                Email: Email: <a href="mailto:poststelle@bfdi.bund.de">poststelle@bfdi.bund.de</a>
                <br />
                Phone: +49 228 997799 0
              </p>
            </Box>
            <Box className={classes.topic}>
              <h2>Changes to this Privacy Policy</h2>
              <p>
                We reserve the right to make change to this Privacy Policy. <br />
                Last modification was made May 24, 2023.
              </p>
            </Box>
          </Box>
        </Box>
      </Container>
    </div>
  )
}
export default Privacy

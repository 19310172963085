import { styled } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'

// const drawerWidth = 230
const drawerWidth = '30%'
const maxWidth = 230

const openedMixin = (theme) => ({
  width: drawerWidth,
  maxWidth,
  transition: theme.transitions.create(['width', 'border'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create(['width', 'border'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `0`,
  borderColor: 'transparent',
  [theme.breakpoints.up('sm')]: {
    width: `0`,
  },
})

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  width: drawerWidth,
  maxWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

export { Drawer, drawerWidth }

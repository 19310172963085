import { toast } from 'react-toastify'
import { TOAST_DEFAULT_CONFIG } from '../constants/toast'

export const downloadURI = (uri, name) => {
  try {
    const link = document.createElement('a')
    link.download = name
    link.href = uri
    link.target = '_blank'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  } catch (err) {
    toast.error(err, TOAST_DEFAULT_CONFIG)
  }
}

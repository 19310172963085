import { createSlice } from '@reduxjs/toolkit'
import moment from 'moment'
import { addDays, startOfDay } from 'date-fns'
import { NO_GROUP_FILTER_OPTION } from './product'
import { isValueExist } from '../../utils/errorParser'

const updateStoredColConfig = (storedConfig, defaultConfig) => {
  if (!storedConfig) {
    return storedConfig
  }

  const updated = {}

  Object.keys(storedConfig).forEach((key) => {
    const updatedColKeys = []
    defaultConfig.forEach((item) => {
      const targetItem = storedConfig[key]?.find(
        (target) => target.key === item.key && target.label === item.label && target.disabled === item.disabled
      )
      if (targetItem) {
        updatedColKeys.push(targetItem)
      } else {
        updatedColKeys.push(item)
      }
    })

    updated[key] = updatedColKeys
  })

  return updated
}

const startRange = moment(startOfDay(addDays(new Date(), -6))).format('yyyy-MM-DD')
const endRange = moment(startOfDay(addDays(new Date(), 0))).format('yyyy-MM-DD')

export const defaultFilters = {
  channel: -1,
  groupBy: NO_GROUP_FILTER_OPTION.value,
  showOnlyActive: 1,
  dateFrom: startRange,
  dateTo: endRange,
  filterBy: '',
}

const defaultSliders = {
  acos: [15, 25],
  netProfit: [0, 10],
  totalProfit: [0, 10],
  tacos: [5, 15],
}

export const defaultColConfig = [
  {
    label: 'Name',
    key: 'name',
    value: true,
    disabled: true,
  },
  {
    label: 'EAN',
    key: 'ean',
    value: true,
  },
  {
    label: 'ASIN',
    key: 'asin',
    value: true,
  },
  {
    label: 'Custom ID',
    key: 'customerID',
    value: false,
  },
  {
    label: 'Channels',
    key: 'presentInChannels_count',
    value: true,
    disabled: true,
  },
  {
    label: 'Products',
    key: 'products',
    value: true,
  },
  {
    label: 'Active',
    key: 'activeEdited',
    value: true,
    disabled: true,
  },
  {
    label: 'Boosting',
    key: 'boostEdited',
    value: true,
    disabled: true,
  },
  {
    label: 'ACoS (%)',
    key: 'acos',
    value: true,
  },
  {
    label: 'TACoS (%)',
    key: 'tacos',
    value: true,
  },
  {
    label: 'Ad profit (%)',
    key: 'profit',
    value: true,
  },
  {
    label: 'Total profit (%)',
    key: 'totalNetProportion',
    value: true,
  },
  {
    label: 'Total sales (€)',
    key: 'totalSales',
    value: true,
  },
  {
    label: 'Ad sales (€)',
    key: 'sales',
    value: true,
  },
  {
    label: 'Ad profit (€)',
    key: 'adProfitAbsolute',
    value: true,
  },
  {
    label: 'Total profit (€)',
    key: 'profitAbsolute',
    value: true,
  },
  {
    label: 'Ad spend (€)',
    key: 'cost',
    value: true,
  },
  {
    label: 'Return rate (%)',
    key: 'returnRate',
    value: true,
  },
  {
    label: 'Conversions',
    key: 'conversions',
    value: true,
  },
  {
    label: 'Clicks',
    key: 'clicks',
    value: true,
  },
  {
    label: 'Impressions',
    key: 'impressions',
    value: true,
  },
]

const initialState = {
  isExpandedTable: true,
  isSomeSubRowExpanded: false,
  isFiltersInited: false,
  filterState: null,
  acosRange: null,
  netProfitRange: null,
  tacosRange: null,
  totalProfitRange: null,
  isFiltersChange: false,
  colConfig: { default: defaultColConfig },
}

/* eslint-disable no-param-reassign */
export const cockpitSlice = createSlice({
  name: 'cockpit',
  initialState,
  reducers: {
    initStoredFilters: (state, action) => {
      const { id } = action.payload

      const storedFilters = localStorage.getItem('nsl-filters') && JSON.parse(localStorage.getItem('nsl-filters'))

      if (storedFilters?.[id]) {
        state.filterState = {
          channel: isValueExist(storedFilters[id].channel) ? storedFilters[id].channel : defaultFilters.channel,
          groupBy: isValueExist(storedFilters[id].groupBy) ? storedFilters[id].groupBy : defaultFilters.groupBy,
          dateFrom: defaultFilters.dateFrom,
          dateTo: defaultFilters.dateTo,
          filterBy: isValueExist(storedFilters[id].filterBy) ? storedFilters[id].filterBy : defaultFilters.filterBy,
          showOnlyActive: isValueExist(storedFilters[id].showOnlyActive)
            ? storedFilters[id].showOnlyActive
            : defaultFilters.showOnlyActive,
        }

        state.acosRange =
          storedFilters[id].acosRange && storedFilters[id].acosRange.length === 2
            ? storedFilters[id].acosRange
            : defaultSliders.acos
        state.netProfitRange =
          storedFilters[id].netProfitRange && storedFilters[id].netProfitRange.length === 2
            ? storedFilters[id].netProfitRange
            : defaultSliders.netProfit
        state.totalProfitRange =
          storedFilters[id].totalProfitRange && storedFilters[id].totalProfitRange.length === 2
            ? storedFilters[id].totalProfitRange
            : defaultSliders.totalProfitRange
        state.tacosRange =
          storedFilters[id].tacosRange && storedFilters[id].tacosRange.length === 2
            ? storedFilters[id].tacosRange
            : defaultSliders.tacos

        state.isFiltersChange = true
      } else {
        state.filterState = {
          channel: defaultFilters.channel,
          groupBy: defaultFilters.groupBy,
          dateFrom: defaultFilters.dateFrom,
          dateTo: defaultFilters.dateTo,
          filterBy: defaultFilters.filterBy,
          showOnlyActive: defaultFilters.showOnlyActive,
        }
        state.acosRange = defaultSliders.acos
        state.netProfitRange = defaultSliders.netProfit
        state.totalProfitRange = defaultSliders.totalProfit
        state.tacosRange = defaultSliders.tacos
      }

      state.isFiltersInited = true
    },
    initColConfig: (state) => {
      // get sotored col config and combine with default col config (need in case when we update some col data: add/delete/rename)
      const storedColConfig =
        localStorage.getItem('nsl-col-config') &&
        updateStoredColConfig(JSON.parse(localStorage.getItem('nsl-col-config')), defaultColConfig)

      if (storedColConfig) {
        state.colConfig = { ...storedColConfig }
      }

      state.isColConfigInited = true
    },
    setExpanded: (state, action) => {
      state.isExpandedTable = action.payload
      if (!action.payload) {
        state.isSomeSubRowExpanded = false
      }
    },
    setSomeSubRowExpanded: (state, action) => {
      state.isSomeSubRowExpanded = action.payload
    },
    setFilters: (state, action) => {
      const { id, ...newFilters } = action.payload
      state.filterState = { ...newFilters }
      state.isFiltersChange = true

      const storedFilters = localStorage.getItem('nsl-filters') && JSON.parse(localStorage.getItem('nsl-filters'))

      const newFiltersForStore = {
        ...storedFilters,
        [id]: {
          ...storedFilters?.[id],
          ...newFilters,
        },
      }

      localStorage.setItem('nsl-filters', JSON.stringify(newFiltersForStore))
    },
    setAcosRange: (state, action) => {
      const { id, value } = action.payload

      const storedFilters = localStorage.getItem('nsl-filters') && JSON.parse(localStorage.getItem('nsl-filters'))
      const newFiltersForStore = {
        ...storedFilters,
        [id]: {
          ...storedFilters?.[id],
          acosRange: value,
        },
      }

      state.acosRange = value
      state.isFiltersChange = true

      localStorage.setItem('nsl-filters', JSON.stringify(newFiltersForStore))
    },
    setTacosRange: (state, action) => {
      const { id, value } = action.payload

      const storedFilters = localStorage.getItem('nsl-filters') && JSON.parse(localStorage.getItem('nsl-filters'))
      const newFiltersForStore = {
        ...storedFilters,
        [id]: {
          ...storedFilters?.[id],
          tacosRange: value,
        },
      }

      state.tacosRange = value
      state.isFiltersChange = true

      localStorage.setItem('nsl-filters', JSON.stringify(newFiltersForStore))
    },
    setNetProfitRange: (state, action) => {
      const { id, value } = action.payload

      const storedFilters = localStorage.getItem('nsl-filters') && JSON.parse(localStorage.getItem('nsl-filters'))
      const newFiltersForStore = {
        ...storedFilters,
        [id]: {
          ...storedFilters?.[id],
          netProfitRange: value,
        },
      }

      state.netProfitRange = value
      state.isFiltersChange = true

      localStorage.setItem('nsl-filters', JSON.stringify(newFiltersForStore))
    },
    setTotalProfitRange: (state, action) => {
      const { id, value } = action.payload

      const storedFilters = localStorage.getItem('nsl-filters') && JSON.parse(localStorage.getItem('nsl-filters'))
      const newFiltersForStore = {
        ...storedFilters,
        [id]: {
          ...storedFilters?.[id],
          totalProfitRange: value,
        },
      }

      state.totalProfitRange = value
      state.isFiltersChange = true

      localStorage.setItem('nsl-filters', JSON.stringify(newFiltersForStore))
    },
    setColConfig: (state, action) => {
      state.colConfig = action.payload

      localStorage.setItem('nsl-col-config', JSON.stringify(action.payload))
    },
    disposeCockpit: (state) => {
      state.acosRange = defaultSliders.acos
      state.tacosRange = defaultSliders.tacos
      state.netProfitRange = defaultSliders.netProfit
      state.totalProfitRange = defaultSliders.totalProfit
      state.filterState = { ...defaultFilters }
      state.isFiltersChange = false
      state.isExpandedTable = true
      state.isSomeSubRowExpanded = false
      state.isFiltersInited = false
      state.colConfig = { default: defaultColConfig }
    },
    setDefaultFilters: (state, action) => {
      state.acosRange = defaultSliders.acos
      state.tacosRange = defaultSliders.tacos
      state.netProfitRange = defaultSliders.netProfit
      state.totalProfitRange = defaultSliders.totalProfit
      state.filterState = { ...defaultFilters }
      state.isFiltersChange = false

      const { id } = action.payload
      const storedFilters = localStorage.getItem('nsl-filters') && JSON.parse(localStorage.getItem('nsl-filters'))
      const newFiltersForStore = {
        ...storedFilters,
        [id]: {
          ...defaultFilters,
          acosRange: defaultSliders.acos,
          tacosRange: defaultSliders.tacos,
          netProfitRange: defaultSliders.netProfit,
          totalProfitRange: defaultSliders.totalProfit,
        },
      }
      localStorage.setItem('nsl-filters', JSON.stringify(newFiltersForStore))
    },
  },
})
/* eslint-enable no-param-reassign */

export const selectIsExpanded = (state) => state.cockpit.isExpandedTable
export const selectIsSomeSubRowExpanded = (state) => state.cockpit.isSomeSubRowExpanded
export const selectFilters = (state) => state.cockpit.filterState
export const selectAcosRange = (state) => state.cockpit.acosRange
export const selectNetProfitRange = (state) => state.cockpit.netProfitRange
export const selectTotalProfitRange = (state) => state.cockpit.totalProfitRange
export const selectTacosRange = (state) => state.cockpit.tacosRange
export const selectIsFiltersChange = (state) => state.cockpit.isFiltersChange
export const selectColConfig = (state) => state.cockpit.colConfig
export const selectIsFiltersInited = (state) => state.cockpit.isFiltersInited

export const { initStoredFilters, initColConfig, setExpanded, setSomeSubRowExpanded } = cockpitSlice.actions
export const {
  setFilters,
  setAcosRange,
  setTacosRange,
  setNetProfitRange,
  setTotalProfitRange,
  setDefaultFilters,
  setColConfig,
  disposeCockpit,
} = cockpitSlice.actions

export default cockpitSlice.reducer

import Cookies from 'universal-cookie'

import { userLogout } from '../store/slice/user'
import { disposeCockpit } from '../store/slice/cockpit'
import { disposeProduct } from '../store/slice/product'

const cookies = new Cookies()

export function removeAuth(prop) {
  cookies.remove(prop)
  sessionStorage.removeItem(prop)
}

export function logout(dispatch) {
  dispatch(userLogout())
  dispatch(disposeCockpit())
  dispatch(disposeProduct())
  removeAuth('AUTH_NSL')
}
